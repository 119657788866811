import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as _ from 'underscore';

// import { AppModel } from '../gcv.reducer';
import { POST_DEPOSIT, RESET_DEPOSITS, PUT_DEPOSIT, GET_ALL_DEPOSITS } from '../action.types';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class DepositDataService {
  private depositStore;

  constructor(private sdk: DefaultService, private store: Store<any>) {
    this.depositStore = store.select('deposits');
  }

  public subscribe(observer) {
    return this.depositStore.subscribe(observer);
  }

  public clearDomain() {
    this.depositStore.dispatch({
      type: RESET_DEPOSITS,
    });
  }

  public acceptDeposit(
    keys: {
      dispensaryId: string;
      bankId: string;
      depositId: string;
    },
    data: object
  ) {
    return new Promise((resolve, reject) => {
      this.sdk
        .banksIdDispensariesDispensaryIdDepositsDepositIdAcceptPost(
          keys.depositId,
          keys.dispensaryId,
          keys.bankId,
          data
        )
        .subscribe(
          deposit => {
            const payload = {
              objects: deposit,
              dispensaryId: deposit.dispensary_id,
            };

            this.depositStore.dispatch({
              type: PUT_DEPOSIT,
              payload,
            });
            resolve(deposit);
          },
          err => reject(err)
        );
    });
  }

  public getNewDeposit(keys: { id: string }) {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesIdDepositsNewGet(keys.id).subscribe(
        deposit => {
          resolve(deposit);
        },
        err => reject(err)
      );
    });
  }

  public create(
    keys: {
      id: string;
    },
    data: object
  ) {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesIdDepositsPost(keys.id, data).subscribe(
        deposit => {
          const payload = {
            objects: deposit,
            dispensaryId: deposit.dispensary_id,
          };

          this.depositStore.dispatch({
            type: POST_DEPOSIT,
            payload,
          });
          resolve(deposit);
        },
        err => reject(err)
      );
    });
  }

  public readAll(keys: { id: string }) {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesIdDepositsGet(keys.id).subscribe(
        deposits => {
          const payload = {
            dispensaryId: keys.id,
            objects: deposits,
          };

          this.depositStore.dispatch({
            type: GET_ALL_DEPOSITS,
            payload,
          });

          resolve(deposits);
        },
        err => reject(err)
      );
    });
  }
}
