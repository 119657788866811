import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CsvExportService {
  fileType = 'sales';
  mrbName = null;
  lineDelimiter = '\n';
  columnDelimiter = ',';
  filterMap = [];
  productKeyMap = [];
  dispensaryNameMap = null;
  constructor() {}

  exportFileAsCsv(fileToExport, type, mrbName = null, nameMap = null) {
    this.dispensaryNameMap = nameMap;
    this.mrbName = mrbName;
    this.fileType = type;
    let csv = this.assembleCsvFile(fileToExport);
    if (csv == null) {
      return;
    }
    if (!csv.match(/^data:text\/csv/i)) {
      csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    this.initiateDownload(csv, fileToExport[0]);
  }

  initiateDownload(csvFile, file) {
    const encodedCsv = encodeURI(csvFile);
    const filename = this.formatFileName(file);
    const link = document.createElement('a');
    link.setAttribute('href', encodedCsv);
    link.setAttribute('download', filename);
    link.click();
    this.cleanState();
  }

  cleanState() {
    this.productKeyMap = [];
    this.filterMap = [];
    this.fileType = null;
    this.dispensaryNameMap = null;
    this.mrbName = null;
  }

  formatFileName(file) {
    let date = '';
    if (file.hasOwnProperty('receipt_date')) {
      date = file['receipt_date'].split('T')[0];
    } else {
      date = file['deposit_date'].split('T')[0];
    }
    let fileName = '';
    if (this.mrbName) {
      fileName = `${this.fileType}_${this.formatMrbName()}_${date}.csv`;
    } else {
      fileName = `${this.fileType}_${date}.csv`;
    }
    return fileName;
  }

  formatMrbName() {
    const formattedName = this.mrbName.replace(/[\', \.]/, '');
    return formattedName.split(' ').join('_');
  }

  assembleCsvFile(data) {
    let result;

    if (data == null || !data.length) {
      return null;
    }

    const { keys, productKeys } = this.formatKeys(data[0]);
    result = this.formatCsvHeaders();

    data.forEach(item => {
      result += this.formatCsvFile(keys, productKeys, item);
      result += this.lineDelimiter;
    });

    return result;
  }

  formatKeys(data) {
    let productKeys = [];
    const keys = [];

    Object.keys(data).forEach(key => {
      if (key === 'products_sold') {
        productKeys = Object.keys(data['products_sold'][0]).map(productSold => this.filterProductKey(productSold));
      }
      const filteredKey = this.determineKeyFilter(key);
      if (filteredKey) {
        keys.push(filteredKey);
      }
    });

    return { keys, productKeys };
  }

  formatCsvHeaders() {
    let formattedHeaders = '';
    formattedHeaders += this.filterMap.join(this.columnDelimiter);
    formattedHeaders += this.columnDelimiter;
    formattedHeaders += this.productKeyMap.join(this.columnDelimiter);
    formattedHeaders += this.lineDelimiter;
    return formattedHeaders;
  }

  formatSaleData(key, item) {
    // FIXME used logging service instead of console.log(key);
    if (key === 'price_per_unit') {
      return `$${item[key] / 100}`;
    } else {
      return item[key];
    }
  }

  formatCsvFile(keys, productKeys, item) {
    let saleData;

    const customerAndAccountData = keys.map((key, i) => this.formatCustomerAndAccountData(key, item));

    if (item.hasOwnProperty('products_sold')) {
      saleData = this.formatProductsSold(item, keys.length, productKeys);
    }
    return customerAndAccountData.concat(saleData).join(this.columnDelimiter);
  }

  formatCustomerAndAccountData(key, item) {
    if (this.dispensaryNameMap !== null && key === 'dispensary_id') {
      return this.dispensaryNameMap[item.dispensary_id];
    }
    if (Array.isArray(item[key])) {
      return item[key].length;
    }
    if (item[key]) {
      if (key === 'tax' || key === 'total_paid' || key === 'price') {
        return `$${item[key] / 100}`;
      } else {
        return item[key];
      }
    }
  }

  formatProductsSold(item, numberOfColumns, productKeys) {
    const saleData = [];
    item['products_sold'].forEach((thatItem, itemCount) => {
      if (itemCount > 0) {
        const productDelimiter = this.columnDelimiter.repeat(numberOfColumns - 2);
        saleData.push(productDelimiter.split(','));
      }

      productKeys.forEach(key => {
        if (key) {
          saleData.push(this.formatSaleData(key, thatItem));
        }
      });
      saleData.push(this.lineDelimiter);
    });
    return saleData;
  }

  determineKeyFilter(key) {
    if (this.fileType === 'sales') {
      return this.filterSaleKey(key);
    } else if (this.fileType === 'deposit') {
      return this.filterDepositKey(key);
    }
  }

  filterSaleKey(key) {
    switch (key) {
      case 'dispensary_id':
        this.filterMap.push('CRB_Name');
        return key;
      case 'receipt_date':
        this.filterMap.push('receipt_date');
        return key;
      case 'status':
        this.filterMap.push('status');
        return key;
      case 'tax':
        this.filterMap.push('total_tax_amount');
        return key;
      case 'customer_id':
        this.filterMap.push('customer_id');
        return key;
      case 'products_sold':
        this.filterMap.push('num_products');
        return key;
      case 'payment_type':
        this.filterMap.push('payment_type');
        return key;
      case 'price':
        this.filterMap.push('sub_total');
        return key;
      case 'total_paid':
        this.filterMap.push('total_paid');
        return key;
    }
  }

  filterDepositKey(key) {
    switch (key) {
      case 'end_date':
        this.filterMap.push('sales_end_date');
        return key;
      case 'status':
        this.filterMap.push('status');
        return key;
      case 'comments':
        this.filterMap.push('comments');
        return key;
      case 'final_deposit':
        this.filterMap.push('final_deposit_amount');
        return key;
      case 'total_dollars_compliant':
        this.filterMap.push('total_amount_verified');
        return key;
      case 'deposit_date':
        this.filterMap.push('deposit_created_on_date');
        return key;
      case 'num_records_red_flagged':
        this.filterMap.push('num_sales_failed');
        return key;
      case 'num_records_green_flagged':
        this.filterMap.push('num_sales_total');
        return key;
      case 'max_deposit':
        this.filterMap.push('max_deposit_amount');
        return key;
      case 'start_date':
        this.filterMap.push('sales_start_date');
        return key;
      case 'total_amount_red_flagged':
        this.filterMap.push('total_amount_failed');
        return key;
      case 'deposit_Id':
        this.filterMap.push('deposit_id');
        return key;
      case 'total_dollars_sold':
        this.filterMap.push('total_sales');
        return key;
      case 'dispensary_id':
        this.filterMap.push('MRB_Name');
        return key;
    }
  }

  filterProductKey(key) {
    switch (key) {
      case 'product_id':
        this.productKeyMap.push('product_id');
        return key;
      case 'num_units':
        this.productKeyMap.push('units');
        return key;
      case 'price_per_unit':
        this.productKeyMap.push('pricePerUnit');
        return key;
    }
  }
}
