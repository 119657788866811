import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as base64arraybuffer from 'base64-arraybuffer';
import { MatDialog } from '@angular/material';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
})
export class UploadButtonComponent implements OnInit {
  @Input()
  textWhenEmpty: string;
  @Input()
  ctrl: FormGroup;
  @Input()
  targetFileType = '*';
  fileUrl: string;
  objUrl: string;
  delete = false;
  uploadIcon = '../../../../assets/Icon-Upload-Green.svg';
  uploadedIcon = '../../../../assets/Icon-FileUploaded.svg';
  deleteIcon = '../../../assets/Icon-Status-Flag.svg';
  successIcon = '../../../assets/Icon-Status-Green.svg';

  constructor(private ngxSmartModalService: NgxSmartModalService, private dialog: MatDialog) {}

  ngOnInit() {
    if (
      this.ctrl.controls &&
      this.ctrl.controls['data'] &&
      this.ctrl.controls['data'].value !== null &&
      /(.pdf)/.test(this.ctrl.controls['filename'].value)
    ) {
      const filename = this.ctrl.controls['filename'].value;
      if (this.targetFileType !== 'sales' && this.ctrl.controls['data'].value !== 'ALREADY FILLED') {
        const base64 = this.ctrl.controls['data'].value;
        const base64Array = base64arraybuffer.decode(base64);
        const file = new File([base64Array], filename, {
          type: 'application/pdf',
        });
        this.objUrl = window.URL.createObjectURL(file);
      }
    }
  }

  async view() {
    const modal = this.ngxSmartModalService.getModal('viewUploadedFile');
    const data = {};
    data['objUrl'] = this.objUrl;
    this.ngxSmartModalService.setModalData(data, 'viewUploadedFile');
    modal.open();

    modal.onAnyCloseEventFinished.subscribe(() => {
      this.ngxSmartModalService.resetModalData('viewUploadedFile');
    });
  }

  onFileEvent(event) {
    const file = event.target.files[0];
    if (file && /(.pdf)/.test(file.name)) {
      this.ctrl.controls['filename'].setValue(file.name);

      // save the file data as Base64 so it can be transferred across http
      const dataReader = new FileReader();
      if (this.targetFileType !== 'sales') {
        dataReader.onload = e => {
          const arrayBuffer = e.target['result'];
          const base64 = base64arraybuffer.encode(arrayBuffer);
          this.ctrl.controls['data'].setValue(base64);
        };
        dataReader.readAsArrayBuffer(file);
      } else {
        dataReader.onload = e => {
          const result = JSON.parse(e.target['result']);
          const formatted = JSON.stringify(result, null, 2);
          this.ctrl.controls['data'].setValue(formatted);
        };
        dataReader.readAsText(file);
      }
      // get a URL to the file data so the user can view it
      const urlReader = new FileReader();
      urlReader.onload = e => {
        this.objUrl = window.URL.createObjectURL(file);
        // FIXME used logging service instead of console.log(this.objUrl);
      };
      urlReader.readAsDataURL(file);
    } else {
      if (!this.ctrl.controls['filename'].value && !this.ctrl.controls['data'].value) {
        this.ctrl.controls['filename'].setValue('');
        this.ctrl.controls['data'].setValue(null);
      }
    }
  }

  buttonText() {
    if (this.ctrl.valid) {
      return this.ctrl.controls['filename'].value;
    } else if (this.textWhenEmpty && this.textWhenEmpty.length > 0) {
      return this.textWhenEmpty;
    } else {
      return 'select a file';
    }
  }

  accept() {
    if (this.targetFileType === 'audio') {
      return 'audio/*';
    } else if (this.targetFileType === 'image') {
      return 'image/*';
    } else if (this.targetFileType === 'video') {
      return 'video/mp4,video/*';
    } else if (this.targetFileType === 'sales') {
      return '.json';
    } else if (this.targetFileType === 'document') {
      return '.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (this.targetFileType === 'data') {
      return '.json,.txt,.csv';
    } else if (this.targetFileType === 'avatar') {
      return 'image/*';
    } else {
      return '*';
    }
  }

  showDisable(val) {
    if (
      (this.ctrl.controls && this.ctrl.controls['data'] && this.ctrl.controls['data'].value) ||
      (this.ctrl.controls && this.ctrl.controls['s3Key'].value)
    ) {
      this.delete = val;
    }
  }

  removeFile() {
    this.ctrl.controls.s3Key.setValue('');
    this.ctrl.controls.data.setValue('');
    this.ctrl.controls.filename.setValue('');
  }
}
