import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as base64arraybuffer from 'base64-arraybuffer';
import { HttpClient } from '@angular/common/http';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-view-uploaded-file',
  templateUrl: './view-uploaded-file.component.html',
  styleUrls: ['./view-uploaded-file.component.scss'],
})
export class ViewUploadedFileComponent implements OnInit {
  @ViewChild('pdfView')
  pdfView: ElementRef;

  objUrl: string;
  s3KeyUrl: string;
  type: string;

  constructor(private httpClient: HttpClient, public ngxSmartModalService: NgxSmartModalService) {}

  ngOnInit() {
    const viewModal = this.ngxSmartModalService.getModal('viewUploadedFile');
    viewModal.onDataAdded.subscribe(data => {
      if (data.objUrl) {
        this.objUrl = data.objUrl;
        this.pdfView.nativeElement.src = this.objUrl;
      } else if (data.s3KeyUrl) {
        this.type = 'application/pdf';
        if (data.hasOwnProperty('type')) {
          this.type = 'image/jpeg';
        }
        this.s3KeyUrl = data.s3KeyUrl;
        this.fetchDataFromS3();
      } else {
        throw Error('must pass in either an objUrl from window.URL.createObjectURL or an s3KeyUrl');
      }
    });
  }

  fetchDataFromS3() {
    const self = this;
    this.getPdfData(this.s3KeyUrl).subscribe(
      (res: Blob) => {
        const fileReader = new FileReader();
        fileReader.onload = e => {
          const filename = 'filename';
          const base64 = e.target['result'];
          const base64Array = base64arraybuffer.decode(base64);
          const file = new File([base64Array], filename, {
            type: this.type,
          });
          const objUrlFromS3 = window.URL.createObjectURL(file);
          self.pdfView.nativeElement.src = objUrlFromS3;
        };
        fileReader.readAsBinaryString(res);
      },
      err => {
        console.error(err);
      }
    );
  }

  getPdfData(s3KeyUrl: string): any {
    const cacheBusterUrl = `${s3KeyUrl}`;
    return this.httpClient.get(cacheBusterUrl, {
      responseType: 'blob',
    });
  }
}
