import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LoggerService } from 'src/services/logger/logger.service';
import { DispensaryDataService } from 'src/services/actions/dispensary.action';
import { UserDataService } from 'src/services/actions/user.action';

@Component({
  selector: 'app-organizations-edit',
  templateUrl: './gcv-organizations-edit-dispensary.component.html',
  styleUrls: ['./gcv-organizations-edit-dispensary.component.scss'],
})
export class GcvOrganizationsEditDispensaryComponent {
  dispensary: any;
  selectedUser: any;
  users = [];
  removePrimary = false;
  constructor(
    public dialogRef: MatDialogRef<GcvOrganizationsEditDispensaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private logger: LoggerService,
    private dispService: DispensaryDataService,
    private userService: UserDataService
  ) {
    this.dispensary = data.dispensary;
    this.users = data.users;
  }

  saveUser() {
    if (
      this.removePrimary &&
      (this.selectedUser.lastName !== this.dispensary.primaryContact.lastName ||
        this.selectedUser.firstName !== this.dispensary.primaryContact.firstName)
    ) {
      this.logger.info('Updating Primary Contact');
      let previousPrimary;
      this.users.forEach(user => {
        if (
          user.lastName === this.dispensary.primaryContact.lastName &&
          user.firstName === this.dispensary.primaryContact.firstName
        ) {
          previousPrimary = user;
        }
      });
      this.userService
        .update({ id: previousPrimary.id }, { ...previousPrimary, primaryContact: false })
        .then(() => {
          this.userService
            .update({ id: this.selectedUser.id }, { ...this.selectedUser, primaryContact: true })
            .then(() => {
              const primaryContactDetails = {
                address: this.selectedUser.address,
                city: this.selectedUser.city,
                dateOfBirth: this.selectedUser.dateOfBirth,
                email: this.selectedUser.email,
                firstName: this.selectedUser.firstName,
                idNumber: this.selectedUser.idNumber,
                idType: this.selectedUser.idType,
                lastName: this.selectedUser.lastName,
                phone: this.selectedUser.phone,
                state: this.selectedUser.state,
                title: this.selectedUser.title,
                zipcode: this.selectedUser.zipcode,
              };
              this.dispService
                .update({ id: this.dispensary.id }, { ...this.dispensary, primaryContact: primaryContactDetails })
                .then(() => this.close())
                .catch(err => {
                  this.logger.error(err);
                  this.close();
                });
            })
            .catch(err => this.logger.error(err));
        })
        .catch(err => this.logger.error(err));
    } else {
      this.close();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
