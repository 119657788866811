import * as _ from 'underscore';
// import { ModelDeposit as Deposit } from '@gcv/shared';
import { GET_ALL_SALES, PUT_SALES, RESET_SALES } from '../action.types';

export interface AppModel {
  sales: {};
}

export interface AppStateAction {
  type: string;
  payload: {
    objects: [any];
    dispensaryId: string;
  };
}

const initialState: AppModel = {
  sales: {},
};

export function salesReducer(state: AppModel = initialState, action: AppStateAction) {
  switch (action.type) {
    case GET_ALL_SALES:
      if (action.payload.objects) {
        if (state.sales[action.payload.dispensaryId]) {
          state.sales[action.payload.dispensaryId] = action.payload.objects;
          return state;
        }
        state.sales[action.payload.dispensaryId] = action.payload.objects;
        return state;
      }
      return state;
    case PUT_SALES:
      const copy = state.sales;
      copy[action.payload.dispensaryId] = action.payload.objects;
      return {
        sales: copy,
      };
    case RESET_SALES:
      return {
        sales: {},
      };
    default:
      return state;
  }
}
