import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BankAppFamilyService } from '../../../../../services/bank.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { S3FileManagementService } from '../../../../../services/s3FileManagement.service';

@Component({
  selector: 'app-view-account',
  templateUrl: './view-account.component.html',
  styleUrls: ['./view-account.component.scss'],
})
export class ViewAccountComponent implements OnInit {
  account: any;
  docMap = {
    cannabisLicenseCopy: 'Cannabis License',
    balanceDoc: 'Balance Sheet',
    leaseDoc: 'Commercial Lease',
    taxDoc: 'Tax Return',
    incomeDoc: 'Income Statement',
    policyDoc: 'Policies and Procedures',
    certificateOfGoodStanding: 'Certificate of Good Standing',
    beneficialOwnerDoc: 'Beneficial Ownership',
    articlesDoc: 'Articles of Incorporation',
  };

  constructor(
    private route: ActivatedRoute,
    private bankApp: BankAppFamilyService,
    private ngxSmartModalService: NgxSmartModalService,
    private s3FileManagement: S3FileManagementService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const report = this.bankApp.getReportById(params['id']);
      this.account = this.bankApp.getDispensary(report.dispensary_id);
    });
  }

  async reviewDocument(doc) {
    // FIXME used logging service instead of console.log(doc);
    const modal = this.ngxSmartModalService.getModal('reviewDocument');
    const data = {};
    this.s3FileManagement.get(doc.s3Key).then(url => {
      data['s3KeyUrl'] = url;
      data['documentName'] = doc.name;
      data['status'] = 'reviewed';
      this.ngxSmartModalService.setModalData(data, 'reviewDocument');
      modal.open();
    });
    modal.onAnyCloseEventFinished.subscribe(() => {
      this.ngxSmartModalService.resetModalData('reviewDocument');
    });
  }
}
