import * as _ from 'underscore';
// import { ModelDeposit as Deposit } from '@gcv/services/model/models';
import { GET_ALL_SALES_FILES, PUT_SALES_FILE, RESET_SALES_FILES } from '../action.types';

export interface AppModel {
  salesFiles: {};
}

export interface AppStateAction {
  type: string;
  payload: {
    objects: [any];
    dispensaryId: string;
  };
}

const initialState: AppModel = {
  salesFiles: {},
};

export function salesFileReducer(state: AppModel = initialState, action: AppStateAction) {
  switch (action.type) {
    case GET_ALL_SALES_FILES:
      if (action.payload.objects) {
        if (state.salesFiles[action.payload.dispensaryId]) {
          state.salesFiles[action.payload.dispensaryId] = action.payload.objects;
          return state;
        }
        state.salesFiles[action.payload.dispensaryId] = action.payload.objects;
        return state;
      }
      return state;
    case PUT_SALES_FILE:
      const copy = state.salesFiles;
      copy[action.payload.dispensaryId].push(action.payload.objects);
      return {
        salesFiles: copy,
      };
    case RESET_SALES_FILES:
      return {
        salesFiles: {},
      };
    default:
      return state;
  }
}
