import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as _ from 'underscore';

// import { AppModel } from '../gcv.reducer';
import { RESET_REPORTS, PUT_REPORTS, GET_ALL_REPORTS, POST_REPORT } from '../action.types';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class FincenDataService {
  private fincenStore;

  constructor(private sdk: DefaultService, private store: Store<any>) {
    this.fincenStore = store.select('fincen');
  }

  public subscribe(observer) {
    return this.fincenStore.subscribe(observer);
  }

  public clearDomain() {
    this.fincenStore.dispatch({
      type: RESET_REPORTS,
    });
  }

  public create(data: object) {
    return new Promise((resolve, reject) => {
      this.sdk.fincenReportsPost(data).subscribe(
        report => {
          const payload = {
            objects: report,
            dispensaryId: report.dispensary_id,
          };

          this.fincenStore.dispatch({
            type: POST_REPORT,
            payload,
          });

          resolve(report);
        },
        err => reject(err)
      );
    });
  }

  public readAll(keys: { dispensaryId: string; bankId: string }) {
    return new Promise((resolve, reject) => {
      this.sdk.fincenReportsBankIdDispensaryIdGet(keys.bankId, keys.dispensaryId).subscribe(
        reports => {
          const payload = {
            dispensaryId: keys.dispensaryId,
            objects: reports,
          };
          this.fincenStore.dispatch({
            type: GET_ALL_REPORTS,
            payload,
          });
          resolve(reports);
        },
        err => reject(err)
      );
    });
  }

  public update(data: any) {
    return new Promise((resolve, reject) => {
      this.sdk.fincenReportsBankIdPut(data.bank_id, data).subscribe(
        (report: any) => {
          this.fincenStore.dispatch({
            type: PUT_REPORTS,
            payload: {
              objects: report,
              dispensaryId: report.dispensary_id,
            },
          });
          resolve(report);
        },
        err => reject(err)
      );
    });
  }
}
