import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RoutesRecognized, ActivatedRoute } from '@angular/router';

import { filter, pairwise } from 'rxjs/operators';
import { Location } from '@angular/common';
import { GcvAppFamilyService } from '../../../services/gcvApp.service';
import { AuthService } from '../../auth/auth.service';

/*
  This shell communicates with its children via a Family-Service.
  See: https://angular.io/guide/component-interaction#parent-and-children-communicate-via-a-service
*/

@Component({
  templateUrl: './gcv-shell.component.html',
  styleUrls: ['./gcv-shell.component.scss'],
})
export class GcvShellComponent implements OnInit, OnDestroy {
  public view = 'dashboard';

  constructor(
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private gcvApp: GcvAppFamilyService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.gcvApp.initState();
    this.activatedRoute.url.subscribe(() => this.manageUrlStream());
  }

  ngOnDestroy() {
    this.gcvApp.resetState();
  }

  setView(view) {
    this.view = view;
    this.router.navigate([`${view}`]);
  }

  manageUrlStream(): void {
    this.view = this.location.path().split('?')[0];
  }

  logout() {
    this.gcvApp.resetState();
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
