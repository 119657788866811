import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-due-diligence-wrapper',
  templateUrl: './due-diligence-wrapper.component.html',
  styleUrls: ['./due-diligence-wrapper.component.scss'],
})
export class DueDiligenceWrapperComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() dispensaryName: string;
  @Input() routeName: string;
  @Input() disabled = false;
  @Input() previousDisabled = false;
  @Input() previousText = 'Back';
  @Output() breadCrumbClicked = new EventEmitter();
  @Output() saveClicked = new EventEmitter();
  @Output() backPressed = new EventEmitter();
  @Input() buttonText = 'Next';

  constructor() {}

  ngOnInit() {}

  handleBreadCrumbClicked() {
    this.breadCrumbClicked.emit();
  }

  btnPressed() {
    this.saveClicked.emit();
  }

  back() {
    this.backPressed.emit();
  }
}
