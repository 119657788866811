import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';

// import { ModelDispensary as Dispensary } from '@gcv/shared';
import { DepositDataService } from './actions/deposit.action';
import { DispensaryDataService } from './actions/dispensary.action';
import { FincenDataService } from './actions/fincen.action';
import { SalesDataService } from './actions/sales.action';
import { SalesFileDataService } from './actions/salesFile.action';
import { BankDataService } from './actions/bank.action';
// import { ModelBank as Bank } from '@gcv/shared';
import { IdentitityService } from './actions/identities.action';
import { LoggerService } from './logger/logger.service';
import { SecureAppFamilyService } from './secure.service';

/*
  This service provides communication for the GCV App family of components.
  See: https://angular.io/guide/component-interaction#parent-and-children-communicate-via-a-service
*/

@Injectable({
  providedIn: 'root',
})
export class GcvAppFamilyService {
  private dispensaries: any[];
  private banks: any[];
  public dispensaries$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private secureApp: SecureAppFamilyService,
    private logger: LoggerService,
    private depositDataService: DepositDataService,
    private dispensaryDataService: DispensaryDataService,
    private fincenDataService: FincenDataService,
    private salesDataService: SalesDataService,
    private salesFileDataService: SalesFileDataService,
    private identitityService: IdentitityService,
    private bankDataService: BankDataService
  ) {}

  initState() {
    this.resetState();
    this.dispensaryDataService
      .readAll()
      .then((dispensaries: any[]) => {
        this.dispensaries = dispensaries;
        this.dispensaries$.next(this.dispensaries);
      })
      .catch(err => this.logger.error(err));
    this.bankDataService
      .readAll()
      .then((banks: any[]) => {
        this.banks = banks;
      })
      .catch(err => this.logger.error(err));

    this.bankDataService.subscribe(state => {
      this.banks = state.banks;
    });

    this.dispensaryDataService.subscribe(state => {
      if (this.dispensaries) {
        if (state.dispensaries.hasOwnProperty('id')) {
          this.dispensaries.push(state.dispensaries);
        }
      }
    });
  }

  resetState() {
    this.identitityService.clearDomain();
    this.bankDataService.clearDomain();
    this.depositDataService.clearDomain();
    this.dispensaryDataService.clearDomain();
    this.fincenDataService.clearDomain();
    this.salesDataService.clearDomain();
    this.salesFileDataService.clearDomain();
  }

  getDispensaries(): any[] {
    return this.dispensaries;
  }

  getBanks(): any[] {
    return this.banks;
  }

  subscribeToDispensaries(observer) {
    this.dispensaryDataService.subscribe(observer);
  }

  gcvApproveReview(dispensary: any, callback) {
    const data = {
      reviewer: this.secureApp.getUser().id,
      status: 'reviewed',
    };
    this.dispensaryDataService
      .updateGcvDiligence({ id: dispensary.id }, data)
      .then(createdDispensary => {
        this.updateDispensaries(createdDispensary);
        callback(null, createdDispensary);
      })
      .catch(err => {
        this.logger.error(err);
        callback(err, null);
      });
  }

  updateDispensaries(updatedDispensary) {
    const index = _.findIndex(this.dispensaries, dispensary => {
      return dispensary.id === updatedDispensary.id;
    });
    this.dispensaries[index] = updatedDispensary;
    this.dispensaries$.next(this.dispensaries);
  }
}
