import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-zero-state',
  templateUrl: './zero-state.component.html',
  styleUrls: ['./zero-state.component.scss'],
})
export class ZeroStateComponent implements OnInit {
  @Input() headerText = ``;
  @Input() subText = ``;

  constructor() {}

  ngOnInit() {}
}
