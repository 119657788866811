import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfirmDialogComponent } from '../../app/gcv-internal/common/confirmDialog.component';
import { User } from '@gcv/shared';
import { UserDataService } from '../../services/actions/user.action';
import { DispensaryAppFamilyService } from '../../services/dispensary.service';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss'],
})
export class EditUserComponent implements OnInit {
  message = '';
  userDetails: FormGroup;
  user: User;
  roles = [{ viewValue: 'Admin', value: 'bank_admin' }, { viewValue: 'Staff', value: 'bank_user' }];
  constructor(
    private matDialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
    private matDialog: MatDialog,
    private userApp: UserDataService,
    private snackBar: MatSnackBar,
    private dispApp: DispensaryAppFamilyService
  ) {
    if (data.user) {
      this.user = data.user;
    } else {
      this.close();
    }
  }

  ngOnInit() {
    this.userDetails = new FormGroup({
      firstName: new FormControl(this.user.firstName, Validators.required),
      lastName: new FormControl(this.user.lastName, Validators.required),
      email: new FormControl(this.user.email, Validators.required),
      role: new FormControl(this.user.role ? this.user.role : '', Validators.required),
    });
  }

  saveUser() {
    const updatedUser = { ...this.user, ...this.userDetails.getRawValue() };
    this.userApp
      .updateStaff({ id: updatedUser.id }, updatedUser)
      .then(() => {
        this.close();
        this.snackBar.open('User has been updated', '', {
          duration: 3000,
        });
      })
      .catch(() => {
        this.close();
        this.snackBar.open('An error occured updating user.', '', {
          duration: 3000,
        });
      });
  }

  close() {
    this.matDialogRef.close();
  }

  deleteUser() {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: { message: 'Are you sure you wish to delete this user?' },
    });
    dialog.afterClosed().subscribe(data => {
      if (data.confirmed) {
        this.userApp
          .updateStaff({ id: this.user.id }, { ...this.user, ...{ companies: [] } })
          .then(() => {
            this.close();
            this.snackBar.open('User has been updated', '', {
              duration: 3000,
            });
          })
          .catch(() => {
            this.close();
            this.snackBar.open('An error occured updating user.', '', {
              duration: 3000,
            });
          });
      } else {
        this.close();
      }
    });
  }
}
