import * as _ from 'underscore';
import {
  GET_DISPENSARY,
  REMOVE_DISPENSARIES,
  PUT_DISPENSARY,
  GET_ALL_DISPENSARIES,
  POST_DISPENSARY,
} from '../action.types';

export interface AppModel {
  dispensaries: {};
}

export interface AppStateAction {
  type: string;
  payload: {
    objects: any[];
    dispensaryId: string;
  };
}

const initialState: AppModel = {
  dispensaries: {},
};

export function dispensaryReducer(state: AppModel = initialState, action: AppStateAction) {
  switch (action.type) {
    case GET_DISPENSARY:
      if (action.payload.objects) {
        if (state.dispensaries[action.payload.dispensaryId]) {
          state.dispensaries[action.payload.dispensaryId] = action.payload.objects;
          return state;
        }
        state.dispensaries[action.payload.dispensaryId] = action.payload.objects;
        return state;
      }
      return state;
    case PUT_DISPENSARY:
      const copy = state.dispensaries;
      copy[action.payload.dispensaryId] = action.payload.objects;
      return {
        dispensaries: copy,
      };
    case REMOVE_DISPENSARIES:
      return {
        dispensaries: {},
      };
    case POST_DISPENSARY:
      const stateCopy = state.dispensaries as any[];
      return {
        dispensaries: stateCopy[action.payload.dispensaryId] = action.payload.objects,
      };
    case GET_ALL_DISPENSARIES:
      const data = {};
      action.payload.objects.forEach(dispensary => (data[dispensary.id] = dispensary));
      return {
        dispensaries: data,
      };
    default:
      return state;
  }
}
