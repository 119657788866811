import { Environment } from './env-interface';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: false,
  amplify: {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:d084a534-cd60-4d04-8d81-3ccb93306eea',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_7xmr3oc1b',
    aws_user_pools_web_client_id: '5i7ppuajt786jvnl3md01i0j9f',
  },
  basePath: 'https://670f4ksx2k.execute-api.us-east-1.amazonaws.com/fkich',
  storageConfig: {
    salesFile: 'fkich-new-data-file-bucket',
    document: 'fkich-gcv-company-documents',
    regulatoryGuide: '',
  },
  env: 'fkich',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
