import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.scss'],
})
export class CreateUserDialogComponent implements OnInit {
  data: FormGroup;
  roles = [];
  bank: any;
  roleMap = {
    dispensary: [{ viewValue: 'Admin', value: 'dispensary_admin' }, { viewValue: 'User', value: 'dispensary_user' }],
    gcv: [{ viewValue: 'Admin', value: 'gcv_admin' }, { viewValue: 'User', value: 'gcv_user' }],
    bank: [{ viewValue: 'Admin', value: 'bank_admin' }, { viewValue: 'User', value: 'bank_user' }],
  };

  constructor(
    private matDialogRef: MatDialogRef<CreateUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit() {
    this.roles = this.roleMap[this.dialogData.orgType];
    this.data = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      primaryContact: new FormControl(false),
      role: new FormControl('', Validators.required),
    });
  }

  isDisabled() {
    return !this.data.valid;
  }

  handleCreateUser() {
    this.matDialogRef.close({ user: this.data.getRawValue() });
  }
}
