import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GcvAppFamilyService } from '../../../../services/gcvApp.service';
import { Bank, Dispensary } from '@gcv/shared';
import { UtilsService } from '../../../../services/utils.service';
import { Location } from '@angular/common';
import { CognitoService } from '../../../auth/cognito.service';
import { UserDataService } from '../../../../services/actions/user.action';
import { LoggerService } from '../../../../services/logger/logger.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-gcv-create-user',
  templateUrl: './gcv-create-user.component.html',
  styleUrls: ['gcv-create-user.component.scss'],
})
export class GcvCreateUserComponent {
  type = '';
  selectedOrg: any;
  organizations = [];
  userRoles = [];
  step = 0;
  userData: FormGroup;
  isPrimaryContact = false;

  constructor(
    private gcvApp: GcvAppFamilyService,
    private utils: UtilsService,
    private location: Location,
    private cognitoService: CognitoService,
    private userDataService: UserDataService,
    private logger: LoggerService,
    private snackBar: MatSnackBar
  ) {
    this.userData = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      minitial: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      companies: new FormControl([], Validators.required),
      role: new FormControl(''),
      primaryContact: new FormControl(false),
    });
  }

  handleButtonClicked() {
    this.step++;
    if (this.step === 1) {
      this.setupOrgs();
    }
  }

  setupOrgs() {
    switch (this.type) {
      case 'fi':
        this.organizations = this.gcvApp.getBanks().map((bank: Bank) => {
          const data = {
            value: {
              companyType: 'bank',
              id: bank.id,
            },
            viewValue: bank.orgName,
          };
          return data;
        });
        this.userRoles = [{ value: 'bank_admin', viewValue: 'Admin' }, { value: 'bank_user', viewValue: 'User' }];
        break;
      case 'crb':
        this.organizations = this.gcvApp.getDispensaries().map((disp: Dispensary) => {
          const data = {
            value: {
              companyType: 'dispensary',
              id: disp.id,
            },
            viewValue: disp.name,
          };
          return data;
        });
        this.userRoles = [
          { value: 'dispensary_admin', viewValue: 'Admin' },
          { value: 'dispensary_user', viewValue: 'User' },
        ];
        break;
      case 'gcv':
        this.organizations = [{ value: { companyType: 'gcv', id: 1 }, viewValue: 'Green Check Verified' }];
        this.userRoles = [{ value: 'gcv_admin', viewValue: 'Admin' }, { value: 'gcv_user', viewValue: 'User' }];
        break;
      default:
        this.step = 0;
        this.type = '';
        break;
    }
  }

  selectOrg(e) {
    this.selectedOrg = e.value;
  }

  invite() {
    const userData: any = this.utils.filterOutEmptyProperties(this.userData.getRawValue());
    userData['companies'] = [userData.companies];
    userData['username'] = userData.email;
    this.userDataService
      .createUser(userData)
      .then(() => {
        this.snackBar.open('You have successfully invited a user', '', {
          duration: 3000,
        });
        this.cancel();
      })
      .catch(err => {
        this.logger.error(err);
        this.cancel();
      });
  }

  cancel() {
    this.location.back();
  }
}
