import * as _ from 'underscore';
import { GET_BANK, PUT_BANK, RESET_BANK, GET_ALL_BANKS, POST_BANK } from '../action.types';

export interface AppModel {
  banks: any[];
}

export interface AppStateAction {
  type: string;
  payload: {
    objects: any;
    dispensaryId: string;
  };
}

const initialState: AppModel = {
  banks: [],
};

export function bankReducer(state: AppModel = initialState, action: AppStateAction) {
  switch (action.type) {
    case GET_BANK:
      const bankCopy: any[] = state.banks;
      return {
        banks: bankCopy.concat([action.payload.objects]),
      };
    case GET_ALL_BANKS:
      return {
        banks: action.payload.objects,
      };
    case RESET_BANK:
      return {
        banks: [],
      };
    case PUT_BANK:
      if (state.banks.length > 1) {
        return {
          banks: state.banks.map((bank: any) => {
            if (bank.id === action.payload.objects.id) {
              return action.payload.objects;
            } else {
              return bank;
            }
          }),
        };
      }
      return {
        banks: [action.payload.objects],
      };
    case POST_BANK:
      const copy = state.banks;
      return {
        banks: copy.concat([action.payload.objects]),
      };
    default:
      return state;
  }
}
