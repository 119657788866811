import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ButtonComponent } from './button.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as base64arraybuffer from 'base64-arraybuffer';

@Component({
  selector: 'app-file-button',
  templateUrl: './file-button.component.html',
  styleUrls: ['shared.button.style.scss'],
})
export class FileButtonComponent extends ButtonComponent implements OnInit {
  // TODO Add To btn-file in live (change)="onFileEvent($event)
  @Output() fileUploaded = new EventEmitter();
  @Input()
  ctrl: FormGroup;
  targetFileType = 'sales';
  objUrl: string;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onFileEvent(event) {
    const file = event.target.files[0];
    if (file) {
      this.ctrl.controls['filename'].setValue(file.name);

      // save the file data as Base64 so it can be transferred across http
      const dataReader = new FileReader();
      if (this.targetFileType !== 'sales') {
        dataReader.onload = e => {
          const arrayBuffer = e.target['result'];
          const base64 = base64arraybuffer.encode(arrayBuffer);
          this.ctrl.controls['data'].setValue(base64);
        };
        dataReader.readAsArrayBuffer(file);
      } else {
        dataReader.onload = e => {
          const result = JSON.parse(e.target['result']);
          const formatted = JSON.stringify(result, null, 2);
          this.ctrl.controls['data'].setValue(formatted);
        };
        dataReader.onloadend = () => {
          // FIXME used logging service instead of console.log('upload Done');
          this.fileUploaded.emit();
        };

        dataReader.readAsText(file);
      }
      // get a URL to the file data so the user can view it
      const urlReader = new FileReader();
      urlReader.onload = e => {
        this.objUrl = window.URL.createObjectURL(file);
      };
      urlReader.readAsDataURL(file);
    } else {
      if (!this.ctrl.controls['filename'].value && !this.ctrl.controls['data'].value) {
        this.ctrl.controls['filename'].setValue('');
        this.ctrl.controls['data'].setValue(null);
      }
    }
  }

  test() {
    // FIXME used logging service instead of console.log(this);
  }
}
