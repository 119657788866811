import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import * as _ from 'underscore';
import { DateTime } from 'luxon';

import { BankUtilService } from './bank-utility.service';
import { Router } from '@angular/router';
import { SessionService } from './session.service';
import { DepositDataService } from './actions/deposit.action';
import { SalesDataService } from './actions/sales.action';
import { FincenDataService } from './actions/fincen.action';
import { SalesFileDataService } from './actions/salesFile.action';
import { DispensaryDataService } from './actions/dispensary.action';
import { UserDataService } from './actions/user.action';
import { AggregateDataService } from './actions/aggregates.action';
import { LoggerService } from './logger/logger.service';
import { SecureAppFamilyService } from './secure.service';
import { Dispensary, Deposit, Sale, SaleFile } from '@gcv/schemas';
import { Location } from '@angular/common';
import { UtilsService } from './utils.service';

/*
  This service provides communication for the Dispensary App family of components
  See: https://angular.io/guide/component-interaction#parent-and-children-communicate-via-a-service
*/

@Injectable({
  providedIn: 'root',
})
export class DispensaryAppFamilyService {
  private dispensary: Dispensary;
  private dataRequestsToMake = 0;
  private dataRequestsCompleted = 0;
  public showNotification = new BehaviorSubject<boolean>(true);
  public view = new Subject();
  private notifications: any[];
  private previousRoute = '';
  private allDataLoaded = false;
  private allUsers = [];
  dispensarySubscription: any;
  private allDataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public timeFilter = new BehaviorSubject<string>('thisMonth');
  private deposits: Deposit[];
  private deposits$: BehaviorSubject<Deposit[]> = new BehaviorSubject<Deposit[]>([]);

  private newDeposit: Deposit; // Currently depositable funds

  private salesAggregate = {};
  private salesAggregate$: BehaviorSubject<object> = new BehaviorSubject<object>({});
  public notificationsStream$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  public newDepositPoller: any;
  public newDepositStream$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private sales: Sale[];
  private sales$: BehaviorSubject<Sale[]> = new BehaviorSubject<Sale[]>([]);

  private salesFiles: SaleFile[];
  private salesFiles$: BehaviorSubject<SaleFile[]> = new BehaviorSubject<SaleFile[]>([]);

  private dashboardAggregate: any = {};
  public dashboardAggregateStream = new BehaviorSubject<any>({});

  constructor(
    private bankUtil: BankUtilService,
    private session: SessionService,
    private router: Router,
    private secureApp: SecureAppFamilyService,
    private depositDataService: DepositDataService,
    private salesDataService: SalesDataService,
    private fincenDataService: FincenDataService,
    private salesFileDataService: SalesFileDataService,
    private dispensaryDataService: DispensaryDataService,
    private userDataService: UserDataService,
    private aggregateDataService: AggregateDataService,
    private logger: LoggerService,
    private store: Store<any>,
    private location: Location,
    private utils: UtilsService
  ) {
    this.view.subscribe((data: any) => {
      if (data.hasOwnProperty('view')) {
        this.router.navigate([`${data.view}`]);
      }
    });
  }

  navigateBack() {
    if (this.previousRoute.length > 0) {
      this.router.navigate([this.previousRoute]);
    } else {
      this.location.back();
    }
  }

  setPreviousUrl(url) {
    this.previousRoute = url;
  }

  initState() {
    // TODO note from Danny to Jon: this might be an approach to have the family services update all subscribers when there are changes
    //      to data after the initial state has been loaded ie. a deposit is submitted
    this.store.select('deposits').subscribe(allDeposits => {
      if (!_.isEmpty(allDeposits.deposits)) {
        if (allDeposits.deposits[this.dispensary.id]) {
          this.deposits = allDeposits.deposits[this.dispensary.id];
          this.deposits$.next(this.deposits);
        }
      }
    });
    // TODO end note

    // this.dispensary = this.getDispIfObjLoaded();
    this.resetState();
    this.aggregateDataService
      .getAggregate({ companyId: this.dispensary.id, type: 'sales' })
      .then((aggregates: object) => {
        this.salesAggregate = aggregates;
        this.salesAggregate$.next(this.salesAggregate);
        this.dataRequestsCompleted++;
        this.checkIfDataLoadCompleted();
      })
      .catch(err => this.logger.error(err));

    this.dataRequestsToMake = 5; // TODO allDeposits + allSalesFiles + (N * salesDateRanges)

    this.salesFileDataService
      .readAll({ id: this.dispensary.id })
      .then((salesFiles: SaleFile[]) => {
        this.salesFiles = salesFiles;
        this.salesFiles$.next(this.salesFiles);
        this.dataRequestsCompleted++;
        this.checkIfDataLoadCompleted();
      })
      .catch(err => this.logger.error(err));

    this.depositDataService
      .readAll({ id: this.dispensary.id })
      .then((deposits: Deposit[]) => {
        this.deposits = deposits;
        this.deposits$.next(this.deposits);
        this.dataRequestsCompleted++;
        this.checkIfDataLoadCompleted();
      })
      .catch(err => this.logger.error(err));

    this.depositDataService
      .getNewDeposit({ id: this.dispensary.id })
      .then((deposit: Deposit) => {
        this.newDeposit = deposit;
        this.dataRequestsCompleted++;
        this.checkIfDataLoadCompleted();
      })
      .catch(err => this.logger.error(err));

    this.userDataService
      .getNotifications({ id: this.getUserIdIfLoaded() })
      .then((notifications: any[]) => {
        if (notifications) {
          this.notifications = notifications.filter(notification => {
            if (notification.type === 'completed_dispensary_review') {
              return notification;
            }
          });
          this.notificationsStream$.next(this.notifications);
          this.dataRequestsCompleted++;
          this.checkIfDataLoadCompleted();
        }
        return [];
      })
      .catch(err => this.logger.error(err));

    this.userDataService
      .readAll()
      .then((users: any[]) => {
        const filteredUsers = users.filter(user => {
          if (this.userBelongsToCompany(user.companies)) {
            return user;
          }
        });
        this.allUsers = filteredUsers;
      })
      .catch(err => this.logger.error(err));
  }

  userBelongsToCompany(companies) {
    const dispId = this.dispensary.id;
    let userBelongsToCompany = false;
    companies.forEach(company => {
      if (company.id === dispId) {
        userBelongsToCompany = true;
        return;
      } else if (!userBelongsToCompany) {
        return;
      } else {
        return;
      }
    });
    return userBelongsToCompany;
  }

  getUserIdIfLoaded(): string {
    const loadedUserId: any = this.secureApp.getUser();
    if (loadedUserId.id) {
      return loadedUserId.id;
    }
    return this.session.getSessionProperty('userId');
  }

  getAllUsersForCompany() {
    return this.allUsers;
  }

  refetchAllUsersForCompany() {
    return new Promise((resolve, reject) => {
      this.userDataService
        .readAll()
        .then((users: any[]) => {
          const filteredUsers = users.filter(user => {
            if (this.userBelongsToCompany(user.companies)) {
              return user;
            }
          });
          this.allUsers = filteredUsers;
          // FIXME used logging service instead of console.log(this.allUsers);
          resolve(filteredUsers);
        })
        .catch(err => {
          this.logger.error(err);
          reject(err);
        });
    });
  }

  getDispIfObjLoaded(): Dispensary {
    // use the instance variable bank obj if it exists
    if (this.dispensary) {
      return this.dispensary;
    }
    // use the user's company loaded by the secure app if it exists
    const loadedDispensary: Dispensary = this.secureApp.getCompany() as Dispensary;
    if (loadedDispensary) {
      this.session.setSessionProperty('dispensary', JSON.stringify(loadedDispensary));
      return loadedDispensary;
    }
    // use the dispensary in the browser sessin if it exists
    const strDispInSession = this.session.getSessionProperty('dispensary');
    const dispInSession = JSON.parse(strDispInSession);
    if (dispInSession) {
      return dispInSession;
    }
    return null;
  }

  loadDispensary(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.dispensary) {
        resolve(this.dispensary);
      } else {
        const dispensary = this.secureApp.getCompany();
        this.dispensaryDataService.read({ id: dispensary.id }).then((disp: Dispensary) => {
          this.dispensary = disp;
          resolve(disp);
        });
      }
    });
  }

  resetState() {
    // this.dispensaryDataService.clearDomain();
    this.depositDataService.clearDomain();
    this.fincenDataService.clearDomain();
    this.salesDataService.clearDomain();
    this.userDataService.clearDomain();
    this.salesFileDataService.clearDomain();
  }

  checkIfDataLoadCompleted() {
    if (this.dataRequestsCompleted === this.dataRequestsToMake) {
      this.allDataLoaded = true;
      this.allDataLoaded$.next(this.allDataLoaded);
      // lots of parallel calls for the sales we're made so only update the sales stream when all the sales data is loaded
      this.sales$.next(this.sales);
      this.setupStreams();
    }
  }

  setupStreams() {
    this.salesFileDataService.subscribe(state => {
      this.salesFiles$.next(state.salesFiles);
    });

    this.userDataService.subscribe(state => {
      if (state.notifications) {
        this.notifications = state.notifications.filter(notification => {
          if (notification.type === 'completed_dispensary_review') {
            return notification;
          }
        });
        this.notificationsStream$.next(this.notifications);
      }
    });

    this.dispensaryDataService.subscribe(state => {
      if (Object.keys(state.dispensaries).length > 0) {
        this.dispensary = _.values(state.dispensaries)[0];
      }
    });
    this.timeFilter.subscribe(() => {
      this.getDashboardData();
    });
  }

  getUsersDispensaries() {
    const user = this.secureApp.getUser();
    const userBanks = user.companies.map((company: any) => {
      if (company.companyType === 'dispensary') {
        return this.dispensaryDataService.read({ id: company.id });
      }
    });
    return Promise.all(userBanks.filter(a => a !== undefined));
  }

  fetchAllSalesFiles(dispensaryId) {
    this.salesFileDataService.readAll({ id: dispensaryId }).then((salesFiles: SaleFile[]) => {
      this.salesFiles = salesFiles;
      this.salesFiles$.next(salesFiles);
    });
  }

  updateNewDeposit() {
    this.depositDataService
      .getNewDeposit({ id: this.dispensary.id })
      .then((deposit: Deposit) => {
        this.newDeposit = deposit;
        this.newDepositStream$.next(deposit);
      })
      .catch(err => this.logger.error(err));
  }

  // setUpNewDepositPolling() {
  //   this.newDepositPoller = Observable.interval(2500)
  //     .switchMap(() => {
  //       return this.depositDataService.getNewDeposit({
  //         id: this.dispensary.id
  //       });
  //     })
  //     .subscribe((deposit: Deposit) => {
  //       this.newDeposit = deposit;
  //       this.newDepositStream$.next(deposit);
  //     });
  // }

  unsubscribeToNewDepositPolling() {
    this.newDepositPoller.unsubscribe();
  }

  subscribeToNewDepositStream(observer) {
    return this.newDepositStream$.subscribe(observer);
  }

  getBankableChartData() {
    const localDateRange = this.utils.getLocalDateTimeRange(this.timeFilter.value);
    const groupBy = this.utils.getChartGrouping(this.timeFilter.value);
    const keys: any = this.utils.getGroupMap(groupBy, localDateRange.start, localDateRange.end);
    const aggregateData = {};
    // FIXME used logging service instead of console.log(this.salesAggregate, 'Apples');
    Object.values(keys).forEach((date: string) => {
      aggregateData[date] = {};
      const aggregate = this.salesAggregate;
      if (aggregate[date]) {
        aggregateData[date] = aggregate[date];
      } else {
        aggregateData[date] = {
          bankable: 0,
          unbankable: 0,
        };
      }
    });

    return aggregateData;
  }

  getDateSpan() {
    const localDateRange = this.utils.getLocalDateTimeRange(this.timeFilter.value);
    const start = DateTime.fromISO(localDateRange.start);
    const end = DateTime.fromISO(localDateRange.end);
    return start.toLocaleString() + ' - ' + end.toLocaleString();
  }

  fetchUpdatedSalesAggregate() {
    this.aggregateDataService
      .getAggregate({ companyId: this.dispensary.id, type: 'sales' })
      .then((aggregates: object) => {
        this.salesAggregate = aggregates;
        this.salesAggregate$.next(this.salesAggregate);
      })
      .catch(err => this.logger.error(err));
  }
  getVerifiedSales() {
    if (this.dashboardAggregate) {
      return this.dashboardAggregate.data[this.timeFilter.value];
    }
    return;
  }

  getDashboardData() {
    if (this.dispensary) {
      this.aggregateDataService
        .getAggregate({ companyId: this.dispensary.id, type: 'dispensary' })
        .then((data: any) => {
          this.dashboardAggregate = data;
          this.dashboardAggregateStream.next(data);
        });
    }
  }

  setDashboardTimeFilter(timePeriod) {
    const newFilter = this.utils.getNewTimeFilter(timePeriod);
    this.timeFilter.next(newFilter);
    this.getBankableChartData();
  }

  getAllDataLoaded(): boolean {
    return this.allDataLoaded;
  }

  getAllDataLoadedStream() {
    return this.allDataLoaded$;
  }

  getDispensary(): Dispensary {
    return this.dispensary;
  }

  getSalesAggregate(): object {
    return this.salesAggregate;
  }

  getDepositById(id) {
    let foundDeposit;
    this.deposits.forEach(deposit => {
      if (deposit.deposit_id === id) {
        foundDeposit = deposit;
      }
    });
    // FIXME used logging service instead of console.log(foundDeposit);
    return foundDeposit;
  }

  async getPaginatedSalesByDepositId(id: string, depositId: string, limit: string, key?: any) {
    const salesAndKey = await this.salesDataService.getPaginatedSalesByDepositId({
      id,
      depositId,
      limit,
      key,
    });
    return salesAndKey;
  }

  async getPagiantedSalesByDateRange(id: string, startDate: string, endDate: string, limit: string, key?: any) {
    const salesAndKey = await this.salesDataService.getPaginatedSalesByDateRange({
      id,
      startDate,
      endDate,
      limit,
      key,
    });
    return salesAndKey;
  }

  getSalesAggregateStream() {
    return this.salesAggregate$;
  }

  getDeposits(): Deposit[] {
    return this.deposits;
  }

  getDepositsStream() {
    return this.deposits$;
  }

  getNewDeposit(): Deposit {
    return this.newDeposit;
  }

  subscribeToDeposits(observer) {
    this.deposits$.subscribe(observer);
  }

  clearNotification() {
    this.userDataService.clearNotification();
  }

  getSales(): Sale[] {
    return this.sales;
  }

  getSalesStream() {
    return this.sales$;
  }

  subscribeToSales(observer) {
    this.sales$.subscribe(observer);
  }

  getSalesFiles(): SaleFile[] {
    return this.salesFiles;
  }

  getSalesFilesStream() {
    return this.salesFiles$;
  }

  subscribeToSalesFiles(observer) {
    this.salesFiles$.subscribe(observer);
  }

  getNotifications() {
    return this.notifications;
  }

  getDispensaryBankReviewStatus() {
    if (this.bankUtil.hasCompletedBankReview(this.dispensary)) {
      return {
        status: this.bankUtil.hasCompletedBankReview(this.dispensary),
        reviewDate: this.bankUtil.lastReviewByBank(this.dispensary),
      };
    } else {
      return { status: false, reviewDate: 'N/A' };
    }
  }

  async getSalesByDepositId(id: string, depositId) {
    const sales = await this.salesDataService.getSalesByDepositId({
      id,
      depositId,
    });
    return sales;
  }

  async getSalesByS3FileUploadDate(id: string, s3UploadDate: string) {
    const sales = await this.salesDataService.getSalesFromUploadDate({
      id,
      s3UploadDate,
    });
    return sales;
  }

  async getPaginatedSalesByS3FileUploadDate(id: string, s3UploadDate, limit, key?: any) {
    const salesAndKey = await this.salesDataService.getPaginatedSalesFromUploadDate({ id, s3UploadDate, limit, key });
    return salesAndKey;
  }

  async getSalesByDates(id: string, startDate: string, endDate: string) {
    const sales = await this.salesDataService.getSalesInDateRange({
      id,
      startDate,
      endDate,
    });
    return sales;
  }

  completeNotification(notification) {
    this.userDataService
      .completeNotification({ id: this.secureApp.getUser().id, body: notification })
      .then(() => {})
      .catch(err => this.logger.error(err));
  }
}
