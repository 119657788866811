import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { UserDataService } from './actions/user.action';
import * as _ from 'underscore';
import { updateConfig } from '../app/rollbar/rollbar.config';
import { environment } from '../environments/environment';
/*
  This service provides communication for the Secure App family of components (secure/landing -> gcv-shell, bank-shell, dispensary-shell).
  See: https://angular.io/guide/component-interaction#parent-and-children-communicate-via-a-service
*/

@Injectable({
  providedIn: 'root',
})
export class SecureAppFamilyService {
  private user: any;
  signedIn = false;
  private user$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private company: any;
  private company$: BehaviorSubject<object> = new BehaviorSubject<object>(null);

  constructor(private userDataService: UserDataService) {}

  init(userId) {
    return new Promise((resolve, reject) => {
      this.userDataService.read({ id: userId }).then((user: any) => {
        if (user) {
          updateConfig({
            userId: user.id,
            username: `${user.firstName} ${user.lastName}`,
            email: user.email,
            env: environment.env,
          });
          this.signedIn = true;
          this.user = user;
          this.user$.next(this.user);
          this.setCompany(this.user.companies[0]);
          resolve(user);
          this.userDataService.subscribe(data => {
            this.user = data.user;
          });
        }
      });
    });
  }

  setCompany(company) {
    this.company = company;
    this.company$.next(this.company);
  }

  getUser(): any {
    return this.user;
  }

  getCompany() {
    return this.company;
  }

  subscribeToUser(observer) {
    this.user$.subscribe(observer);
  }

  subscribeToCompany(observer) {
    this.company$.subscribe(observer);
  }
}
