import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserDataService } from 'src/services/actions/user.action';
import { LoggerService } from 'src/services/logger/logger.service';
import { CognitoService } from 'src/app/auth/cognito.service';

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './gcv-create-user-dialog.html',
  styleUrls: ['./gcv-create-user-dialog.scss'],
})
export class GcvCreateUserDialogComponent {
  selectedType: string;
  firstName = '';
  lastName = '';
  email = '';
  role = '';
  primaryContact = false;
  selectedOrgs: any[] = [];
  selectedOrganization: any[] = [];
  roles = ['gcv_admin', 'gcv_user', 'bank_admin', 'bank_user', 'dispensary_admin', 'dispensary_user'];
  constructor(
    public dialogRef: MatDialogRef<GcvCreateUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userDataService: UserDataService,
    private logger: LoggerService,
    private cognitoService: CognitoService
  ) {}

  close() {
    this.dialogRef.close();
  }

  saveUser() {
    const userData = {
      firstName: this.firstName,
      lastName: this.lastName,
      id: '',
      companies: this.determineUserCompanies(),
      email: this.email,
      primaryContact: this.primaryContact,
    };
    this.resetForm();
    // FIXME used logging service instead of console.log(userData);
    this.cognitoService
      .registerUser(userData.email, this.role, `${userData.firstName} ${userData.lastName}`)
      .then((res: any) => {
        userData.id = res.userSub;
        this.userDataService
          .createUser(userData)
          .then(() => {
            this.close();
          })
          .catch(err => {
            this.logger.error(err);
            this.close();
          });
      });
  }

  resetForm() {
    this.firstName = '';
    this.selectedOrganization = [];
    this.selectedOrgs = [];
    this.lastName = '';
    this.email = '';
    this.selectedType = '';
    this.primaryContact = false;
  }

  setSelectedOrganization() {
    if (this.selectedType === 'dispensary') {
      this.selectedOrganization = this.data.dispensaries;
      return;
    } else if (this.selectedType === 'bank') {
      this.selectedOrganization = this.data.banks;
    } else {
      this.selectedOrganization = [{ companyType: 'gcv', id: 1 }];
    }
    return;
  }

  roleChanged({ value }) {
    this.role = value;
  }

  selectionChanged({ value }) {
    const checkedSelectedOrgs = this.selectedOrgs.filter(org => org.id === value.id);
    if (checkedSelectedOrgs.length === 0) {
      this.selectedOrgs.push(value);
    }
    return;
  }

  formFilled() {
    return (
      this.firstName.length > 0 && this.lastName.length > 0 && this.email.length > 0 && this.selectedOrgs.length > 0
    );
  }

  removeOrg(org) {
    this.selectedOrgs = this.selectedOrgs.filter(selectedOrg => selectedOrg.id !== org.id);
  }

  determineUserCompanies() {
    return this.selectedOrgs.map(org => {
      if (org.hasOwnProperty('name')) {
        return {
          companyType: 'dispensary',
          id: org.id,
        };
      } else if (org.hasOwnProperty('orgName')) {
        return {
          companyType: 'bank',
          id: org.id,
        };
      } else {
        return {
          companyType: 'gcv',
          id: org.id,
        };
      }
    });
  }

  determineOrgName(org) {
    if (org.hasOwnProperty('name')) {
      return org.name;
    } else if (org.hasOwnProperty('orgName')) {
      return org.orgName;
    } else {
      return 'GCV';
    }
  }
}
