import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as _ from 'underscore';

// import { AppModel } from '../gcv.reducer';
import { GET_ALL_SALES, RESET_SALES } from '../action.types';
import { DefaultService } from '@gcv/services';
import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class SalesDataService {
  private salesStore;

  constructor(private sdk: DefaultService, private store: Store<any>, private logger: LoggerService) {
    this.salesStore = store.select('sales');
  }

  public subscribe(observer) {
    return this.salesStore.subscribe(observer);
  }

  public clearDomain() {
    this.salesStore.dispatch({
      type: RESET_SALES,
    });
  }

  public getSalesByDepositId(keys: { id: string; depositId: string }) {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesIdDepositsDepositIdSalesGet(keys.depositId, keys.id).subscribe(
        sales => {
          resolve(sales);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  public getPaginatedSalesByDepositId(keys: { id: string; depositId: string; limit: string; key?: any }) {
    return new Promise((resolve, reject) => {
      this.sdk
        .dispensariesIdDepositsDepositIdSalesPaginatedGet(keys.id, keys.depositId, keys.limit, keys.key)
        .subscribe(
          sales => {
            resolve(sales);
          },
          err => reject(err)
        );
    });
  }

  public getPaginatedSalesByDateRange(keys: {
    id: string;
    startDate: string;
    endDate: string;
    limit?: string;
    status?: string;
    key?: any;
  }) {
    return new Promise((resolve, reject) => {
      // FIXME used logging service instead of console.log(keys.key);
      if (keys.key) {
        this.sdk
          .dispensariesIdSalesPaginatedGet(
            keys.endDate,
            keys.startDate,
            keys.id,
            keys.limit,
            keys.key.customer_id.replace(/=/gi, '%3D'),
            keys.key.receipt_date,
            null // FIXME
          )
          .subscribe(
            sales => {
              resolve(sales);
            },
            err => reject(err)
          );
      } else {
        this.sdk
          .dispensariesIdSalesPaginatedGet(
            keys.endDate,
            keys.startDate,
            keys.id,
            keys.limit,
            null,
            null // FIXME
          )
          .subscribe(
            sales => {
              resolve(sales);
            },
            err => reject(err)
          );
      }
    });
  }

  public readAll(keys: { id: string }) {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesIdSalesGet(keys.id).subscribe(
        sales => {
          const payload = {
            dispensaryId: keys.id,
            objects: sales,
          };

          this.salesStore.dispatch({
            type: GET_ALL_SALES,
            payload,
          });
          resolve(sales);
        },
        err => reject(err)
      );
    });
  }

  getSalesInDateRange(keys: { id: string; startDate: string; endDate: string }) {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesIdSalesGet(keys.id, keys.endDate, keys.startDate).subscribe(
        sales => {
          const payload = {
            dispensaryId: keys.id,
            objects: sales,
          };

          this.salesStore.dispatch({
            type: GET_ALL_SALES,
            payload,
          });
          resolve(sales);
        },
        err => reject(err)
      );
    });
  }

  getSalesFromUploadDate(keys: { id: string; s3UploadDate: string }) {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesIdSalesfilesS3DateUploadedGet(keys.id, keys.s3UploadDate).subscribe(
        sales => {
          resolve(sales);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getPaginatedSalesFromUploadDate(keys: { id: string; s3UploadDate: string; limit: string; key: any }) {
    return new Promise((resolve, reject) => {
      this.sdk
        .dispensariesIdSalesfilesS3DateUploadedSalesPaginatedGet(keys.s3UploadDate, keys.id, keys.key, keys.limit)
        .subscribe(
          sales => {
            resolve(sales);
          },
          err => {
            reject(err);
          }
        );
    });
  }
}
