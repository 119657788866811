import { FormatCurrencyCustom } from './../pipes/formatCurrency.pipe';
import { FormatPercentCustom } from './../pipes/formatPercent.pipe';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app/app-routing.module';
import { TimeFormatterPipe } from 'src/pipes/time-formatter.pipe';

@NgModule({
  declarations: [FormatCurrencyCustom, FormatPercentCustom],
  providers: [FormatCurrencyCustom, FormatPercentCustom],
  imports: [],
  exports: [FormatCurrencyCustom],
})
export class GcvSharedModule {}
