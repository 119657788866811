import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Dispensary } from '@gcv/shared';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { S3FileManagementService } from '../../../../../services/s3FileManagement.service';

@Component({
  selector: 'app-gcv-review-account',
  templateUrl: './gcv-review-account.component.html',
  styleUrls: ['./gcv-review-account.component.scss'],
})
export class GcvReviewAccountComponent {
  @Input() account: Dispensary;
  @Output() acknowledged = new EventEmitter();
  docMap = {
    cannabisLicenseCopy: 'Cannabis License',
    balanceDoc: 'Balance Sheet',
    leaseDoc: 'Commercial Lease',
    taxDoc: 'Tax Return',
    incomeDoc: 'Income Statement',
    policyDoc: 'Policies and Procedures',
    certificateOfGoodStanding: 'Certificate of Good Standing',
    beneficialOwnerDoc: 'Beneficial Ownership',
    articlesDoc: 'Articles of Incorporation',
  };
  constructor(private ngxSmartModalService: NgxSmartModalService, private s3FileManagement: S3FileManagementService) {}

  acknowledge(e, t) {
    this.acknowledged.emit();
  }

  async reviewDocument(doc, i) {
    const modal = this.ngxSmartModalService.getModal('reviewDocument');
    const data = {};
    this.s3FileManagement.get(doc.s3Key).then(url => {
      data['s3KeyUrl'] = url;
      data['documentName'] = doc.name;
      data['status'] = 'reviewed';
      this.ngxSmartModalService.setModalData(data, 'reviewDocument');
      modal.open();
    });

    modal.onAnyCloseEventFinished.subscribe(() => {
      this.ngxSmartModalService.resetModalData('reviewDocument');
    });
  }
}
