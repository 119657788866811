import { Injectable } from '@angular/core';
import { DefaultService } from '@gcv/services';
import { Store } from '@ngrx/store';
// import { AppModel } from '../gcv.reducer';
import { RESET_IDENTITIES, GET_ALL_IDENTITIES } from '../action.types';
import { LoggerService } from '../logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class IdentitityService {
  private identityStore;

  constructor(private sdk: DefaultService, private store: Store<any>, private logger: LoggerService) {
    this.identityStore = store.select('identities');
  }

  public subscribe(observer) {
    return this.identityStore.subscribe(observer);
  }

  public clearDomain() {
    this.identityStore.dispatch({
      type: RESET_IDENTITIES,
    });
  }

  public getAllIdentities() {
    return new Promise((resolve, reject) => {
      this.sdk.identitiesGet().subscribe(
        identities => {
          this.identityStore.dispatch({
            type: GET_ALL_IDENTITIES,
            payload: identities,
          });
        },
        err => this.logger.error(err)
      );
    });
  }
}
