import { Component, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DefaultService } from '@gcv/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor() {}
}
