import { NgModule, ErrorHandler } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GcvCommonModule } from '../components/common.module';
import { Configuration, ApiModule } from '@gcv/services';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { environment } from '../environments/environment';
import { depositReducer } from '../services/reducers/deposit.reducer';
import { dispensaryReducer } from '../services/reducers/dispensary.reducer';
import { fincenReducer } from '../services/reducers/fincen.reducer';
import { salesReducer } from '../services/reducers/sales.reducer';
import { salesFileReducer } from '../services/reducers/salesFile.reducer';
import { bankReducer } from '../services/reducers/bank.reducer';
import { userReducer } from '../services/reducers/user.reducer';
import { aggregatesReducer } from '../services/reducers/aggregates.reducer';
import { identitityReducer } from '../services/reducers/identities.reducer';
import { GcvInternalModule } from './gcv-internal/gcv-internal.module';
import { Config, parseGcvConfig } from '../services/config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { RollbarService } from './rollbar/rollbar.service';
import { rollbarFactory } from './rollbar/rollbar.config';
import { CustomErrorHandler } from '../services/customErrorHandler.service';
import { httpClientFactory } from '../services/customHttpClient/httpClientFactory';
import { LoggerService } from '../services/logger/logger.service';
import { NewPasswordComponent } from './cognito/new-password/new.password.component';

const metaReducer = {
  deposits: depositReducer,
  dispensaries: dispensaryReducer,
  fincen: fincenReducer,
  sales: salesReducer,
  salesFiles: salesFileReducer,
  banks: bankReducer,
  user: userReducer,
  aggregates: aggregatesReducer,
  identities: identitityReducer,
};
@NgModule({
  declarations: [AppComponent, NewPasswordComponent],
  imports: [
    AmplifyAngularModule,
    ApiModule.forRoot(getApiConfig),
    BrowserAnimationsModule,
    AppRoutingModule,
    GcvCommonModule,
    BrowserModule,
    GcvInternalModule,
    StoreModule.forRoot(metaReducer),
    StoreDevtoolsModule.instrument({ maxAge: 5 }), // TODO redux/aot instrumentation issue: https://github.com/ngrx/store/issues/316
  ],
  providers: [
    AmplifyService,
    { provide: Config, useValue: parseGcvConfig(environment) },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: HttpClient, useFactory: httpClientFactory, deps: [LoggerService, HttpHandler] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

const apiConfig = new Configuration({
  withCredentials: false,
  apiKeys: {}, // TODO look into setting all headers here instead of
  ...(environment.basePath
    ? {
        basePath: environment.basePath,
      }
    : {}),
});

export function getApiConfig() {
  return apiConfig;
}

export { metaReducer, apiConfig };
