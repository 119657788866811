import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NavigationButtonComponent } from './navigation-button/navigation-button.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, InlineSVGModule.forRoot()],
  declarations: [NavigationButtonComponent],
  exports: [NavigationButtonComponent],
})
export class GcvInlineSVGModule {}
