import { FormatCurrencyCustom } from './../../pipes/formatCurrency.pipe';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { countries, states, primaryFederalRegulators, entitytypes } from '../../app/constants';
import * as _ from 'underscore';

@Component({
  selector: 'app-editable-card',
  templateUrl: './editable-card.component.html',
  styleUrls: ['./editable-card.component.scss'],
})
export class EditableCardComponent implements OnInit {
  editable = false;
  @Output() saveContent = new EventEmitter();
  @Input() customStyle: any;
  @Input() data: FormGroup;
  @Input() title: string;
  @Input() formKey: any;
  @Input() customStyleEdit: any;
  @Input() addressContent = true;
  states = states;
  countries = countries;
  primaryReg = primaryFederalRegulators;
  entityType = entitytypes;
  entityNameMap = {
    sole: 'Sole Proprietorship',
    partnership: 'General Partnership',
    corporation: 'Corporation',
    llc: 'Limited Liability Company',
  };
  @Output() blurCalled = new EventEmitter();
  @Output() focusCalled = new EventEmitter();

  tempData: any;

  constructor(private currencyPipe: FormatCurrencyCustom) {}

  ngOnInit() {
    this.resetFormGroup();
  }

  edit() {
    this.editable = true;
  }

  cancel() {
    this.editable = false;
    this.resetFormGroup();
  }

  resetFormGroup() {
    const data = this.data.getRawValue();
    const controls = {};
    for (const key in data) {
      if (key) {
        if (this.data.controls[key].validator) {
          controls[key] = new FormControl(data[key], Validators.required);
        } else {
          controls[key] = new FormControl(data[key]);
        }
      }
    }
    this.tempData = new FormGroup(controls);
  }

  save() {
    for (const key in this.tempData) {
      if (key) {
        this.data = this.tempData;
      }
    }
    this.saveContent.emit(this.data.getRawValue());
    this.editable = false;
  }

  handleInput(e) {
    this.tempData.controls[e.property].setValue(e.value);
  }

  selectionChanged(e, property) {
    this.tempData[property] = e.value[0];
  }

  onFocusCalled(key) {
    if (key.prefixValue && key.prefixValue === '$') {
      this.unformatCurrency(key);
    }
  }

  onBlurCalled(key) {
    if (key.prefixValue && key.prefixValue === '$') {
      this.formatCurrency(key);
    }
  }

  formatCurrency(key) {
    const defaultValue = this.tempData.get(key.value).value;
    this.tempData.get('monthlySales').setValue(this.currencyPipe.transform(defaultValue, 2, false));
  }

  unformatCurrency(key) {
    const defaultValue = this.tempData.get(key.value).value;
    this.tempData.get(key.value).setValue(this.currencyPipe.parse(defaultValue));
  }
}
