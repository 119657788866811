import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as _ from 'underscore';

// import { AppModel } from '../gcv.reducer';

import { RESET_BANK, GET_BANK, PUT_BANK, GET_ALL_BANKS, POST_BANK } from '../action.types';
import { DefaultService } from '@gcv/services';
import { LoggerService } from '../logger/logger.service';
// import { SessionService } from '../session.service';

@Injectable({
  providedIn: 'root',
})
export class BankDataService {
  private bankStore;

  constructor(
    private store: Store<any>,
    // private session: SessionService,
    private sdk: DefaultService,
    private logger: LoggerService
  ) {
    this.bankStore = store.select('banks');
  }

  public subscribe(observer) {
    return this.bankStore.subscribe(observer);
  }

  public clearDomain() {
    // this.bankStore.dispatch({
    //   type: RESET_BANK
    // });
  }

  public readAll() {
    return new Promise((resolve, reject) => {
      this.sdk.banksGet().subscribe(
        banks => {
          const payload = {
            objects: banks,
          };
          this.bankStore.dispatch({
            type: GET_ALL_BANKS,
            payload,
          });
          resolve(banks);
        },
        err => {
          this.logger.error(err);
          reject(err);
        }
      );
    });
  }

  public read(keys: { id: string }) {
    return new Promise((resolve, reject) => {
      this.sdk.banksIdGet(keys.id).subscribe(
        bank => {
          const payload = {
            objects: bank,
          };

          this.bankStore.dispatch({
            type: GET_BANK,
            payload,
          });
          resolve(bank);
        },
        err => reject(err)
      );
    });
  }

  public post(body) {
    return new Promise((resolve, reject) => {
      this.sdk.banksPost(body).subscribe(bank => {
        const payload = {
          objects: bank,
        };

        this.bankStore.dispatch({
          type: POST_BANK,
          payload,
        });
        resolve(bank);
      });
    });
  }

  public update(
    keys: {
      id: string;
    },
    data: object
  ) {
    return new Promise((resolve, reject) => {
      this.sdk.banksIdPut(keys.id, data).subscribe(
        updatedBank => {
          const payload = {
            objects: updatedBank,
          };
          // this.session.setSessionProperty('bank', JSON.stringify(updatedBank));
          this.bankStore.dispatch({
            type: PUT_BANK,
            payload,
          });
          resolve(updatedBank);
        },
        err => reject(err)
      );
    });
  }
}
