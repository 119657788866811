import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import * as _ from 'underscore';
import { MatDialog } from '@angular/material';
import { GcvCreateUserDialogComponent } from '../gcv-create-user-dialog/gcv-create-user-dialog';
import { GcvUsersEditDialogComponent } from './gcv-users-edit-dialog/gcv-users-edit.component';
import { LoggerService } from 'src/services/logger/logger.service';
import { UserDataService } from 'src/services/actions/user.action';
import { IdentitityService } from 'src/services/actions/identities.action';
import { GcvAppFamilyService } from 'src/services/gcvApp.service';
import { Route, Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './gcv-users.component.html',
  styleUrls: ['./gcv-users.component.scss'],
})
export class GcvUsersComponent implements OnInit {
  public users = [];
  public filteredUsers = [];
  filterText = '';
  public identities$: Subject<object[]> = new BehaviorSubject<object[]>([]);
  public identities = [];
  public displayedColumns: string[] = ['lastName', 'firstName', 'invitation_status', 'organization', 'email'];
  public dataMap = {
    lastName: 'Last Name',
    firstName: 'First Name',
    invitation_status: 'Team Invitation',
    email: 'Email',
    organization: 'Organization',
  };
  public organizationMap = {
    bank: {},
    dispensary: {},
  };
  constructor(
    private gcvapp: GcvAppFamilyService,
    private logger: LoggerService,
    private userDataService: UserDataService,
    private matDialog: MatDialog,
    private identitityService: IdentitityService,
    private route: Router
  ) {}

  ngOnInit() {
    Object.values(this.gcvapp.getDispensaries()).forEach(disp => {
      this.organizationMap['dispensary'][disp.id] = disp.name;
    });

    Object.values(this.gcvapp.getBanks()).forEach(bank => {
      this.organizationMap['bank'][bank.id] = bank.orgName;
    });

    this.subscribeToAppState();
    this.userDataService
      .readAll()
      .then((data: any[]) => {
        this.users = data;
        this.filteredUsers = this.users;
      })
      .catch(err => this.logger.error(err));

    this.identitityService
      .getAllIdentities()
      .then(identities => {})
      .catch(err => this.logger.error(err));
  }

  subscribeToAppState() {
    this.userDataService.subscribe(state => {
      this.users = state.allUsers[0];
    });

    this.identitityService.subscribe(state => {
      this.identities = state.identities;
    });
  }

  cancel() {
    this.logger.debug('cancel');
  }

  determineStatus(user) {
    const correctIdentity = this.identities.filter(identity => user.id === identity.Attributes[0].Value);
    if (correctIdentity.length > 0) {
      return correctIdentity[0].UserStatus;
    }
    return 'UNCONFIRMED';
  }

  createUser() {
    this.route.navigate(['/secure/gcv/create/user']);
  }

  openEditDetails(user) {
    const correctIdentity = this.identities.filter(identity => user.id === identity.Attributes[0].Value);
    // this.matDialog.open(GcvUsersEditDialogComponent, {
    //   data: {
    //     dispensaries: this.gcvapp.getDispensaries(),
    //     banks: this.gcvapp.getBanks(),
    //     user,
    //     ,
    //   },
    // });
    const data: NavigationExtras = {
      queryParams: {
        user: JSON.stringify(user),
        confirmed: correctIdentity[0].UserStatus === 'CONFIRMED' ? 'Confirmed' : 'Unconfirmed',
      },
    };
    this.route.navigate(['/secure/gcv/view/user'], data);
  }

  filterItems() {
    // FIXME used logging service instead of console.log(this.filterText);
    if (this.filterText.length === 0) {
      this.filteredUsers = this.users;
    }
    this.filteredUsers = this.users.filter(user => {
      const filterLower = this.filterText.toLowerCase();
      if (user.firstName.toLowerCase().includes(filterLower) || user.lastName.toLowerCase().includes(filterLower)) {
        return user;
      }
    });
  }
}
