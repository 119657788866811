import { Injectable, ErrorHandler } from '@angular/core';

import { Store } from '@ngrx/store';

import * as _ from 'underscore';

// import { AppModel } from '../gcv.reducer';
import {
  REMOVE_DISPENSARIES,
  GET_DISPENSARY,
  PUT_DISPENSARY,
  GET_ALL_DISPENSARIES,
  POST_DISPENSARY,
  GET_NEW_DEPOSIT,
} from '../action.types';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class DispensaryDataService {
  private dispensaryStore;

  constructor(private store: Store<any>, private sdk: DefaultService, private errorHandler: ErrorHandler) {
    this.dispensaryStore = store.select('dispensaries');
  }

  public subscribe(observer) {
    return this.dispensaryStore.subscribe(observer);
  }

  public clearDomain() {
    this.dispensaryStore.dispatch({
      type: REMOVE_DISPENSARIES,
    });
  }

  public updateGcvDiligence(keys: { id: string }, data: object) {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesIdDiligenceGcvPost(keys.id, data).subscribe(
        dispensary => {
          const payload = {
            dispensaryId: dispensary.id,
            objects: dispensary,
          };

          this.dispensaryStore.dispatch({
            type: PUT_DISPENSARY,
            payload,
          });

          resolve(dispensary);
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }

  public post(body) {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesPost(body).subscribe(
        dispensary => {
          const payload = {
            dispensaryId: dispensary.id,
            objects: dispensary,
          };
          this.dispensaryStore.dispatch({
            type: POST_DISPENSARY,
            payload,
          });

          resolve(dispensary);
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }

  public updateBankDiligence(keys: { id: string; bankId: string }, data: object) {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesIdDiligenceBankBankIdPost(keys.bankId, keys.id, data).subscribe(
        dispensary => {
          const payload = {
            dispensaryId: dispensary.id,
            objects: dispensary,
          };

          this.dispensaryStore.dispatch({
            type: PUT_DISPENSARY,
            payload,
          });

          resolve(dispensary);
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }

  public read(keys: { id: string }) {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesIdGet(keys.id).subscribe(
        dispensary => {
          const payload = {
            dispensaryId: dispensary.id,
            objects: dispensary,
          };

          this.dispensaryStore.dispatch({
            type: GET_DISPENSARY,
            payload,
          });

          resolve(dispensary);
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }

  public readAll() {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesGet().subscribe(
        dispensaries => {
          const payload = {
            objects: dispensaries,
          };
          this.dispensaryStore.dispatch({
            type: GET_ALL_DISPENSARIES,
            payload,
          });
          resolve(dispensaries);
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }

  public update(keys: { id: string }, body: object) {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesIdPut(keys.id, body).subscribe(
        dispensary => {
          const payload = {
            dispensaryId: dispensary.id,
            objects: dispensary,
          };
          this.dispensaryStore.dispatch({
            type: PUT_DISPENSARY,
            payload,
          });
          resolve(dispensary);
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }
}
