import { GcvSharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { GcvInternalCommonModule } from './internal.module';
import { GcvMaterialCommonModule } from './material.module';
import { GcvInlineSVGModule } from './inline-svg.module';
import { GcvVendorModule } from '../shared/vendor.module';
@NgModule({
  imports: [GcvInternalCommonModule, GcvSharedModule, GcvInlineSVGModule, GcvVendorModule],
  exports: [GcvInternalCommonModule, GcvSharedModule, GcvInlineSVGModule, GcvVendorModule],
})
export class GcvCommonModule {}
