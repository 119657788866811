import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() type: string;
  @Input() label: string;
  @Input() styles: string;
  @Input() property: string;
  @Input() disabled = false;
  @Input() required = false;
  @Input() inputValue = '';
  @Input() autoFill = 'notAAutoFill';
  @Output() value = new EventEmitter();
  @Output() blurCalled = new EventEmitter();
  @Output() focusCalled = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onInputChange(e) {
    this.value.emit({ value: e.srcElement.value, property: this.property });
  }

  onBlur() {
    this.blurCalled.emit();
  }

  onFocus() {
    this.focusCalled.emit();
  }
}
