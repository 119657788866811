import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss'],
})
export class NavigationButtonComponent implements OnInit {
  @Input() logo: string;
  @Input() navBtnText: string;
  @Input() isActive = false;
  logoPathInactive: string;
  logoPathActive: string;
  logoPath: string;
  constructor() {}

  ngOnInit() {
    // this.logoPathInactive = `../../assets/Icon-${this.logo}-Grey.svg`;
    // this.logoPathInactive = `../../assets/Icon-${this.logo}-Grey.svg`;
    this.logoPath = `../../assets/Icon-${this.logo}.svg`;
  }
}
