import { Injectable } from '@angular/core';

import * as _ from 'underscore';

import { Dispensary } from '@gcv/services';

// TODO these methods all work on dispensaries!  This class should be called DispensaryUtilService.

@Injectable({
  providedIn: 'root',
})
export class BankUtilService {
  constructor() {}

  requiresBankReview(dispensary): boolean {
    const reviewRequired = this.readyForBankReview(dispensary) && !this.hasCompletedBankReview(dispensary);
    // FIXME used logging service instead of console.log(reviewRequired, dispensary.name);
    return reviewRequired;
  }

  readyForReview(dispensary) {
    // const ready =
    //   _.has(dispensary, 'id') &&
    //   _.has(dispensary, 'name') &&
    //   _.has(dispensary, 'streetAddress') &&
    //   _.has(dispensary, 'city') &&
    //   _.has(dispensary, 'state') &&
    //   _.has(dispensary, 'postalCode') &&
    //   _.has(dispensary, 'cannabisLicenseNumber') &&
    //   _.has(dispensary, 'cannabisLicenseType') &&
    //   _.has(dispensary, 'articlesDoc') &&
    //   _.has(dispensary, 'incomeDoc') &&
    //   _.has(dispensary, 'balanceDoc') &&
    //   _.has(dispensary, 'leaseDoc') &&
    //   _.has(dispensary, 'policyDoc') &&
    //   _.has(dispensary, 'beneficialOwnerDoc') &&
    //   _.has(dispensary, 'taxDoc') &&
    //   _.has(dispensary, 'establishedDate') &&
    //   this.hasCannabisLicenseCopy(dispensary) &&
    //   this.hasCertificateOfGoodStanding(dispensary) &&
    //   this.hasArticlesDoc(dispensary) &&
    //   this.hasIncomeDoc(dispensary) &&
    //   this.hasBalanceDoc(dispensary) &&
    //   this.hasLeaseDoc(dispensary) &&
    //   this.hasPolicyDoc(dispensary) &&
    //   this.hasBeneficialOwnerDoc(dispensary) &&
    //   this.hasTaxDoc(dispensary);
    // return ready;
    return true;
  }
  hasCompletedGcvReview(dispensary) {
    const gcvHasReviewed =
      this.readyForReview(dispensary) &&
      _.has(dispensary, 'dueDiligence') &&
      _.has(dispensary.dueDiligence, 'gcvReview') &&
      dispensary.dueDiligence.gcvReview.length > 0 &&
      dispensary.dueDiligence.gcvReview[0].status === 'reviewed';
    return gcvHasReviewed;
  }

  readyForBankReview(dispensary): boolean {
    const ready = dispensary.documents.length === 9 && this.hasCompletedGcvReview(dispensary);
    return ready;
  }

  // bank review is complete when all documents have been reviewed
  hasCompletedBankReview(dispensary) {
    const bankReviewCompleted =
      _.has(dispensary, 'dueDiligence') &&
      _.has(dispensary.dueDiligence, 'bankReview') &&
      dispensary.dueDiligence.bankReview.length === 9 &&
      _.has(dispensary.dueDiligence.bankReview[0], 'status') &&
      _.has(dispensary.dueDiligence.bankReview[1], 'status') &&
      _.has(dispensary.dueDiligence.bankReview[2], 'status') &&
      _.has(dispensary.dueDiligence.bankReview[3], 'status') &&
      _.has(dispensary.dueDiligence.bankReview[4], 'status') &&
      _.has(dispensary.dueDiligence.bankReview[5], 'status') &&
      _.has(dispensary.dueDiligence.bankReview[6], 'status') &&
      _.has(dispensary.dueDiligence.bankReview[7], 'status') &&
      _.has(dispensary.dueDiligence.bankReview[8], 'status') &&
      (dispensary.dueDiligence.bankReview[0].status === 'reviewed' &&
        dispensary.dueDiligence.bankReview[1].status === 'reviewed' &&
        dispensary.dueDiligence.bankReview[2].status === 'reviewed' &&
        dispensary.dueDiligence.bankReview[3].status === 'reviewed' &&
        dispensary.dueDiligence.bankReview[4].status === 'reviewed' &&
        dispensary.dueDiligence.bankReview[5].status === 'reviewed' &&
        dispensary.dueDiligence.bankReview[6].status === 'reviewed' &&
        dispensary.dueDiligence.bankReview[7].status === 'reviewed' &&
        dispensary.dueDiligence.bankReview[8].status === 'reviewed');
    return bankReviewCompleted;
  }

  getBankReview(dispensary: any, documentName): any {
    if (this.hasReviews(dispensary, 'bankReview')) {
      const documentReview = _.find(dispensary.dueDiligence.bankReview as any[], review => {
        return review.documentName === documentName;
      });
      return documentReview ? documentReview : null;
    } else {
      return null;
    }
  }
  lastReviewByBank(dispensary): any {
    if (!this.hasCompletedBankReview(dispensary)) {
      return null;
    }
    const sortedReviews = _.sortBy(dispensary.dueDiligence.bankReview as any[], review => {
      return review.timestamp;
    });
    const lastReview = _.last(sortedReviews);
    return lastReview;
  }

  // reviewType = 'bankReview' | 'gcvReview'
  hasReviews(dispensary, reviewType) {
    const hasReviews =
      _.has(dispensary, 'dueDiligence') &&
      _.has(dispensary.dueDiligence, reviewType) &&
      dispensary.dueDiligence[reviewType].length > 0;
    return hasReviews;
  }

  lastReviewByGCV(dispensary: any) {
    if (!this.hasCompletedGcvReview(dispensary)) {
      return null;
    }
    const sortedReviews = _.sortBy(dispensary.dueDiligence.gcvReview, (review: any) => {
      return review.timestamp;
    });
    const lastReview = _.last(sortedReviews);
    return lastReview;
  }

  numDocuments(dispensary) {
    let num = 0;
    if (this.hasCannabisLicenseCopy(dispensary)) {
      num++;
    }
    if (this.hasCertificateOfGoodStanding(dispensary)) {
      num++;
    }
    if (this.hasArticlesDoc(dispensary)) {
      num++;
    }
    if (this.hasIncomeDoc(dispensary)) {
      num++;
    }
    if (this.hasBalanceDoc(dispensary)) {
      num++;
    }
    if (this.hasLeaseDoc(dispensary)) {
      num++;
    }
    if (this.hasPolicyDoc(dispensary)) {
      num++;
    }
    if (this.hasBeneficialOwnerDoc(dispensary)) {
      num++;
    }
    if (this.hasTaxDoc(dispensary)) {
      num++;
    }
    return num;
  }

  hasCannabisLicenseCopy(dispensary) {
    return _.has(dispensary, 'cannabisLicenseCopy') && _.has(dispensary.cannabisLicenseCopy, 's3Key');
  }

  hasCertificateOfGoodStanding(dispensary) {
    return _.has(dispensary, 'certificateOfGoodStanding') && _.has(dispensary.certificateOfGoodStanding, 's3Key');
  }

  hasArticlesDoc(dispensary) {
    return _.has(dispensary, 'articlesDoc') && _.has(dispensary.articlesDoc, 's3Key');
  }

  hasIncomeDoc(dispensary) {
    return _.has(dispensary, 'incomeDoc') && _.has(dispensary.incomeDoc, 's3Key');
  }

  hasBalanceDoc(dispensary) {
    return _.has(dispensary, 'balanceDoc') && _.has(dispensary.balanceDoc, 's3Key');
  }

  hasLeaseDoc(dispensary) {
    return _.has(dispensary, 'leaseDoc') && _.has(dispensary.leaseDoc, 's3Key');
  }

  hasPolicyDoc(dispensary) {
    return _.has(dispensary, 'policyDoc') && _.has(dispensary.policyDoc, 's3Key');
  }

  hasBeneficialOwnerDoc(dispensary) {
    return _.has(dispensary, 'beneficialOwnerDoc') && _.has(dispensary.beneficialOwnerDoc, 's3Key');
  }

  hasTaxDoc(dispensary) {
    return _.has(dispensary, 'taxDoc') && _.has(dispensary.taxDoc, 's3Key');
  }
}
