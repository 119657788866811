import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Dispensary } from '@gcv/shared';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { S3FileManagementService } from '../../../../services/s3FileManagement.service';
import { ConfirmDialogComponent } from '../../common/confirmDialog.component';
import { MatDialog } from '@angular/material';
import { LoggerService } from '../../../../services/logger/logger.service';
import { DispensaryDataService } from '../../../../services/actions/dispensary.action';
import { UserDataService } from '../../../../services/actions/user.action';
import { GcvAppFamilyService } from '../../../../services/gcvApp.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-gcv-due-diligence',
  styleUrls: ['./gcv-due-diligence.component.scss'],
  templateUrl: './gcv-due-diligence.component.html',
})
export class GcvDueDiligenceComponent {
  complete = {
    dueDiligence: false,
    identity: false,
  };
  selectedTab = 0;

  approved = false;
  account: Dispensary;
  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private logger: LoggerService,
    private dispensaryApp: DispensaryDataService,
    private gcvApp: GcvAppFamilyService,
    private location: Location
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['data']) {
        this.account = JSON.parse(params['data']);
        // FIXME used logging service instead of console.log(this.account);
      }
    });
  }

  approve() {
    this.approved = true;
  }

  cancel() {
    this.location.back();
  }

  awknowledge(e, i) {
    this.complete[e] = true;
    this.selectedTab = i;
  }

  manuallyVerify() {
    const verificationDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to override the verification of this user?',
      },
    });
    verificationDialog.afterClosed().subscribe(data => {
      if (data && data.confirmed) {
        this.logger.log(data);
        this.account.primaryContact['verified'] = true;
        this.dispensaryApp
          .update({ id: this.account.id }, this.account)
          .then((disp: Dispensary) => {
            this.account = disp;
            this.logger.log(disp);
            this.complete.identity = true;
            this.selectedTab = 2;
          })
          .catch(err => this.logger.error(err));
      }
    });
  }

  approveDispensary() {
    this.gcvApp.gcvApproveReview(this.account, (err, value) => {
      this.location.back();
    });
  }
}
