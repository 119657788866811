import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SecureAppFamilyService } from '../../../../services/secure.service';
import { UserDataService } from '../../../../services/actions/user.action';
import { User } from '@gcv/schemas';
import { LoggerService } from '../../../../services/logger/logger.service';
import { UtilsService } from '../../../../services/utils.service';

@Component({
  selector: 'app-bank-personal',
  templateUrl: './bank-personal.component.html',
  styleUrls: ['./bank-personal.component.scss'],
})
export class BankPersonalComponent implements OnInit {
  userProfileData: FormGroup;
  user: User;
  constructor(
    private secureApp: SecureAppFamilyService,
    private userApp: UserDataService,
    private logger: LoggerService,
    private utils: UtilsService
  ) {}

  ngOnInit() {
    this.user = this.secureApp.getUser();
    this.userProfileData = new FormGroup({
      firstName: new FormControl(this.user.firstName ? this.user.firstName : ''),
      minitial: new FormControl(this.user.minitial ? this.user.minitial : ''),
      lastName: new FormControl(this.user.lastName ? this.user.lastName : ''),
      email: new FormControl(this.user.email ? this.user.email : ''),
      phone: new FormControl(this.user.phone ? this.user.phone : ''),
      title: new FormControl('Administrator'),
    });
  }

  save(e) {
    this.userApp
      .update({ id: this.user.id }, { ...this.user, ...this.utils.filterOutEmptyProperties(e) })
      .then(() => {})
      .catch(err => this.logger.error(err));
  }
}
