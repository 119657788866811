import { Component, OnInit, Input } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() public buttonText: string;
  @Input() bodyContent: string;
  @Input() heading: string;
  @Input() imageUrl: string; // FIXME

  constructor(public ngxSmartModalService: NgxSmartModalService) {}

  ngOnInit() {}
  onClose() {
    this.ngxSmartModalService.close('myModal');
  }
  // this.ngxSmartModalService.setModalData({modalData: data}, 'myModal');
}
