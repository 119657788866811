import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GcvShellComponent } from './gcv-shell/gcv-shell.component';
import { GcvDashboardComponent } from './gcv-dashboard/gcv-dashboard.component';
import { GcvDispensaryReviewComponent } from './gcv-admin/gcv-dispensary-review/gcv-dispensary-review.component';
import { GcvUsersComponent } from './gcv-users/gcv-users.component';
import { GcvOrganizationsComponent } from './gcv-organizations/gcv-organizations.component';
import { GcvHelpComponent } from './gcv-help/gcv-help.component';
import { GcvCreateUserComponent } from './gcv-users/gcv-create-user/gcv-create-user.component';
import { GcvCreateOrgComponent } from './gcv-organizations/gcv-create-organization.component/gcv-create-organization.component';
import { GcvViewBankComponent } from './gcv-organizations/gcv-view-bank/gcv-view-bank.component';
import { GcvDueDiligenceComponent } from './gcv-organizations/gcv-due-diligence/gcv-due-diligence.component';
import { GcvEditUserComponent } from './gcv-users/gcv-edit-user/gcv-edit-user.component';

const routes: Routes = [
  {
    path: '',
    component: GcvShellComponent,
    children: [
      {
        path: 'dashboard',
        component: GcvDashboardComponent,
      },
      {
        path: 'admin',
        component: GcvDispensaryReviewComponent,
      },
      {
        path: 'users',
        component: GcvUsersComponent,
      },
      {
        path: 'view',
        children: [
          {
            path: 'bank',
            component: GcvViewBankComponent,
          },
          {
            path: 'dispensary',
            component: GcvDueDiligenceComponent,
          },
          {
            path: 'user',
            component: GcvEditUserComponent,
          },
        ],
      },
      {
        path: 'create',
        children: [
          {
            path: 'user',
            component: GcvCreateUserComponent,
          },
          {
            path: 'organization',
            component: GcvCreateOrgComponent,
          },
        ],
      },
      {
        path: 'organizations',
        component: GcvOrganizationsComponent,
      },
      {
        path: 'help',
        component: GcvHelpComponent,
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GcvInternalRoutingModule {}
