import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { states, entitytypes, primaryFederalRegulators } from '../../../constants';
import { UtilsService } from '../../../../services/utils.service';
import { CustomValidators } from 'ngx-custom-validators';
import { DispensaryDataService } from '../../../../services/actions/dispensary.action';
import { MatSnackBar } from '@angular/material';
import { LoggerService } from '../../../../services/logger/logger.service';
import { GcvAppFamilyService } from '../../../../services/gcvApp.service';
import { Dispensary } from '@gcv/shared';
import { BankDataService } from '../../../../services/actions/bank.action';

@Component({
  selector: 'app-gcv-create-org',
  templateUrl: './gcv-create-organization.component.html',
  styleUrls: ['./gcv-create-organization.component.scss'],
})
export class GcvCreateOrgComponent {
  type = '';
  step = 0;
  states = states;
  entityTypes = entitytypes;
  primaryRegs = primaryFederalRegulators;
  dispensaries = [];
  bankCompanies: FormGroup;
  bankData: FormGroup;
  dispData: FormGroup;
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private utils: UtilsService,
    private dispService: DispensaryDataService,
    private snackbar: MatSnackBar,
    private logger: LoggerService,
    private gcvServ: GcvAppFamilyService,
    private bankService: BankDataService
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['type']) {
        this.type = params['type'];
        if (this.type === 'bank') {
          this.bankData = new FormGroup({
            orgName: new FormControl('', Validators.required),
            address: new FormControl('', Validators.required),
            city: new FormControl('', Validators.required),
            state: new FormControl('', Validators.required),
            country: new FormControl('United States'),
            postalCode: new FormControl('', Validators.required),
            primaryReg: new FormControl('', Validators.required),
            phoneNumber: new FormControl('', [Validators.required]),
            certificateOrCharterNumber: new FormControl('', Validators.required),
          });
          this.dispensaries = this.gcvServ.getDispensaries().map((disp: Dispensary) => {
            const data = {
              value: disp.id,
              viewValue: disp.name,
            };
            return data;
          });
          this.bankCompanies = new FormGroup({
            dispensaries: new FormControl([], Validators.required),
          });
        } else {
          this.dispData = new FormGroup({
            name: new FormControl('', Validators.required),
            streetAddress: new FormControl('', Validators.required),
            city: new FormControl('', Validators.required),
            state: new FormControl('', Validators.required),
            country: new FormControl('United States'),
            postalCode: new FormControl('', Validators.required),
            establishedDate: new FormControl('', Validators.required),
            entityType: new FormControl('', Validators.required),
            phone: new FormControl('', Validators.required),
            posName: new FormControl(''),
            website: new FormControl(''),
          });
        }
      }
    });
  }

  handleButtonClicked() {
    this.step++;
  }

  cancel() {
    this.location.back();
  }

  createDispensary() {
    const data = this.utils.filterOutEmptyProperties(this.dispData.getRawValue());
    this.dispService
      .post(data)
      .then(() => {
        this.snackbar.open('You have created a new Dispensary', '', {
          duration: 3000,
        });
        this.location.back();
      })
      .catch(err => this.logger.error(err));
  }

  createBank() {
    const data = {
      ...this.utils.filterOutEmptyProperties(this.bankData.getRawValue()),
      ...this.utils.filterOutEmptyProperties(this.bankCompanies.getRawValue()),
    };
    this.bankService
      .post(data)
      .then(newBank => {
        this.snackbar.open('You have created a new Bank', '', {
          duration: 3000,
        });
        this.location.back();
      })
      .catch(err => this.logger.error(err));
  }
}
