import { NgModule } from '@angular/core';

import { GcvUsersComponent } from './gcv-users/gcv-users.component';
import { GcvShellComponent } from './gcv-shell/gcv-shell.component';
import { GcvDispensaryReviewComponent } from './gcv-admin/gcv-dispensary-review/gcv-dispensary-review.component';
import { GcvDashboardComponent } from './gcv-dashboard/gcv-dashboard.component';
import { GcvHelpComponent } from './gcv-help/gcv-help.component';
import { GcvCreateUserDialogComponent } from './gcv-create-user-dialog/gcv-create-user-dialog';
import { GcvOrganizationsComponent } from './gcv-organizations/gcv-organizations.component';
import { GcvCreateOrganizationDialogComponent } from './gcv-create-organization-dialog/gcv-create-organization-dialog';
import { GcvUsersEditDialogComponent } from './gcv-users/gcv-users-edit-dialog/gcv-users-edit.component';
import { GcvOrganizationsEditBankComponent } from './gcv-organizations/gcv-organizations-edit-bank/gcv-organizations-edit-bank.component';
import { GcvCommonModule } from 'src/components/common.module';
import { GcvSharedModule } from 'src/shared/shared.module';
// tslint:disable-next-line:max-line-length
import { GcvOrganizationsEditDispensaryComponent } from './gcv-organizations/gcv-organizations-edit-dispensary/gcv-organizations-edit-dispensary.component';
import { PdfViewDialogComponent } from './common/pdfViewDialog.component';
import { ConfirmDialogComponent } from './common/confirmDialog.component';
import { GcvInternalRoutingModule } from './gcv-internal-routing.module';
import { GcvCreateUserComponent } from './gcv-users/gcv-create-user/gcv-create-user.component';
import { GcvCreateOrgComponent } from './gcv-organizations/gcv-create-organization.component/gcv-create-organization.component';
import { GcvViewBankComponent } from './gcv-organizations/gcv-view-bank/gcv-view-bank.component';
import { GcvDueDiligenceComponent } from './gcv-organizations/gcv-due-diligence/gcv-due-diligence.component';
import { ReviewAccountComponent } from '../bank/accounts/review-account/review-account.component';
import { GcvReviewAccountComponent } from './gcv-organizations/gcv-due-diligence/gcv-review-account/gcv-review-account.component';
import { GcvReviewAccountDocumentComponent } from './review-account-document/review-account-document.component';
import { GcvEditUserComponent } from './gcv-users/gcv-edit-user/gcv-edit-user.component';

@NgModule({
  imports: [GcvCommonModule, GcvInternalRoutingModule],
  declarations: [
    GcvDashboardComponent,
    GcvOrganizationsComponent,
    GcvHelpComponent,
    GcvCreateUserDialogComponent,
    GcvOrganizationsEditDispensaryComponent,
    GcvUsersEditDialogComponent,
    GcvCreateOrganizationDialogComponent,
    GcvOrganizationsEditBankComponent,
    GcvShellComponent,
    GcvUsersComponent,
    PdfViewDialogComponent,
    GcvCreateOrgComponent,
    ConfirmDialogComponent,
    GcvReviewAccountDocumentComponent,
    GcvDueDiligenceComponent,
    GcvCreateUserComponent,
    GcvReviewAccountComponent,
    GcvViewBankComponent,
    GcvDispensaryReviewComponent,
    GcvEditUserComponent,
  ],
  exports: [
    GcvViewBankComponent,
    GcvDueDiligenceComponent,
    GcvDashboardComponent,
    GcvReviewAccountDocumentComponent,
    GcvCreateUserComponent,
    GcvCreateOrgComponent,
    GcvHelpComponent,
    GcvCreateUserDialogComponent,
    GcvCreateOrganizationDialogComponent,
    GcvOrganizationsEditDispensaryComponent,
    GcvOrganizationsEditBankComponent,
    GcvUsersEditDialogComponent,
    GcvOrganizationsComponent,
    GcvReviewAccountComponent,
    GcvShellComponent,
    GcvUsersComponent,
    GcvDispensaryReviewComponent,
  ],
  entryComponents: [
    GcvReviewAccountDocumentComponent,
    GcvCreateUserDialogComponent,
    ConfirmDialogComponent,
    PdfViewDialogComponent,
    GcvUsersEditDialogComponent,
    GcvOrganizationsEditDispensaryComponent,
    GcvCreateOrganizationDialogComponent,
    GcvOrganizationsEditBankComponent,
  ],
})
export class GcvInternalModule {}
