import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Dispensary } from '@gcv/schemas';
import { DispensaryAppFamilyService } from '../../services/dispensary.service';

@Component({
  selector: 'app-initial-dashboard',
  templateUrl: './initial-dashboard.component.html',
  styleUrls: ['./initial-dashboard.component.scss'],
})
export class InitialDashboardComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() buttonText: string;
  @Output() buttonPressed = new EventEmitter();
  dispensary: Dispensary;

  constructor(private dispApp: DispensaryAppFamilyService) {}

  ngOnInit() {
    this.dispensary = this.dispApp.getDispensary();
  }

  handleButtonClicked() {
    // FIXME used logging service instead of console.log('Button clicked');
    this.buttonPressed.emit();
  }
}
