import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnInit {
  editable = false;
  @Input() data: FormGroup;
  @Output() saveContent = new EventEmitter();
  tempData = {};

  constructor() {}

  ngOnInit() {}

  edit() {
    this.editable = true;
  }

  cancel() {
    this.editable = false;
  }

  save() {
    for (const key in this.tempData) {
      if (key) {
        this.data.controls[key].setValue(this.tempData[key]);
      }
    }
    this.editable = false;
    this.saveContent.emit(this.data.getRawValue());
  }

  handleInput(e) {
    this.tempData[e.property] = e.value;
  }
}
