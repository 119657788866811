import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { merge } from 'rxjs';
import * as _ from 'underscore';

import { Router } from '@angular/router';

import { DepositDataService } from './actions/deposit.action';
import { DispensaryDataService } from './actions/dispensary.action';
import { FincenDataService } from './actions/fincen.action';
import { SalesDataService } from './actions/sales.action';
import { SalesFileDataService } from './actions/salesFile.action';
import { BankDataService } from './actions/bank.action';
import { UserDataService } from './actions/user.action';
import { AggregateDataService } from './actions/aggregates.action';
import { LoggerService } from './logger/logger.service';
import { SecureAppFamilyService } from './secure.service';
import { UtilsService } from './utils.service';
import { Location } from '@angular/common';
import { DocumentChecker } from '@gcv/shared';

/*
  This service provides communication for the Bank App family of components
  See: https://angular.io/guide/component-interaction#parent-and-children-communicate-via-a-service
*/

@Injectable({
  providedIn: 'root',
})
export class BankAppFamilyService implements OnDestroy {
  private bank: any;

  private dataRequestsToMake = 0;
  private dataRequestsCompleted = 0;
  public view = new Subject();
  private notificationPolling: any;
  private allDataLoaded = false;
  private allDataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // all maps are indexed by the dispensaryId
  private dispensaryAggregates = {};
  private fincenAggregateMap = {};
  private salesAggregates = {};
  private reportAggregates = [];
  private reportAggregatesStream$ = new BehaviorSubject<any[]>([]);
  private dispensaries: any = {};
  private dispensaryStreams: {
    [key: string]: BehaviorSubject<any>;
  } = {};
  private deposits = {};
  private mappedDeposits = {};
  private mappedReports = {};
  private depositsStreams: { [key: string]: BehaviorSubject<any[]> } = {};
  private sales = {};
  private salesAggregateStreams: { [key: string]: BehaviorSubject<any[]> } = {};
  private salesStreams: { [key: string]: BehaviorSubject<any[]> } = {};
  private salesFiles = {};
  private salesFilesStreams: { [key: string]: BehaviorSubject<any[]> } = {};
  private fincenReports = {};
  private fincenReportsStreams: { [key: string]: BehaviorSubject<any[]> } = {};
  private notifications = [];
  private allUsers = [];
  public notificationStream$ = new BehaviorSubject<any[]>([]);
  dispensarySubscription: any;
  public timeFilter = new BehaviorSubject<string>('');
  private dashboardAggregate: any = {};
  public dashboardAggregateStream = new BehaviorSubject<any>({});
  private dashboardChart: any = {};
  public dashboardChartStream = new BehaviorSubject<any>({});
  private previousRoute: any;
  constructor(
    // private session: SessionService,
    private router: Router,
    private depositDataService: DepositDataService,
    private secureApp: SecureAppFamilyService,
    private dispensaryDataService: DispensaryDataService,
    private fincenDataService: FincenDataService,
    private salesDataService: SalesDataService,
    private salesFileDataService: SalesFileDataService,
    private bankDataService: BankDataService,
    private userDataService: UserDataService,
    private aggregateDataService: AggregateDataService,
    private logger: LoggerService,
    private utils: UtilsService,
    private location: Location
  ) {
    this.view.subscribe((data: any) => {
      if (data.hasOwnProperty('view')) {
        this.router.navigate([`${data.view}`]);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeToPolling();
  }

  initState() {
    this.resetState();
    if (_.has(this.bank, 'dispensaries')) {
      this.dataRequestsToMake = this.bank.dispensaries.length * 6 + 1;
      const bank = this.bank;

      this.bank.dispensaries.forEach(dispensaryId => {
        this.aggregateDataService
          .getAggregate({ companyId: dispensaryId, type: 'dispensary' })
          .then((aggregate: any) => {
            if (aggregate.data) {
              this.dispensaryAggregates[dispensaryId] = aggregate.data;
              this.dataRequestsCompleted++;
              this.checkIfDataLoadCompleted();
            }
          });

        this.dispensaryDataService
          .read({ id: dispensaryId })
          .then(dispensary => {
            this.dispensaries[dispensaryId] = dispensary;
            this.dispensaryStreams[dispensaryId] = new BehaviorSubject<any>(this.dispensaries[dispensaryId]);
            this.dataRequestsCompleted++;
            this.checkIfDataLoadCompleted();
          })
          .catch(err => this.logger.error(err));
        this.aggregateDataService
          .getAggregate({ companyId: dispensaryId, type: 'sales' })
          .then((aggregate: object) => {
            this.salesAggregates[dispensaryId] = aggregate;
            this.salesAggregateStreams[dispensaryId] = new BehaviorSubject<any[]>(this.salesAggregates[dispensaryId]);
            this.dataRequestsCompleted++;
            this.checkIfDataLoadCompleted();
          })
          .catch(err => this.logger.error(err));
        this.salesFileDataService
          .readAll({ id: dispensaryId })
          .then(salesFiles => {
            this.salesFiles[dispensaryId] = salesFiles;
            this.salesFilesStreams[dispensaryId] = new BehaviorSubject<any[]>(this.salesFiles[dispensaryId]);
            this.dataRequestsCompleted++;
            this.checkIfDataLoadCompleted();
          })
          .catch(err => this.logger.error(err));
        this.depositDataService
          .readAll({ id: dispensaryId })
          .then((deposits: any[]) => {
            this.deposits[dispensaryId] = deposits;
            this.depositsStreams[dispensaryId] = new BehaviorSubject<any[]>(this.deposits[dispensaryId]);
            deposits.forEach(deposit => (this.mappedDeposits[deposit.deposit_id] = deposit));
            this.dataRequestsCompleted++;
            this.checkIfDataLoadCompleted();
          })
          .catch(err => this.logger.error(err));
        this.fincenDataService
          .readAll({ bankId: bank.id, dispensaryId })
          .then((fincenReports: any) => {
            this.fincenReports[dispensaryId] = fincenReports;
            this.fincenReportsStreams[dispensaryId] = new BehaviorSubject<any[]>(this.fincenReports[dispensaryId]);
            fincenReports.forEach(report => (this.mappedReports[report.id] = report));
            this.dataRequestsCompleted++;
            this.checkIfDataLoadCompleted();
          })
          .catch(err => this.logger.error(err));
      });
    } else {
      this.checkIfDataLoadCompleted(true);
    }

    this.aggregateDataService.getAggregate({ companyId: this.getBank().id, type: 'fincen' }).then((data: any) => {
      this.reportAggregates = data;
      this.dataRequestsCompleted++;
      this.checkIfDataLoadCompleted();
      this.reportAggregatesStream$.next(data);
    });

    this.userDataService.getNotifications({ id: this.secureApp.getUser().id }).then((notifications: any[]) => {
      this.notifications = notifications;
      this.notificationStream$.next(notifications);
    });
    this.userDataService
      .readAll()
      .then((users: any[]) => {
        const filteredUsers = users.filter(user => {
          if (this.userBelongsToCompany(user.companies)) {
            return user;
          }
        });
        this.allUsers = filteredUsers;
      })
      .catch(err => this.logger.error(err));
    this.getDashboardData();

    this.aggregateDataService
      .getAggregate({ companyId: this.bank.id, type: 'fincenDeposits' })
      .then((fincenAggregates: any) => (this.fincenAggregateMap = fincenAggregates.data));
  }

  navigateBack() {
    if (this.previousRoute.length > 0) {
      this.router.navigate([this.previousRoute]);
    } else {
      this.location.back();
    }
  }

  loadBank(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.bank) {
        resolve(this.bank);
      } else {
        const bank = this.secureApp.getCompany();
        this.bankDataService.read({ id: bank.id }).then(userBank => {
          this.bank = userBank;
          resolve(userBank);
        });
      }
    });
  }

  getDispensaryAggregates() {
    return this.dispensaryAggregates;
  }

  getReportAggregate(reportId) {
    return this.fincenAggregateMap[reportId];
  }

  clearNotification() {
    this.userDataService.clearNotification();
  }

  getUsersBanks() {
    const user = this.secureApp.getUser();
    const userBanks = user.companies.map((company: any) => {
      if (company.companyType === 'bank') {
        return this.bankDataService.read({ id: company.id });
      }
    });
    return Promise.all(userBanks.filter(a => a !== undefined));
  }

  getAllNotifications() {
    return this.notifications;
  }

  setPreviousUrl(url) {
    this.previousRoute = url;
  }

  resetState(logout = false) {
    if (this.dispensarySubscription) {
      this.dispensarySubscription.unsubscribe();
    }
    if (logout) {
      this.bankDataService.clearDomain();
    }
    if (typeof this.depositDataService.clearDomain !== 'undefined') {
      this.depositDataService.clearDomain();
    }
    if (typeof this.dispensaryDataService.clearDomain !== 'undefined') {
      this.dispensaryDataService.clearDomain();
    }
    if (typeof this.fincenDataService.clearDomain !== 'undefined') {
      this.fincenDataService.clearDomain();
    }
    if (typeof this.salesDataService.clearDomain !== 'undefined') {
      this.salesDataService.clearDomain();
    }
    if (typeof this.salesFileDataService.clearDomain !== 'undefined') {
      this.salesFileDataService.clearDomain();
    }
    if (typeof this.aggregateDataService.clearDomain !== 'undefined') {
      this.aggregateDataService.clearDomain();
    }

    if (typeof this.userDataService.clearDomain !== 'undefined') {
      this.userDataService.clearDomain();
    }
  }

  checkIfDataLoadCompleted(flag = false) {
    if (this.dataRequestsCompleted === this.dataRequestsToMake || flag) {
      this.allDataLoaded = true;
      this.allDataLoaded$.next(this.allDataLoaded);
      this.timeFilter.next('thisMonth');

      this.setupUpdateStreams();
    }
  }

  getDepositById(id) {
    return this.mappedDeposits[id];
  }

  getReportById(id) {
    return this.mappedReports[id];
  }
  userBelongsToCompany(companies) {
    const bankId = this.bank.id;
    let userBelongsToCompany = false;
    companies.forEach(company => {
      if (company.id === bankId) {
        userBelongsToCompany = true;
        return;
      } else if (!userBelongsToCompany) {
        return;
      } else {
        return;
      }
    });
    return userBelongsToCompany;
  }

  getAllUsersForCompany() {
    return this.allUsers;
  }

  refetchAllUsersForCompany() {
    return new Promise((resolve, reject) => {
      this.userDataService
        .readAll()
        .then((users: any[]) => {
          const filteredUsers = users.filter(user => {
            if (this.userBelongsToCompany(user.companies)) {
              return user;
            }
          });
          this.allUsers = filteredUsers;
        })
        .catch(err => this.logger.error(err));
    });
  }

  getReportAggregates(): any {
    return this.reportAggregates;
  }

  setupUpdateStreams() {
    this.pollNotifications();
    this.aggregateDataService.subscribe(state => {
      this.salesAggregates = state.aggregates;
      Object.keys(state.aggregates).forEach(dispensaryId => {
        if (this.salesAggregateStreams[dispensaryId]) {
          this.salesAggregateStreams[dispensaryId].next(this.salesAggregates[dispensaryId]);
        }
      });
    });

    this.depositDataService.subscribe(state => {
      this.deposits = state.deposits;
      Object.keys(state.deposits).forEach(dispensaryId => {
        this.depositsStreams[dispensaryId].next(this.deposits[dispensaryId]);
      });
    });

    this.dispensaryDataService.subscribe(state => {
      this.dispensaries = state.dispensaries;
      Object.keys(state.dispensaries).forEach((dispensaryId: any) => {
        if (dispensaryId) {
          this.dispensaryStreams[dispensaryId].next(this.dispensaries[dispensaryId]);
        }
      });
    });

    this.fincenDataService.subscribe(state => {
      this.fincenReports = state.reports;
      Object.keys(state.reports).forEach(dispensaryId => {
        this.fincenReportsStreams[dispensaryId].next(this.fincenReports[dispensaryId]);
        this.fincenReports[dispensaryId].forEach(report => (this.mappedReports[report.id] = report));
      });
    });

    this.userDataService.subscribe(state => {
      this.notifications = state.notifications;
      this.notificationStream$.next(state.notification);
    });

    this.bankDataService.subscribe(state => {
      const updatedBank = state.banks.filter(bank => bank.id === this.bank.id)[0];
      if (updatedBank) {
        this.bank = updatedBank;
      }
    });

    this.timeFilter.subscribe(() => {
      this.getDashboardData();
    });
  }

  getAllDataLoaded(): boolean {
    return this.allDataLoaded;
  }

  getAllDataLoadedStream() {
    return this.allDataLoaded$;
  }

  getBank() {
    return this.bank;
  }

  pollNotifications() {
    // this.notificationPolling = interval(3000)
    //   .switchMap(() => {
    //     return this.userDataService.getNotifications({
    //       id: this.getUserIdIfLoaded()
    //     });
    //   })
    //   .subscribe((notifications: Notification[]) => {
    //     this.logger.debug('Notification Polling Subscribed');
    //     this.notifications = notifications;
    //     this.notificationStream$.next(notifications);
    //   });
  }

  unsubscribeToPolling() {
    this.logger.debug('Notification Polling Unsubscribed');
    this.notificationPolling.unsubscribe();
  }

  getDispensary(dispensaryId): any {
    return this.dispensaries[dispensaryId];
  }

  getDispensaryStream(dispensaryId): BehaviorSubject<any> {
    return this.dispensaryStreams[dispensaryId];
  }

  getDispensaries() {
    return this.dispensaries;
  }

  getSalesAggregates() {
    return this.salesAggregates;
  }

  getSalesAggregate(dispensaryId) {
    return this.salesAggregates[dispensaryId];
  }

  getSalesAggregateStream(dispensaryId) {
    return this.salesAggregateStreams[dispensaryId];
  }

  subscribeToDispensaries(dispensaryId, observer) {
    this.dispensaryStreams[dispensaryId].subscribe(observer);
  }

  subscribeToAllDispensaries(observer) {
    const allDispensaryStreams = _.values(this.dispensaryStreams);
    merge(...allDispensaryStreams).subscribe(observer);
  }

  subscribeToAllDeposits(observer) {
    const allDepositStreams = _.values(this.depositsStreams);
    merge(...allDepositStreams).subscribe(observer);
  }

  subscribeToAllReports(observer) {
    const allReportStreams = _.values(this.fincenReportsStreams);
    merge(...allReportStreams).subscribe(observer);
  }

  subscribeToAllSalesAggregates(observer) {
    const allSalesAggregateStreams = _.values(this.salesAggregateStreams);
    merge(...allSalesAggregateStreams).subscribe(observer);
  }

  subscribeToAllStreams(deposit, report, dispensary) {
    if (deposit) {
      this.subscribeToAllDeposits(deposit);
    }

    if (report) {
      this.subscribeToAllReports(report);
    }

    if (dispensary) {
      this.subscribeToAllDispensaries(dispensary);
    }
  }

  getDeposits(dispensaryId): any[] {
    return this.deposits[dispensaryId];
  }

  getDepositsStream(dispensaryId) {
    return this.depositsStreams[dispensaryId];
  }

  getAllDepositStreams() {
    return _.values(this.depositsStreams);
  }

  subscribeToDeposits(dispensaryId, observer) {
    this.depositsStreams[dispensaryId].subscribe(observer);
  }

  getAllDeposits(): any[] {
    const allDeposits = [];
    _.keys(this.dispensaries).forEach(dispensaryId => {
      allDeposits[dispensaryId] = this.getDeposits(dispensaryId);
    });
    return allDeposits;
  }

  getSales(dispensaryId): any[] {
    return this.sales[dispensaryId];
  }

  getSalesStream(dispensaryId) {
    return this.salesStreams[dispensaryId];
  }

  getAllSalesStreams() {
    return _.values(this.salesStreams);
  }

  subscribeToSales(dispensaryId, observer) {
    this.salesStreams[dispensaryId].subscribe(observer);
  }

  getAllSales() {
    const allSales = {};
    _.keys(this.dispensaries).forEach(dispensaryId => {
      allSales[dispensaryId] = this.getSales(dispensaryId);
    });
    return allSales;
  }

  getSalesFiles(dispensaryId): any[] {
    return this.salesFiles[dispensaryId];
  }

  getSalesFilesStream(dispensaryId) {
    return this.salesFilesStreams[dispensaryId];
  }

  subscribeToSalesFiles(dispensaryId, observer) {
    this.salesFilesStreams[dispensaryId].subscribe(observer);
  }

  readAllFincenReports(dispensaryId) {
    this.userDataService
      .getNotifications({ id: this.secureApp.getUser().id })
      .then((notifications: any[]) => {
        this.notifications = notifications;
        this.notificationStream$.next(notifications);
        this.fincenDataService
          .readAll({ bankId: this.bank.id, dispensaryId })
          .then(fincenReports => {
            this.fincenReports[dispensaryId] = fincenReports;
            this.fincenReportsStreams[dispensaryId].next(this.fincenReports[dispensaryId]);
          })
          .catch(err => this.logger.error(err));
      })
      .catch(err => this.logger.error(err));
  }

  // TODO end note

  getFincenReports() {
    return this.fincenReports;
  }

  getFincenReportsForDispensary(dispensaryId) {
    return this.fincenReports[dispensaryId];
  }

  getDepositNotification() {
    if (this.notifications) {
      return this.notifications.filter(notification => notification.type === 'new_deposit');
    }
  }

  getDashboardData() {
    if (this.bank) {
      this.aggregateDataService.getAggregate({ companyId: this.getBank().id, type: 'bank' }).then((data: any) => {
        this.dashboardAggregate = data;
        this.dashboardAggregateStream.next(data);
      });
    }
  }

  fetchReportAggregates() {
    this.aggregateDataService.getAggregate({ companyId: this.getBank().id, type: 'fincen' }).then((data: any) => {
      this.reportAggregates = data;
      this.reportAggregatesStream$.next(data);
    });
  }

  getBankableChartData() {
    const localDateRange = this.utils.getLocalDateTimeRange(this.timeFilter.value);
    const groupBy = this.utils.getChartGrouping(this.timeFilter.value);
    const keys: any = this.utils.getGroupMap(groupBy, localDateRange.start, localDateRange.end);
    const aggregateData = {};
    for (const crb in this.salesAggregates) {
      if (
        this.dispensaries[crb] &&
        DocumentChecker.isBankReviewed(this.dispensaries[crb], 'default') &&
        DocumentChecker.isGcvReviewed(this.dispensaries[crb])
      ) {
        aggregateData[crb] = {};
        const aggregate = this.salesAggregates[crb];
        keys.forEach(key => {
          if (aggregate[key]) {
            aggregateData[crb][key] = aggregate[key];
          } else {
            aggregateData[crb][key] = {
              bankable: 0,
              unbankable: 0,
            };
          }
        });
      }
    }
    return this.condenseAggregateData(aggregateData);
  }

  condenseAggregateData(filteredAggregates) {
    const condensedAggregates = {};
    for (const crb in filteredAggregates) {
      if (crb) {
        const crbName = this.dispensaries[crb].name;
        condensedAggregates[crbName] = {
          bankable: 0,
          unbankable: 0,
        };
        for (const date in filteredAggregates[crb]) {
          if (date) {
            const data = filteredAggregates[crb][date];
            condensedAggregates[crbName].bankable += data.bankable;
            condensedAggregates[crbName].unbankable += data.unbankable;
          }
        }
      }
    }
    return condensedAggregates;
  }

  getVerifiedSales() {
    if (this.dashboardAggregate) {
      return this.dashboardAggregate.data[this.timeFilter.value];
    }
    return;
  }

  setDashboardTimeFilter(timePeriod) {
    const newFilter = this.utils.getNewTimeFilter(timePeriod);
    this.timeFilter.next(newFilter);
  }

  async getSalesByDepositId(id: string, depositId) {
    const sales = await this.salesDataService.getSalesByDepositId({
      id,
      depositId,
    });
    return sales;
  }

  async getPaginatedSalesByDepositId(id: string, depositId: string, limit: string, key?: any) {
    const salesAndKey = await this.salesDataService.getPaginatedSalesByDepositId({
      id,
      depositId,
      limit,
      key,
    });
    return salesAndKey;
  }

  async getPaginatedSalesByS3FileUploadDate(id: string, s3UploadDate, limit, key?: any) {
    const salesAndKey = await this.salesDataService.getPaginatedSalesFromUploadDate({ id, s3UploadDate, limit, key });
    return salesAndKey;
  }

  getSalesByDate(id, startDate, endDate) {
    return this.salesDataService.getSalesInDateRange({
      id,
      startDate,
      endDate,
    });
  }

  getAccountsNotification() {
    if (this.notifications) {
      return this.notifications.filter(notification => notification.type === 'bank_dispensary_review');
    }
  }

  getReportsNotification() {
    if (this.notifications) {
      return this.notifications.filter(notification => notification.type === 'new_fincen');
    }
  }

  completeNotification(data) {
    const DISPENSARY = 'dispensary';
    const REPORT = 'report';
    const DEPOSIT = 'deposit';

    switch (data.type) {
      case REPORT:
        const completedReport = this.getReportsNotification().filter(report => report.data.id === data.id);
        if (completedReport.length >= 1) {
          completedReport.forEach(notification => {
            notification.completed = true;
            this.processCompletedNotification(notification);
          });
        }
        break;
      case DISPENSARY:
        // FIXME used logging service instead of console.log(this.getAccountsNotification(), 'testd');
        const completedDispensary = this.getAccountsNotification().filter(dispensary => dispensary.data.id === data.id);
        if (completedDispensary.length >= 1) {
          completedDispensary.forEach(notification => {
            notification.completed = true;
            this.processCompletedNotification(notification);
          });
        }
        break;
      case DEPOSIT:
        const completedDeposit = this.getDepositNotification().filter(deposit => deposit.data.deposit_id === data.id);
        if (completedDeposit.length >= 1) {
          completedDeposit.forEach(notification => {
            notification.completed = true;
            this.processCompletedNotification(notification);
          });
        }
        break;
    }
  }

  processCompletedNotification(data) {
    this.userDataService
      .completeNotification({
        id: this.secureApp.getUser().id,
        body: data,
      })
      .then(() => {})
      .catch(err => this.logger.error(err));
  }
}
