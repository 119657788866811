import * as _ from 'underscore';
import {
  GET_USER,
  RESET_USER,
  PUT_USER,
  GET_ALL_USERS,
  GET_NOTIFICATIONS,
  CLEAR_NOTIFICATION,
  PUT_NOTIFICATIONS,
  PUT_USER_STAFF,
} from '../action.types';

export interface AppModel {
  user: {};
  allUsers: any[];
  showNotification: boolean;
  notifications: any[];
}

export interface AppStateAction {
  type: string;
  payload: {
    objects: any;
    dispensaryId: string;
  };
}

const state: AppModel = {
  user: {},
  allUsers: [],
  showNotification: true,
  notifications: [],
};

export function userReducer(appModel: AppModel = state, action: AppStateAction) {
  switch (action.type) {
    case GET_USER:
      return {
        ...appModel,
        user: action.payload.objects,
      };
    case RESET_USER:
      const copy = appModel;
      copy.notifications = [];
      copy.allUsers = [];
      return copy;
    case GET_NOTIFICATIONS:
      return {
        ...appModel,
        notifications: action.payload.objects,
      };
    case PUT_NOTIFICATIONS:
      const notificationCopys = appModel.notifications;
      return {
        ...appModel,
        notifications: notificationCopys.map(notification => {
          if (notification.id === action.payload.objects.id) {
            notification.completed = true;
          }
          return notification;
        }),
      };
    case PUT_USER_STAFF:
      const allUsersCopy = appModel.allUsers[0];
      // FIXME used logging service instead of console.log(allUsersCopy);
      return {
        ...appModel,
        allUsers: [
          allUsersCopy.map(user => {
            if (user.id === action.payload.objects.id) {
              return action.payload.objects;
            } else {
              return user;
            }
          }),
        ],
      };
    case PUT_USER:
      return {
        ...appModel,
        user: action.payload.objects,
      };
    case GET_ALL_USERS:
      return {
        ...appModel,
        allUsers: [action.payload.objects],
      };
    case CLEAR_NOTIFICATION:
      return {
        ...appModel,
        showNotification: false,
      };
    default:
      return appModel;
  }
}
