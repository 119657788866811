import { EventEmitter, Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  @Input() label = '';
  @Input() labelStyle: any;
  @Input() checked = false;
  @Output() toggled = new EventEmitter();
  constructor() {}

  ngOnInit() {
    // FIXME used logging service instead of console.log(this.checked);
  }

  handleChange(e) {
    this.toggled.emit(e.target.checked);
  }
}
