import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as _ from 'underscore';

// import { AppModel } from '../gcv.reducer';
import { GET_AGGREGATE, RESET_AGGREGATE } from '../action.types';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class AggregateDataService {
  private aggregateStore;

  constructor(private sdk: DefaultService, private store: Store<any>) {
    this.aggregateStore = store.select('aggregates');
  }

  public subscribe(observer) {
    return this.aggregateStore.subscribe(observer);
  }

  public clearDomain() {
    this.aggregateStore.dispatch({
      type: RESET_AGGREGATE,
    });
  }

  getAggregate(keys: { companyId: string; type: string }) {
    return new Promise((resolve, reject) => {
      this.sdk.aggregatesCompanyIdTypeGet(keys.type, keys.companyId).subscribe(
        aggregates => {
          if (!aggregates.hasOwnProperty('data')) {
            const payload = {
              companyId: keys.companyId,
              objects: aggregates,
            };

            this.aggregateStore.dispatch({
              type: GET_AGGREGATE,
              payload,
            });
          }
          resolve(aggregates);
        },
        err => reject(err)
      );
    });
  }
}
