import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Inject, Component, OnInit, ErrorHandler } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserDataService } from '../../../../services/actions/user.action';
import { LoggerService } from '../../../../services/logger/logger.service';

@Component({
  selector: 'app-dispensary-admin-verification',
  templateUrl: './dispensary-admin-verification.html',
  styleUrls: ['./dispensary-admin-verification.scss'],
})
export class DispensaryAdminVerificationComponent implements OnInit {
  verificationFormGroup: FormGroup;
  questions = [];
  selectedQuestions;
  answers;
  user;
  loading = true;
  resets = 0;
  constructor(
    public dialogRef: MatDialogRef<DispensaryAdminVerificationComponent>,
    private logger: LoggerService,
    private userDataService: UserDataService,
    private errorHandler: ErrorHandler,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user = data.user;
  }

  ngOnInit() {
    this.userDataService
      .verifyUser(this.user)
      .then((verificationData: any) => {
        if (verificationData && verificationData.questions && !verificationData.length) {
          this.questions = verificationData.questions;
          this.answers = verificationData.answers;
          this.selectedQuestions = this.questions.splice(0, 3);
          this.loading = false;
          this.setupForm();
        } else {
          this.logger.log('User not found');
          this.close({ verified: false });
        }
      })
      .catch(err => {
        // this.logger.error(err);
        // this.errorHandler.handleError(err);
        this.close({ verified: false });
      });
  }

  setupForm() {
    this.verificationFormGroup = new FormGroup(this.formatForm());
  }

  formatForm() {
    const formcontrols = {};
    this.selectedQuestions.forEach((question, index) => {
      let val = '';
      if (this.verificationFormGroup && this.verificationFormGroup.controls[question.code]) {
        val = this.verificationFormGroup.controls[question.code].value;
      }

      formcontrols[question.code] = new FormControl(val, Validators.required);
    });

    return formcontrols;
  }

  verifyUser() {
    const usersAnswers = this.verificationFormGroup.getRawValue();
    const userVerified = this.determineIfVerified(usersAnswers);
    if (userVerified) {
      this.close({ verified: true });
    } else {
      this.close({ verified: false });
    }
  }

  close(verifiedStatus) {
    this.dialogRef.close(verifiedStatus);
  }

  getNewQuestion(index) {
    if (this.resets < 6) {
      this.resets++;
      if (this.selectedQuestions) {
        this.selectedQuestions.splice(index, 1);
        const newQuestion: any[] = this.questions.splice(0, 1);
        this.selectedQuestions.splice(index, 0, newQuestion[0]);
      }
      this.setupForm();
    }
  }

  determineIfVerified(usersAnswers) {
    let correctAnswers = 0;
    for (const keys in usersAnswers) {
      if (this.answers[keys] === usersAnswers[keys]) {
        correctAnswers++;
      }
    }
    if (correctAnswers / this.selectedQuestions.length >= 1) {
      return true;
    } else {
      return false;
    }
  }
}
