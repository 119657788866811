import { Injectable, ErrorHandler } from '@angular/core';

import { Store } from '@ngrx/store';

import * as _ from 'underscore';

// import { AppModel } from '../gcv.reducer';
import {
  RESET_USER,
  PUT_USER,
  GET_USER,
  GET_ALL_USERS,
  CLEAR_NOTIFICATION,
  GET_NOTIFICATIONS,
  PUT_NOTIFICATIONS,
  PUT_USER_STAFF,
} from '../action.types';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private userStore;

  constructor(private sdk: DefaultService, private store: Store<any>, private errorHandler: ErrorHandler) {
    this.userStore = store.select('user');
  }

  public subscribe(observer) {
    return this.userStore.subscribe(observer);
  }

  public clearDomain() {
    this.userStore.dispatch({
      type: RESET_USER,
    });
  }

  public clearNotification() {
    this.userStore.dispatch({
      type: CLEAR_NOTIFICATION,
    });
  }

  public verifyUser(user) {
    return new Promise((resolve, reject) => {
      this.sdk.verifyUserPost(user).subscribe(
        verificationQuestions => {
          resolve(verificationQuestions);
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }

  public read(keys: { id: string }) {
    return new Promise((resolve, reject) => {
      // FIXME used logging service instead of console.log(keys);
      this.sdk.usersIdGet(keys.id).subscribe(
        user => {
          // FIXME used logging service instead of console.log(user);
          if (user) {
            const payload = {
              objects: user,
            };
            this.userStore.dispatch({
              type: GET_USER,
              payload,
            });
          }
          resolve(user);
        },
        err => {
          // FIXME used logging service instead of console.log(err);
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }

  public readAll() {
    return new Promise((resolve, reject) => {
      this.sdk.usersGet().subscribe(
        users => {
          const payload = {
            objects: users,
          };

          this.userStore.dispatch({
            type: GET_ALL_USERS,
            payload,
          });

          resolve(users);
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }

  public getNotifications(keys: { id: string }) {
    return new Promise((resolve, reject) => {
      this.sdk.notificationsIdGet(keys.id).subscribe(
        notifications => {
          const payload = {
            objects: notifications,
          };
          this.userStore.dispatch({
            type: GET_NOTIFICATIONS,
            payload,
          });
          resolve(notifications);
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }

  public completeNotification(keys: { id: string; body: any }) {
    return new Promise((resolve, reject) => {
      this.sdk.notificationsIdPut(keys.id, keys.body).subscribe(
        data => {
          this.userStore.dispatch({
            type: PUT_NOTIFICATIONS,
            payload: { objects: data },
          });
          resolve(data);
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }

  public createUser(data) {
    return new Promise((resolve, reject) => {
      this.sdk.usersPost(data).subscribe(
        newUser => {
          resolve(newUser);
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }

  // public resetDemoFlow({ id, type }: { id: string; type: string }) {
  //   return new Promise((resolve, reject) => {
  //     this.sdk.resetDemoFlow(id, type).subscribe(
  //       () => resolve(),
  //       err => {
  //         this.errorHandler.handleError(err);
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  public update(
    keys: {
      id: string;
    },
    data: object
  ) {
    return new Promise((resolve, reject) => {
      this.sdk.usersIdPut(keys.id, data).subscribe(
        user => {
          // FIXME used logging service instead of console.log(user);
          const payload = {
            objects: user,
          };
          this.userStore.dispatch({
            type: PUT_USER,
            payload,
          });
          resolve(user);
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }

  public updateStaff(
    keys: {
      id: string;
    },
    data: object
  ) {
    return new Promise((resolve, reject) => {
      this.sdk.usersIdPut(keys.id, data).subscribe(
        user => {
          const payload = {
            objects: user,
          };
          this.userStore.dispatch({
            type: PUT_USER_STAFF,
            payload,
          });
          resolve(user);
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }

  public resendVerificationCode(username) {
    return new Promise((resolve, reject) => {
      this.sdk.resendConfirmationCodeGet(username).subscribe(
        () => {
          resolve({ success: true });
        },
        err => {
          this.errorHandler.handleError(err);
          reject(err);
        }
      );
    });
  }
}
