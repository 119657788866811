import { Component, Input, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sales-side-drawer',
  templateUrl: './sales-side-drawer.component.html',
  styleUrls: ['./sales-side-drawer.component.scss'],
})
export class SalesSideDrawerComponent {
  @ViewChild('sidenavs') sideNav;
  @Input() opened = false;
  @Input() items = {};
  @Output() toggled = new EventEmitter(false);

  constructor() {}

  toggle() {
    this.toggled.emit(this.sideNav.opened);
  }

  close() {
    this.toggled.emit(false);
  }
}
