import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-icon',
  templateUrl: './spinner-icon.component.html',
  styleUrls: ['./spinner-icon.component.scss'],
})
export class SpinnerIconComponent implements OnInit {
  @Input() styles: string;

  constructor() {}

  ngOnInit() {}
}
