import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UserDataService } from 'src/services/actions/user.action';
import { LoggerService } from 'src/services/logger/logger.service';
import { GcvAppFamilyService } from '../../../services/gcvApp.service';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-organizations',
  templateUrl: './gcv-organizations.component.html',
  styleUrls: ['./gcv-organizations.component.scss'],
})
export class GcvOrganizationsComponent implements OnInit {
  banks: any[] = [];
  dispensaries: any[];
  organizations: any[];
  users: any[] = [];
  displayedBankColumns = ['orgName'];
  displayedDispColumns = ['name', 'due_diligence_step'];
  public dataBankMap = {
    orgName: 'Organization Name',
  };
  public dataDispMap = {
    name: 'Organization Name',
    due_diligence_step: 'Status',
  };
  constructor(
    public matDialog: MatDialog,
    private userDataService: UserDataService,
    private logger: LoggerService,
    private gcvApp: GcvAppFamilyService,
    private router: Router
  ) {
    this.banks = this.gcvApp.getBanks();
    this.dispensaries = this.gcvApp.getDispensaries();
  }

  ngOnInit() {
    this.userDataService
      .readAll()
      .then((data: any[]) => (this.users = data))
      .catch(err => this.logger.error(err));
  }

  create(type) {
    const data: NavigationExtras = {
      queryParams: {
        type,
      },
    };
    this.router.navigate(['/secure/gcv/create/organization'], data);
  }

  getDispensaryUsers(dispensary) {
    const users = [];
    this.users.forEach(user => {
      return user.companies.forEach(company => {
        if (company.id === dispensary.id) {
          users.push(user);
          return;
        }
      });
    });
    return users;
  }

  openBank(e) {
    const data: NavigationExtras = {
      queryParams: {
        data: JSON.stringify(e),
      },
    };
    this.router.navigate(['/secure/gcv/view/bank'], data);
  }

  openDispensary(e) {
    const data: NavigationExtras = {
      queryParams: {
        data: JSON.stringify(e),
      },
    };
    this.router.navigate(['/secure/gcv/view/dispensary'], data);
  }
}
