import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'reportDaysRemaining' })
export class CalculateReportDaysRemaining implements PipeTransform {
  transform(report): string {
    // FIXME used logging service instead of console.log(report);
    if (report.report_type === 'SAR') {
      const dueDate = DateTime.fromISO(report.due_date, { zone: 'utc' });
      const todaysDate = DateTime.utc();
      const diff = todaysDate.diff(dueDate, 'days').toObject();
      if (Math.floor(diff.days) >= 1) {
        return 'LATE';
      } else {
        return `${Math.abs(Math.floor(diff.days))} Day(s)`;
      }
    } else {
      const dueDate = DateTime.fromISO(report.due_date, { zone: 'utc' });
      const todaysDate = DateTime.utc();
      const diff = todaysDate.diff(dueDate, 'days').toObject();
      if (Math.floor(diff.days) >= 1) {
        return 'LATE';
      } else {
        return `${Math.abs(Math.floor(diff.days))} Day(s)`;
      }
    }
  }
}
