import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BankDataService } from 'src/services/actions/bank.action';
import { LoggerService } from 'src/services/logger/logger.service';

@Component({
  selector: 'app-organizations-edit-bank-component',
  templateUrl: './gcv-organizations-edit-bank.component.html',
  styleUrls: ['./gcv-organizations-edit-bank.component.scss'],
})
export class GcvOrganizationsEditBankComponent {
  bank: any;
  dispensaries: any;
  selectedOrgs = [];
  selectedOrganization = [];
  constructor(
    public dialogRef: MatDialogRef<GcvOrganizationsEditBankComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bankService: BankDataService,
    private logger: LoggerService
  ) {
    this.bank = data.bank;
    this.dispensaries = data.dispensaries;
    const filteredOrgs = [];
    this.bank.dispensaries.forEach(dispId => {
      this.dispensaries.forEach(disp => {
        if (disp.id === dispId) {
          filteredOrgs.push(disp);
        }
      });
    });
    this.selectedOrganization = data.dispensaries;
    this.selectedOrgs = filteredOrgs;
  }

  removeOrg(org) {
    this.selectedOrgs = this.selectedOrgs.filter(selectedOrg => selectedOrg.id !== org.id);
  }
  close() {
    this.dialogRef.close();
  }

  selectionChanged({ value }) {
    const checkedSelectedOrgs = this.selectedOrgs.filter(org => org.id === value.id);
    if (checkedSelectedOrgs.length === 0) {
      this.selectedOrgs.push(value);
    }
    return;
  }

  saveBank() {
    const data = { ...this.bank, dispensaries: this.formatSelectedOrgs() };
    this.bankService
      .update({ id: this.bank.id }, data)
      .then(() => this.close())
      .catch(err => this.logger.error(err));
  }

  formatSelectedOrgs() {
    const data = [];
    this.selectedOrgs.forEach(disp => {
      data.push(disp.id);
    });
    return data;
  }
}
