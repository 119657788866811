import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User, Bank, Dispensary } from '@gcv/shared';
import { GcvAppFamilyService } from '../../../../services/gcvApp.service';
import { UserDataService } from '../../../../services/actions/user.action';
import { ConfirmDialogComponent } from '../../common/confirmDialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { LoggerService } from '../../../../services/logger/logger.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-gcv-edit-user',
  templateUrl: './gcv-edit-user.component.html',
  styleUrls: ['./gcv-edit-user.component.scss'],
})
export class GcvEditUserComponent implements OnInit {
  selectedTab = 0;
  user: User;
  data: any = {};
  confirmed = '';
  constructor(
    private route: ActivatedRoute,
    private gcvApp: GcvAppFamilyService,
    private userDataService: UserDataService,
    private matDialog: MatDialog,
    private snackbar: MatSnackBar,
    private logger: LoggerService,
    private location: Location
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['user']) {
        this.user = JSON.parse(params['user']);
        if (this.user.companies[0].companyType === 'bank') {
          this.gcvApp.getBanks().forEach(
            (bank: Bank): any => {
              if (bank.id === this.user.companies[0].id) {
                this.data.orgName = bank.orgName;
                this.data.org = bank;
              }
            }
          );
        } else if (this.user.companies[0].companyType === 'dispensary') {
          this.gcvApp.getDispensaries().forEach(
            (dispensary: Dispensary): any => {
              if (dispensary.id === this.user.companies[0].id) {
                this.data.orgName = dispensary.name;
                this.data.org = dispensary;
              }
            }
          );
        } else {
          this.data.orgName = 'Green Check Verified';
        }
      }
      if (params['confirmed']) {
        this.confirmed = params['confirmed'];
      }
    });
  }

  ngOnInit() {}

  resendConfirmation() {
    this.userDataService.resendVerificationCode(this.user.email);
  }

  disableUser() {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: { message: 'Are You Sure You Want To Disable This User?' },
    });
    dialog.afterClosed().subscribe(({ confirmed }) => {
      if (confirmed) {
        const data = {
          ...this.user,
          disabled: true,
          companies: [],
          primaryContact: false,
        };
        this.handleSaveUser(data);
      }
    });
  }

  handleSaveUser(data) {
    this.userDataService
      .update({ id: data.id }, data)
      .then(() => {
        const snackbar = this.snackbar.open('User has been updated', '', {
          duration: 3000,
        });
        snackbar.afterDismissed().subscribe(() => {
          this.location.back();
        });
      })
      .catch(err => {
        this.logger.error(err);
      });
  }
}
