import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  editable = false;
  data = {
    newPassword: '',
    oldPassword: '',
    confirmPassword: '',
  };
  error = '';

  constructor() {}

  ngOnInit() {}

  edit() {
    this.editable = true;
  }

  save() {
    if (this.data.confirmPassword === this.data.newPassword) {
      // FIXME used logging service instead of console.log('Password Changed');
    } else {
      this.error = 'Your passwords do not match';
      setTimeout(() => {
        this.error = '';
      }, 1000);
    }
  }

  handleInput(e, property) {
    this.data[e.property] = e.value;
  }

  cancel() {
    this.editable = false;
  }

  isDisabled() {
    // FIXME used logging service instead of console.log(this.data);
    return !(
      this.data.oldPassword.length > 7 &&
      this.data.confirmPassword.length > 7 &&
      this.data.oldPassword.length > 7
    );
  }
}
