import { Component, OnInit, Input } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'app-pagination-control',
  templateUrl: './pagination-control.component.html',
  styleUrls: ['./pagination-control.component.scss'],
})
export class PaginationControlComponent implements OnInit {
  @Input() public page = 1;
  public filter = '';
  public maxSize = 7;
  public directionLinks = true;
  public autoHide = false;
  public responsive = false;
  @Input() public config: PaginationInstance;

  public labels: any = {
    previousLabel: 'Previous',
    nextLabel: 'Next',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`,
  };
  constructor() {}

  ngOnInit() {
    // FIXME used logging service instead of console.log(this);
  }

  onPageChange(page: number) {
    this.config.currentPage = page;
  }
}
