import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as base64arraybuffer from 'base64-arraybuffer';
import { HttpClient } from '@angular/common/http';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-review-account-document',
  templateUrl: './review-account-document.component.html',
  styleUrls: ['./review-account-document.component.scss'],
})
export class ReviewAccountDocumentComponent implements OnInit {
  @ViewChild('pdfView')
  pdfView: ElementRef;
  selectedTab = 0;
  docMap = {
    cannabisLicenseCopy: 'Cannabis License',
    balanceDoc: 'Balance Sheet',
    leaseDoc: 'Commercial Lease',
    taxDoc: 'Tax Return',
    incomeDoc: 'Income Statement',
    policyDoc: 'Policies and Procedures',
    certificateOfGoodStanding: 'Certificate of Good Standing',
    beneficialOwnerDoc: 'Beneficial Ownership',
    articlesDoc: 'Articles of Incorporation',
  };
  statusMap = {
    approve: 'Approve',
    // flag: 'Flag',
  };
  objUrl: string;
  documentName = '';
  statusSelected = 'approve';
  s3KeyUrl: string;
  type: string;
  approved = false;

  constructor(private httpClient: HttpClient, public ngxSmartModalService: NgxSmartModalService) {}

  ngOnInit() {
    const viewModal = this.ngxSmartModalService.getModal('reviewDocument');
    viewModal.onDataAdded.subscribe(data => {
      this.documentName = data.documentName;
      // if (this.statusMap[data.status]) {
      //   this.statusSelected = this.statusMap[data.status];
      // }

      if (data.status === 'reviewed') {
        this.approved = true;
      }
      if (data.objUrl) {
        this.objUrl = data.objUrl;
        this.pdfView.nativeElement.src = this.objUrl;
      } else if (data.s3KeyUrl) {
        this.type = 'application/pdf';
        if (data.hasOwnProperty('type')) {
          this.type = 'image/jpeg';
        }
        this.s3KeyUrl = data.s3KeyUrl;
        this.fetchDataFromS3();
      } else {
        // throw Error(
        //   'must pass in either an objUrl from window.URL.createObjectURL or an s3KeyUrl'
        // );
      }
    });
  }

  fetchDataFromS3() {
    const self = this;
    this.getPdfData(this.s3KeyUrl).subscribe(
      (res: Blob) => {
        const fileReader = new FileReader();
        fileReader.onload = e => {
          const filename = 'filename';
          const base64 = e.target['result'];
          const base64Array = base64arraybuffer.decode(base64);
          const file = new File([base64Array], filename, {
            type: this.type,
          });
          const objUrlFromS3 = window.URL.createObjectURL(file);
          this.objUrl = objUrlFromS3;
          self.pdfView.nativeElement.src = objUrlFromS3;
        };
        fileReader.readAsBinaryString(res);
      },
      err => {
        console.error(err);
      }
    );
  }

  getPdfData(s3KeyUrl: string): any {
    const cacheBusterUrl = `${s3KeyUrl}`;
    return this.httpClient.get(cacheBusterUrl, {
      responseType: 'blob',
    });
  }

  cancel() {
    this.ngxSmartModalService.close('reviewDocument');
  }

  handleSelectionChanged() {}

  submit() {
    const data = {
      documentName: this.documentName,
      status: this.statusSelected,
    };
    this.documentName = '';
    this.ngxSmartModalService.resetModalData('reviewDocument');
    this.ngxSmartModalService.setModalData(data, 'reviewDocument');
    this.ngxSmartModalService.close('reviewDocument');
  }

  isDisabled() {
    return this.statusSelected ? false : true;
  }
}
