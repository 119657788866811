import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation-shell',
  templateUrl: './navigation-shell.component.html',
  styleUrls: ['./navigation-shell.component.scss'],
})
export class NavigationShellComponent implements OnInit, OnChanges {
  @Input() username: string;
  @Input() notificationsComplete: any[];
  @Input() notificationsIncomplete: any[];
  @ViewChild('sidenav') sideNav;
  @Output() profileClicked = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() notificationClicked = new EventEmitter();
  @Output() notificationHandled = new EventEmitter();

  public accountOpen = false;
  public opened = false;
  public tabIndex = 0;
  public completeNotifications = [];
  public incompleteNotifications = [];
  constructor(private router: Router) {}

  ngOnInit() {}

  ngOnChanges() {
    // FIXME used logging service instead of console.log(this.notificationsIncomplete);
  }

  handleProfileClick() {
    // FIXME used logging service instead of console.log('Profile Clicked');
    this.profileClicked.emit();
  }

  handleNotificationIconClick() {
    this.sideNav.toggle();
    this.notificationClicked.emit();
  }

  handleNotificationClicked(e) {
    this.sideNav.toggle();
    this.notificationHandled.emit(e);
  }

  goToDashboard() {
    const url = this.router.url;
    if (url.search('bank') > -1) {
      this.router.navigate(['/secure/bank/dashboard/overview']);
    } else if (url.search('dispensary') > -1) {
      this.router.navigate(['/secure/dispensary/dashboard/overview']);
    } else {
      this.router.navigate(['/gcv/dashboard/']);
    }
  }

  toggleProfileDrawer(val) {
    this.accountOpen = val;
  }

  handleLogout() {
    this.logout.emit();
  }

  handleAccountSettingsClicked() {
    // FIXME used logging service instead of console.log('Account Settings Clicked!');
    this.profileClicked.emit();
  }

  handleDismissNotificationDrawer() {
    // FIXME used logging service instead of console.log('Notification Drawer Closed');
    this.sideNav.toggle();
  }

  handleNotificationTabClicked(val) {
    this.tabIndex = val;
  }
}
