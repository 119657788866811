import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { LoggerService } from './logger/logger.service';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RollbarService } from '../app/rollbar/rollbar.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}
  handleError(error: any) {
    const rollbar = this.injector.get(RollbarService);
    const loggingService = this.injector.get(LoggerService);
    const location = this.injector.get(LocationStrategy);
    const message = error.message ? error.message : error.toString();
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    loggingService.error(message, [url]);
    rollbar.error(error.originalError || error);
    throw error;
  }
}
