export const READ_ALL = 'READ_ALL';

export const GET_DISPENSARY = 'GET_DISPENSARY';
export const GET_NEW_DEPOSIT = 'GET_NEW_DEPOSIT';
export const PUT_DISPENSARY = 'PUT_DISPENSARY';
export const POST_DISPENSARY = 'POST_DISPENSARY';
export const POST_DEPOSIT = 'POST_DEPOSIT';
export const PUT_DEPOSIT = 'PUT_DEPOSIT';
export const GET_ALL_DEPOSITS = 'GET_ALL_DEPOSITS';
export const RESET_DEPOSITS = 'RESET_DEPOSITS';
export const REMOVE_DISPENSARIES = 'REMOVE_DISPENSARIES';
export const GET_ALL_DISPENSARIES = 'GET_ALL_DISPENSARIES';
export const PUT_SALES = 'PUT_SALES';
export const GET_ALL_SALES = 'GET_ALL_SALES';
export const RESET_SALES = 'RESET_SALES';

export const PUT_SALES_FILE = 'PUT_SALES_FILE';
export const GET_ALL_SALES_FILES = 'GET_ALL_SALES_FILES';
export const RESET_SALES_FILES = 'RESET_SALES_FILES';

export const PUT_BANK = 'PUT_BANK';
export const GET_BANK = 'GET_BANK';
export const POST_BANK = 'POST_BANK';
export const RESET_BANK = 'RESET_BANK';
export const GET_ALL_BANKS = 'GET_ALL_BANKS';

export const PUT_USER = 'PUT_USER';
export const GET_USER = 'GET_USER';
export const RESET_USER = 'RESET_USER';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const PUT_USER_STAFF = 'PUT_USER_STAFF';

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const PUT_NOTIFICATIONS = 'PUT_NOTIFICATIONS';

export const GET_AGGREGATE = 'GET_AGGREGATE';
export const RESET_AGGREGATE = 'RESET_AGGREGATE';

export const GET_ALL_REPORTS = 'GET_ALL_REPORTS';
export const PUT_REPORTS = 'PUT_REPORTS';
export const POST_REPORT = 'POST_REPORT';
export const RESET_REPORTS = 'RESET_REPORTS';

export const RESET_IDENTITIES = 'RESET_IDENTITIES';
export const GET_ALL_IDENTITIES = 'RESET_ALL_IDENTITIES';
