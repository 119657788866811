import * as _ from 'underscore';
import { GET_AGGREGATE, RESET_AGGREGATE } from '../action.types';

export interface AppModel {
  aggregates: {};
}

export interface AppStateAction {
  type: string;
  payload: {
    objects: any;
    companyId: string;
  };
}

const initialState: AppModel = {
  aggregates: {},
};

export function aggregatesReducer(state: AppModel = initialState, action: AppStateAction) {
  switch (action.type) {
    case GET_AGGREGATE:
      if (action.payload.objects) {
        if (state.aggregates[action.payload.companyId]) {
          state.aggregates[action.payload.companyId] = action.payload.objects;
          return state;
        }
        state.aggregates[action.payload.companyId] = action.payload.objects;
        return state;
      }
      return state;
    case RESET_AGGREGATE:
      return {
        aggregates: {},
      };
    default:
      return state;
  }
}
