import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import * as _ from 'underscore';

// import { AppModel } from '../gcv.reducer';
import { PUT_SALES_FILE, GET_ALL_SALES_FILES, RESET_SALES_FILES } from '../action.types';
import { DefaultService } from '@gcv/services';

@Injectable({
  providedIn: 'root',
})
export class SalesFileDataService {
  private salesStore;

  constructor(private sdk: DefaultService, private store: Store<any>) {
    this.salesStore = store.select('sales');
  }

  public subscribe(observer) {
    return this.salesStore.subscribe(observer);
  }

  public clearDomain() {
    this.salesStore.dispatch({
      type: RESET_SALES_FILES,
    });
  }

  // public create(
  //   keys: {
  //     id: string;
  //   },
  //   data: Object
  // ) {
  //   return new Promise((resolve, reject) => {
  //     this.sdk.dispensariesIdSalesfilesPost(keys.id, data).subscribe(
  //       salesFile => {
  //         const payload = {
  //           objects: salesFile,
  //           dispensaryId: salesFile.dispensary_id
  //         };

  //         this.salesStore.dispatch({
  //           type: PUT_SALES_FILE,
  //           payload: payload
  //         });
  //         resolve(salesFile);
  //       },
  //       err => reject(err)
  //     );
  //   });
  // }

  public readAll(keys: { id: string }) {
    return new Promise((resolve, reject) => {
      this.sdk.dispensariesIdSalesfilesGet(keys.id).subscribe(
        salesFiles => {
          const payload = {
            dispensaryId: keys.id,
            objects: salesFiles,
          };

          this.salesStore.dispatch({
            type: GET_ALL_SALES_FILES,
            payload,
          });
          resolve(salesFiles);
        },
        err => reject(err)
      );
    });
  }

  public checkForDuplicate(keys: { hash: string }) {
    return new Promise((resolve, reject) => {
      this.sdk.salesFileHashHashGet(keys.hash).subscribe(
        hash => {
          if (hash.data_hash) {
            resolve(true);
          } else {
            resolve(false);
          }
        },
        err => reject(err)
      );
    });
  }

  // public getProgress(keys: { hash: string }) {
  //   return new Promise((resolve, reject) => {
  //     this.sdk.salesFileProgressGet(keys.hash).subscribe(
  //       saleFile => {
  //         resolve(saleFile);
  //       },
  //       err => reject(err)
  //     );
  //   });
  // }
}
