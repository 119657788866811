import * as _ from 'underscore';
// import { ModelDeposit as Deposit } from '@gcv/shared';
import { POST_DEPOSIT, GET_ALL_DEPOSITS, RESET_DEPOSITS, PUT_DEPOSIT } from '../action.types';

export interface AppModel {
  deposits: {};
}

export interface AppStateAction {
  type: string;
  payload: {
    objects: any;
    dispensaryId: string;
  };
}

const initialState: AppModel = {
  deposits: {},
};

export function depositReducer(state: AppModel = initialState, action: AppStateAction) {
  switch (action.type) {
    case GET_ALL_DEPOSITS:
      if (action.payload.objects) {
        if (state.deposits[action.payload.dispensaryId]) {
          state.deposits[action.payload.dispensaryId] = action.payload.objects;
          return state;
        }
        state.deposits[action.payload.dispensaryId] = action.payload.objects;
        return state;
      }
      return state;
    case RESET_DEPOSITS:
      return {
        deposits: {},
      };
    case POST_DEPOSIT:
      const copyForPost = state.deposits;
      if (!copyForPost[action.payload.dispensaryId]) {
        copyForPost[action.payload.dispensaryId] = [];
      }
      copyForPost[action.payload.dispensaryId].push(action.payload.objects);
      return {
        deposits: copyForPost,
      };
    case PUT_DEPOSIT:
      const copy = state.deposits;
      copy[action.payload.dispensaryId] = copy[action.payload.dispensaryId].map(deposit => {
        if (deposit.deposit_id === action.payload.objects.deposit_id) {
          return action.payload.objects;
        }
        return deposit;
      });
      return {
        deposits: copy,
      };
    default:
      return state;
  }
}
