import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
})
export class TabGroupComponent implements OnInit {
  @Input() tabs: string[];
  @Input() styles: any;
  @Output() handleTabChanged = new EventEmitter();
  @Input() tabActive;
  constructor() {}

  ngOnInit() {}

  changeTab(tab) {
    this.tabActive = tab;
    this.handleTabChanged.emit(tab);
  }
}
