import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Router,
  Route,
  UrlSegment,
} from '@angular/router';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { LoggerService } from '../../services/logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(public router: Router, public logger: LoggerService, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.logger.debug('AuthGuard.canActivate');
    return this.authService
      .isUserLoggedIn()
      .then(isLoggedIn => {
        if (isLoggedIn) {
          this.logger.debug('AuthGuard.canActivate: true');
          return Promise.resolve(true);
        } else {
          this.logger.debug('AuthGuard.canActivate: false');
          this.router.navigate(['/login']);
          return Promise.resolve(false);
        }
      })
      .catch(() => {
        this.logger.debug('AuthGuard.canActivate: false');
        this.router.navigate(['/login']);
        return Promise.resolve(false);
      });
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return false;
  }
}
