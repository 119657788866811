import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { AmplifyService } from 'aws-amplify-angular';
import { SecureAppFamilyService } from '../../services/secure.service';
import { Dispensary } from '@gcv/shared';
import { BankAppFamilyService } from '../../services/bank.service';
import { DispensaryAppFamilyService } from '../../services/dispensary.service';
import { GcvAppFamilyService } from '../../services/gcvApp.service';
import { Router, ActivatedRoute, Event, NavigationEnd, NavigationExtras } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loading = new BehaviorSubject(false);
  private currentUserSubject: BehaviorSubject<{ [key: string]: any }>;
  public currentUser: Observable<{ [key: string]: any }>;
  private ITEM_KEY = 'currentUser';

  private REDIRECT_URL = 'redirect_url';
  private isLoggedSubject: BehaviorSubject<boolean>;
  public isLoggedIn: Observable<boolean>;
  // store the URL so we can redirect after logging in
  loggedIn = false;
  private redirectUrl: string;

  constructor(
    private amplifyService: AmplifyService,
    private secureService: SecureAppFamilyService,
    private bankApp: BankAppFamilyService,
    private dispApp: DispensaryAppFamilyService,
    private router: Router,
    private gcvApp: GcvAppFamilyService
  ) {
    this.isLoggedSubject = new BehaviorSubject(false);
    this.currentUserSubject = new BehaviorSubject<{ [key: string]: any }>(
      JSON.parse(localStorage.getItem(this.ITEM_KEY))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.isLoggedIn = this.isLoggedSubject.asObservable();
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url.search('/login') === -1 &&
          event.urlAfterRedirects.search('/login') === -1 &&
          event.url.search('/user/registration') === -1
        ) {
          this.setRedirectUrl(event.url);
        }
      }
    });
  }

  public get currentUserValue(): { [key: string]: any } {
    return this.currentUserSubject.value;
  }

  public get isLoggedInValue() {
    return this.isLoggedSubject.value;
  }

  public set loggin(val) {
    this.loggedIn = val;
  }

  public get loggin() {
    return this.loggedIn;
  }

  public initLogin(authUser, changePassword, authGuard = false) {
    return new Promise((res, reject) => {
      this.secureService.init(authUser.username).then((user: any) => {
        if (changePassword) {
          this.initChangePassword(user);
        } else {
          this.determineCompany(authGuard).then(() => {
            res();
          });
        }
      });
    });
  }

  private setRedirectUrl(url) {
    // const parsedUrl = url.split('?');
    localStorage.setItem(this.REDIRECT_URL, encodeURI(url));
  }

  initChangePassword(user) {
    this.loading.next(false);
    this.amplifyService.setAuthState({ state: 'requireNewPassword', user });
  }

  getRedirectUrl() {
    return localStorage.getItem(this.REDIRECT_URL);
  }

  determineCompany(authGuard = false) {
    return new Promise((res, reject) => {
      const company = this.secureService.getCompany();
      this.redirectUrl = this.getRedirectUrl();
      if (company) {
        switch (company.companyType) {
          case 'bank':
            this.initBank(authGuard).then(() => res());
            break;
          case 'dispensary':
            this.initDispensary(authGuard).then(() => res());
            break;
          case 'gcv':
            this.initGcv(authGuard).then(() => res());
            break;
          default:
            this.loading.next(false);
            break;
        }
      } else {
        this.loading.next(false);
      }
    });
  }

  initBank(authGuard = false) {
    return new Promise((resolve, reject) => {
      this.bankApp.loadBank().then(bank => {
        if (bank.onboarding && bank.onboarding.complete) {
          this.bankApp.initState();
          this.bankApp.getAllDataLoadedStream().subscribe(loaded => {
            if (loaded) {
              this.handleAuthGuard(authGuard, '/secure/bank/dashboard/overview').then(() => resolve());
            }
          });
        } else {
          this.handleAuthGuard(authGuard, '/secure/onboarding').then(() => resolve());
        }
      });
    });
  }

  handleNavigation(route) {
    if (route.split('?').length > 1) {
      const { url, data } = this.handleReconstructionOfParams(route);
      this.router
        .navigate([url], data)
        .then(d => this.loading.next(false))
        .catch(err => this.loading.next(false));
    } else {
      this.router
        .navigate([route])
        .then(d => this.loading.next(false))
        .catch(err => this.loading.next(false));
    }
  }

  handleReconstructionOfParams(route) {
    const splitUrl = route.split('?');
    const queryData = splitUrl[1].split('=');
    if (queryData.length === 2) {
      const data: NavigationExtras = {
        queryParams: {},
      };
      data.queryParams[queryData[0]] = queryData[1];
      return { url: splitUrl[0], data };
    }
    return {};
  }
  initDispensary(authGuard = false) {
    return new Promise((resolve, reject) => {
      this.dispApp.loadDispensary().then((disp: Dispensary) => {
        if (disp.onBoardingComplete) {
          this.dispApp.initState();
          this.dispApp.getAllDataLoadedStream().subscribe(loaded => {
            if (loaded) {
              // change to use docchecker
              if (disp && disp.dueDiligenceStatus === 'submitted') {
                this.handleAuthGuard(authGuard, '/secure/dispensary/dashboard/overview').then(() => resolve());
              } else {
                this.handleAuthGuard(authGuard, '/secure/dispensary/dashboard/overview-onboarding').then(() =>
                  resolve()
                );
              }
            }
          });
        } else {
          this.handleAuthGuard(authGuard, '/secure/registration').then(() => resolve());
        }
      });
    });
  }

  handleAuthGuard(authGuard, route) {
    return new Promise((resolve, reject) => {
      if (!authGuard) {
        this.router.navigate([route]).then(() => {
          this.loading.next(false);
          resolve();
        });
      } else {
        this.loading.next(false);
        resolve();
      }
    });
  }

  initGcv(authGuard = false) {
    return new Promise((resolve, reject) => {
      this.gcvApp.initState();
      // FIX ME THIS IS BAD AF... Basically need to do the data exchanges better temp fix.
      setTimeout(() => {
        this.handleAuthGuard(authGuard, '/secure/gcv/dashboard').then(() => resolve());
      }, 5000);
    });
  }

  login(user: { [key: string]: any }): Observable<boolean> {
    return of(true).pipe(
      delay(1000),
      tap(val => {
        this.isLoggedSubject.next(val);
        localStorage.setItem(this.ITEM_KEY, JSON.stringify(user));
        this.currentUserSubject.next(user);
      })
    );
  }

  logout(): void {
    this.isLoggedSubject.next(false);
    localStorage.removeItem(this.ITEM_KEY);
    localStorage.removeItem(this.REDIRECT_URL);
    this.amplifyService.auth().signOut();
    this.loggin = false;
    this.amplifyService.setAuthState({ state: 'signedOut', user: null });
    this.loading.next(false);
    this.currentUserSubject.next(null);
  }

  isUserLoggedIn() {
    return new Promise((resolve, reject) => {
      this.amplifyService
        .auth()
        .currentAuthenticatedUser()
        .then(user => {
          if (user) {
            this.initLogin(user, false, true).then(() => resolve(true));
          } else {
            resolve(false);
          }
        })
        .catch(() => resolve(false));
    });
  }
}
