import { Component } from '@angular/core';
import { versions } from '../../../environments/versions';

@Component({
  selector: 'app-dashboard',
  templateUrl: './gcv-dashboard.component.html',
})
export class GcvDashboardComponent {
  public injectedVersions;
  constructor() {
    this.injectedVersions = versions;
  }
}
