import * as _ from 'underscore';

import { GET_ALL_REPORTS, PUT_REPORTS, RESET_REPORTS, POST_REPORT } from '../action.types';

export interface AppModel {
  reports: {};
}

export interface AppStateAction {
  type: string;
  payload: {
    objects: any;
    dispensaryId: string;
  };
}

const initialState: AppModel = {
  reports: {},
};

export function fincenReducer(state: AppModel = initialState, action: AppStateAction) {
  let copy;
  switch (action.type) {
    case GET_ALL_REPORTS:
      if (action.payload.objects) {
        if (state.reports[action.payload.dispensaryId]) {
          state.reports[action.payload.dispensaryId] = action.payload.objects;
          return state;
        }
        state.reports[action.payload.dispensaryId] = action.payload.objects;
        return state;
      }
      return state;
    case PUT_REPORTS:
      copy = state.reports;
      copy[action.payload.dispensaryId] = copy[action.payload.dispensaryId].map(report => {
        if (report.id === action.payload.objects.id) {
          return action.payload.objects;
        }
        return report;
      });
      return {
        reports: copy,
      };
    case POST_REPORT:
      copy = state.reports;
      copy[action.payload.dispensaryId].push(action.payload.objects);
      return {
        reports: copy,
      };
    case RESET_REPORTS:
      return {
        reports: {},
      };
    default:
      return state;
  }
}
