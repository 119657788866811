import { Injectable } from '@angular/core';

declare const Modernizr: any;

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private inMemorySessionMap: object;

  constructor() {
    this.inMemorySessionMap = {};
  }

  clearAllStorage() {
    if (Modernizr.sessionstorage) {
      sessionStorage.clear();
    }
    if (Modernizr.localstorage) {
      localStorage.clear();
    }
    this.inMemorySessionMap = {};
  }

  // uses the browsers session storage if available
  getSessionProperty(key: string): string {
    if (Modernizr.sessionstorage) {
      return sessionStorage.getItem(key);
    } else {
      return this.inMemorySessionMap[key]; // fall back to the inMemorySessionMap when browser storage isn't avaialble
    }
  }

  // uses the browsers session storage if available
  setSessionProperty(key: string, value: string): void {
    if (Modernizr.sessionstorage) {
      sessionStorage.setItem(key, value);
    } else {
      this.inMemorySessionMap[key] = value; // fall back to the inMemorySessionMap when browser storage isn't avaialble
    }
  }

  // uses the browsers session storage if available
  removeSessionProperty(key: string): void {
    if (Modernizr.sessionstorage) {
      sessionStorage.removeItem(key);
    } else {
      delete this.inMemorySessionMap[key]; // fall back to the inMemorySessionMap when browser storage isn't avaialble
    }
  }

  // uses the browsers localStorage if available to store data with no expiry and life beyond its initial browser tab
  getPermanentProperty(key: string): string {
    if (Modernizr.localstorage) {
      return localStorage.getItem(key);
    } else {
      return this.inMemorySessionMap[key]; // fall back to the inMemorySessionMap when browser storage isn't avaialble
    }
  }

  // uses the browsers localStorage if available to store data with no expiry and life beyond its initial browser tab
  setPermanentProperty(key: string, value: string): void {
    if (Modernizr.localstorage) {
      localStorage.setItem(key, value);
    } else {
      this.inMemorySessionMap[key] = value; // fall back to the inMemorySessionMap when browser storage isn't avaialble
    }
  }

  // uses the browsers localStorage if available to store data with no expiry and life beyond its initial browser tab
  removePermanentProperty(key: string): void {
    if (Modernizr.localstorage) {
      localStorage.removeItem(key);
    } else {
      delete this.inMemorySessionMap[key]; // fall back to the inMemorySessionMap when browser storage isn't avaialble
    }
  }
}
