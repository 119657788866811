import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent implements OnInit {
  @Input() notification: any;
  @Output() notificationClicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  goToNotification() {
    this.notificationClicked.emit(this.notification);
  }
}
