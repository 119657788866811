import { Component } from '@angular/core';
import { Route, ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-new-password',
  templateUrl: 'new.password.component.html',
  styleUrls: ['new.password.component.scss'],
})
export class NewPasswordComponent {
  errorMessage: any;
  loading = false;
  password = '';
  email = '';
  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.queryParams.subscribe(params => {
      if (params['password'] && params['email']) {
        this.password = decodeURI(params['password']);
        this.email = decodeURI(params['email']);
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  setErrorMessage(err) {
    this.errorMessage = err;
  }

  isLoading(loading) {
    this.loading = loading;
  }

  passwordChanged(user) {
    this.router.navigate(['/login']);
  }

  onAlertClose() {
    this.errorMessage = undefined;
  }
}
