import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource, Sort } from '@angular/material';

import * as _ from 'underscore';
import { GcvAppFamilyService } from '../../../../services/gcvApp.service';
import { S3FileManagementService } from '../../../../services/s3FileManagement.service';
import { PdfViewDialogComponent } from '../../common/pdfViewDialog.component';
import { ConfirmDialogComponent } from '../../common/confirmDialog.component';
import { DispensaryDataService } from '../../../../services/actions/dispensary.action';
import { DocumentChecker, Dispensary } from '@gcv/shared';
import { UserDataService } from '../../../../services/actions/user.action';
import { LoggerService } from '../../../../services/logger/logger.service';

@Component({
  selector: 'app-dispensary-review',
  templateUrl: './gcv-dispensary-review.component.html',
  styleUrls: ['./gcv-dispensary-review.component.scss'],
})
export class GcvDispensaryReviewComponent implements OnInit {
  displayedColumns = [
    'name',
    'streetAddress',
    'city',
    'state',
    'postalCode',
    'cannabisLicenseNumber',
    'cannabisLicenseType',
    'identificationCard',
    'status',
    'documents',
  ];

  dispensaries: any;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  dispensariesTableRows: MatTableDataSource<any> = null;
  documents = [
    { value: 'articlesDoc', viewValue: 'Articles of Incorporation' },
    { value: 'taxDoc', viewValue: 'Tax Documentation' },
    { value: 'policyDoc', viewValue: 'Policy Documents' },
    { value: 'leaseDoc', viewValue: 'Lease Document' },
    { value: 'balanceDoc', viewValue: 'Balance Document' },
    { value: 'cannabisLicenseCopy', viewValue: 'Cannabis License' },
    {
      value: 'certificateOfGoodStanding',
      viewValue: 'Certificate of Good Standing',
    },
    { value: 'incomeDoc', viewValue: 'Income Document' },
    { value: 'beneficialOwnerDoc', viewValue: 'Beneficial Owner Document' },
  ];
  constructor(
    public dialog: MatDialog,
    private gcvApp: GcvAppFamilyService,
    private dispensaryApp: DispensaryDataService,
    private userApp: UserDataService,
    private s3FileManagement: S3FileManagementService,
    private logger: LoggerService
  ) {
    this.dispensaries = [];
  }

  ngOnInit() {
    this.gcvApp.dispensaries$.subscribe(() => {
      const dispensaries = this.gcvApp.getDispensaries();
      if (!_.isEmpty(dispensaries)) {
        const sortedDispensaries = dispensaries.sort((a, b) => {
          return a['name'] > b['name'] ? 1 : -1; // ascending sort by name
        });
        this.updateTable(sortedDispensaries);
      }
    });
  }

  viewDocument(document) {
    if (document) {
      this.showPdf(document.s3Key, document);
    }
  }

  isBankReviewed(e: Dispensary) {
    return DocumentChecker.isBankReviewed(e, 'default');
  }

  isGcvReviewed(e: Dispensary) {
    return DocumentChecker.isGcvReviewed(e);
  }

  isDispensaryReviewed(e) {
    return DocumentChecker.isDispensaryReviewed(e, 'default');
  }

  documentsUploaded(e) {
    if (e.documents) {
      return DocumentChecker.areDocumentsUploaded(e);
    } else {
      return false;
    }
  }

  updateTable(sortedDispensaries) {
    this.dispensariesTableRows = new MatTableDataSource<any>(sortedDispensaries);

    this.dispensariesTableRows.paginator = this.paginator;
  }

  gcvApproveReview(dispensary: any) {
    if (!this.documentsUploaded(dispensary) && this.isGcvReviewed(dispensary)) {
      throw new Error('cannot do GCV review on dispensary');
    }
    // const dialogRef = this.dialog.open(InProgressDialogComponent, {
    //   data: {
    //     message: 'Documenting GCV approval ...',
    //   },
    // });
    this.gcvApp.gcvApproveReview(dispensary, (err, value) => {
      // dialogRef.close();
    });
  }

  async showPdf(s3Key: string, doc) {
    const fullS3KeyUrl = await this.s3FileManagement.get(s3Key);
    const data = {
      s3KeyUrl: fullS3KeyUrl,
    };
    const dialogRef = this.dialog.open(PdfViewDialogComponent, {
      width: '1000px',
      height: '700px',
      data,
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  async viewId(id) {
    const fullS3KeyUrl = await this.s3FileManagement.get(id.s3Key);
    const data = {
      s3KeyUrl: fullS3KeyUrl,
      type: 'image/jpeg',
    };
    const dialogRef = this.dialog.open(PdfViewDialogComponent, {
      width: '1000px',
      height: '700px',
      data,
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  sortData(sort: Sort) {
    // TODO is it possible to sort the existing MatTableDataSource?
    const dispensaries: any[] = this.gcvApp.getDispensaries();
    const sortedDispensaries = dispensaries.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'streetAddress':
          return this.compare(a.streetAddress, b.streetAddress, isAsc);
        case 'city':
          return this.compare(a.city, b.city, isAsc);
        case 'state':
          return this.compare(a.state, b.state, isAsc);
        case 'postalCode':
          return this.compare(a.postalCode, b.postalCode, isAsc);
        default:
          return 0;
      }
    });
    this.updateTable(sortedDispensaries);
  }

  manuallyVerify(dispensary) {
    const verificationDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'Are you sure you want to override the verification of this user?',
      },
    });
    verificationDialog.afterClosed().subscribe(data => {
      if (data && data.confirmed) {
        this.logger.log(data);
        dispensary.primaryContact['verified'] = true;
        this.dispensaryApp
          .update({ id: dispensary.id }, dispensary)
          .then(disp => {
            this.logger.log(disp);
            this.userApp
              .read({ id: dispensary.primaryContact.userId })
              .then((user: any) => {
                this.logger.log(user);
                this.userApp
                  .update({ id: user.id }, { ...user, ...{ verified: true } })
                  .then(() => {})
                  .catch(err => this.logger.error(err));
              })
              .catch(err => this.logger.error(err));
          })
          .catch(err => this.logger.error(err));
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
