import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as _ from 'underscore';

@Component({
  selector: 'app-editable-card-hours',
  templateUrl: './editable-card-hours.component.html',
  styleUrls: ['./editable-card-hours.component.scss'],
})
export class EditableCardHoursComponent implements OnInit {
  activeButton = {
    'background-color': '#00BC66',
    'font-size': '14px',
    width: '6rem',
    height: '2rem',
    'border-radius': '75px',
    'font-weight': '400',
  };
  inactiveButton = {
    'background-color': '#FFFFFF',
    border: '1px solid #DBE0EB',
    'font-size': '14px',
    width: '6rem',
    height: '2rem',
    color: '#A5A8BA',
    'border-radius': '75px',
    'font-weight': '400',
  };
  editable = false;
  @Output() saveContent = new EventEmitter();
  @Input() customStyle: any;
  @Input() data: FormGroup;
  @Input() title: string;
  @Input() formKey: any;
  @Input() customStyleEdit: any;
  tempData: any;

  constructor() {}

  ngOnInit() {
    this.resetFormGroup();
  }

  edit() {
    this.editable = true;
  }

  cancel() {
    this.editable = false;
    this.resetFormGroup();
  }

  resetFormGroup() {
    const data = this.data.getRawValue();
    const controls = {};
    for (const key in data) {
      if (key) {
        controls[key] = new FormGroup({
          isOpen: new FormControl(data[key] && data[key].isOpen ? data[key].isOpen : false),
          openHour: new FormControl(data[key] && data[key].openHour ? data[key].openHour : '09:30'),
          closeHour: new FormControl(data[key] && data[key].closeHour ? data[key].closeHour : '17:30'),
        });
      }
    }
    this.tempData = new FormGroup(controls);
  }

  save() {
    for (const key in this.tempData) {
      if (key) {
        this.data = this.tempData;
      }
    }
    const data = { businessHours: this.data.getRawValue() };
    this.saveContent.emit(data);
    this.editable = false;
  }

  handleInput(e) {
    this.tempData.controls[e.property].setValue(e.value);
  }

  selectionChanged(e, property) {
    this.tempData[property] = e.value[0];
  }

  isDisabled() {
    return !this.data.getRawValue();
  }

  // need to convert to iso?
  timeInputHandled(e: any, property: any) {
    this.tempData.controls[property].controls[e.property].setValue(e.value);
  }

  toggleDay(dayName) {
    this.tempData.controls[dayName].controls['isOpen'].setValue(
      !this.tempData.controls[dayName].controls['isOpen'].value
    );
  }
}
