import { ConfirmDialogComponent } from './../../common/confirmDialog.component';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { UserDataService } from 'src/services/actions/user.action';
import { LoggerService } from 'src/services/logger/logger.service';

@Component({
  selector: 'app-users-edit-dialog-component',
  templateUrl: './gcv-users-edit.component.html',
  styleUrls: ['./gcv-users-edit.component.scss'],
})
export class GcvUsersEditDialogComponent {
  user;
  banks;
  dispensaries;
  selectedType = '';
  selectedOrgs: any[] = [];
  selectedOrganization: any[] = [];
  shouldDisableUser = false;
  altOrg = [];
  constructor(
    public dialogRef: MatDialogRef<GcvUsersEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userDataService: UserDataService,
    private logger: LoggerService,
    private matDialog: MatDialog
  ) {
    this.user = data.user;
    this.banks = data.banks;
    this.dispensaries = data.dispensaries;
    const filteredOrgs = [];
    this.user.companies.forEach(company => {
      if (company.companyType === 'bank') {
        return data.banks.forEach(bank => {
          if (bank.id === company.id) {
            filteredOrgs.push(bank);
          }
        });
      } else if (company.companyType === 'dispensary') {
        return data.dispensaries.forEach(disp => {
          if (disp.id === company.id) {
            filteredOrgs.push(disp);
          }
        });
      } else {
        this.altOrg.push(company);
      }
    });
    this.selectedOrgs = filteredOrgs;
  }

  close() {
    this.dialogRef.close();
  }

  setSelectedOrganization() {
    if (this.selectedType === 'dispensary') {
      this.selectedOrganization = this.data.dispensaries;
      return;
    }
    this.selectedOrganization = this.data.banks;
    return;
  }

  selectionChanged({ value }) {
    const checkedSelectedOrgs = this.selectedOrgs.filter(org => org.id === value.id);
    if (checkedSelectedOrgs.length === 0) {
      this.selectedOrgs.push(value);
    }
    return;
  }

  disableUser() {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: { message: 'Are You Sure You Want To Disable This User?' },
    });
    dialog.afterClosed().subscribe(({ confirmed }) => {
      if (confirmed) {
        const data = {
          ...this.user,
          disabled: true,
          companies: [],
          primaryContact: false,
        };
        this.handleSaveUser(data);
      }
    });
  }

  removeOrg(org) {
    this.selectedOrgs = this.selectedOrgs.filter(selectedOrg => selectedOrg.id !== org.id);
  }

  saveUser() {
    const updatedOrgs = this.determineUserCompanies();
    const updatedUser = {
      ...this.user,
      companies: [...updatedOrgs, ...this.altOrg],
    };
    this.handleSaveUser(updatedUser);
  }

  handleSaveUser(data) {
    this.userDataService
      .update({ id: data.id }, data)
      .then(() => this.close())
      .catch(err => {
        this.logger.error(err);
      });
    this.close();
  }

  determineUserCompanies() {
    return this.selectedOrgs.map(org => {
      if (org.hasOwnProperty('name')) {
        return {
          companyType: 'dispensary',
          id: org.id,
        };
      }
      return {
        companyType: 'bank',
        id: org.id,
      };
    });
  }

  determineOrgName(org) {
    if (org.hasOwnProperty('name')) {
      return org.name;
    }
    return org.orgName;
  }

  resendVerificationLink() {
    // FIXME used logging service instead of console.log(this.user);
    this.userDataService.resendVerificationCode(this.user.email);
  }
}
