import { Component, OnInit, HostListener } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { interval } from 'rxjs';
import { throttle } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';
import { Router, NavigationExtras } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-cognito',
  templateUrl: './cognito.component.html',
  styleUrls: ['./cognito.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('400ms ease-in', style({ transform: 'translateX(0%)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ position: 'absolute' }),
        animate('200ms ease-in', style({ transform: 'translateX(200%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class CognitoComponent implements OnInit {
  isEnvironmentProduction: boolean = environment.production;
  signedIn: boolean;
  changePass = false;
  user: any;
  loading = false;
  authState = { state: 'signIn', user: null };
  constructor(private amplifyService: AmplifyService, private authService: AuthService) {}

  ngOnInit() {
    this.amplifyService.authStateChange$.pipe(throttle(() => interval(500))).subscribe(authState => {
      this.authState = authState;
      if (authState.state === 'signedIn' && !this.authService.loggin) {
        this.user = authState.user;
        this.authService.loggin = true;
        this.loading = true;
        this.authService.initLogin(this.user, this.changePass).then(() => {});
      }
    });

    this.authService.loading.subscribe(val => (this.loading = val));
  }

  signingIn(e) {
    this.loading = e;
  }

  shouldChangePass(e) {
    this.changePass = e;
  }
}
