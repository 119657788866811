import * as _ from 'underscore';

import { RESET_IDENTITIES, GET_ALL_IDENTITIES } from '../action.types';

export interface AppModel {
  identities: any[];
}

export interface AppStateAction {
  type: string;
  payload: any[];
}

const initialState: AppModel = {
  identities: [],
};

export function identitityReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_IDENTITIES:
      return {
        identities: action.payload,
      };
    case RESET_IDENTITIES:
      return {
        identities: [],
      };
    default:
      return state;
  }
}
