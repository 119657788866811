import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-mat-input',
  templateUrl: './mat-input.component.html',
  styleUrls: ['./mat-input.component.scss'],
})
export class MatInputComponent implements OnInit {
  @Input() type: string;
  @Input() label: string;
  @Input() prefix = false;
  @Input() required = false;
  @Input() prefixValue = '';
  @Input() suffix = false;
  @Input() suffixValue = '';
  @Input() styles: string;
  @Input() property: string;
  @Input() autoFill = 'thisIsNotAAutoFill';
  @Input() control: string;
  @Input() group: FormGroup;
  @Input() disabled = false;
  @Input() validators: any[];
  @Input() errors: any;
  @Input() inputValue = '';
  @Output() value = new EventEmitter();
  @Output() blurCalled = new EventEmitter();
  @Output() focusCalled = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onInputChange(e) {
    this.value.emit({ value: e.srcElement.value, property: this.property });
  }

  onBlur() {
    this.blurCalled.emit();
  }

  onFocus() {
    this.focusCalled.emit();
  }

  getErrorMessage() {
    let msg = '';

    for (const error of this.validators) {
      if (this.group.controls[this.control].hasError(error.type)) {
        msg = error.message;
      }
    }

    return msg;
  }
}
