import { Storage } from 'aws-amplify';
import { Injectable } from '@angular/core';
import { Config } from './config';
import { LoggerService } from './logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class S3FileManagementService {
  constructor(private config: Config, private logger: LoggerService) {
    this.setupBucket();
  }

  setupBucket() {
    Storage.configure({
      bucket: this.config.storageConfig.document,
      region: 'us-east-1',
    });
  }

  async get(key: string) {
    try {
      return await Storage.get(key);
    } catch (err) {
      this.logger.error(err);
    }
  }

  async getRegulatoryGuide(key: string) {
    try {
      return await Storage.get(key, {
        level: 'public',
        contentType: 'text/plain',
        bucket: this.config.storageConfig.regulatoryGuide,
      });
    } catch (err) {
      this.logger.error(err);
    }
  }

  async putRegulatoryGuide(key: string, data) {
    return await Storage.vault.put(key, data, {
      level: 'public',
      contentType: 'text/plain',
      bucket: this.config.storageConfig.regulatoryGuide,
    });
  }

  putSales(key, data) {
    Storage.vault
      .put(key, data, {
        level: 'public',
        contentType: 'text/plain',
        bucket: this.config.storageConfig.salesFile,
      })
      .then(() => {})
      .catch(err => this.logger.error(err));
  }

  putDocuments(key, data) {
    Storage.put(key, data, {
      level: 'public',
      contentType: 'image/jepg',
    })
      .then(() => {})
      .catch(err => this.logger.error(err));
  }
}
