import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { LoggerService } from 'src/services/logger/logger.service';
import { states } from 'src/app/constants';
import { BankDataService } from '../../../services/actions/bank.action';
import { DispensaryDataService } from '../../../services/actions/dispensary.action';
@Component({
  selector: 'app-create-organization-dialog',
  templateUrl: './gcv-create-organization-dialog.html',
  styleUrls: ['./gcv-create-organization-dialog.scss'],
})
export class GcvCreateOrganizationDialogComponent {
  selectedType: string;
  name = '';
  address = '';
  city = '';
  zipcode = '';
  state = '';
  establishedDate = '';
  states = states;
  dispensaries = [];
  selectedDispensaries = [];
  constructor(
    public dialogRef: MatDialogRef<GcvCreateOrganizationDialogComponent>,
    private bankService: BankDataService,
    private logger: LoggerService,
    public dialog: MatDialog,
    private dispensaryService: DispensaryDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dispensaries = data.dispensaries;
  }

  close() {
    this.dialogRef.close();
  }

  saveUser() {
    let data;
    if (this.selectedType === 'bank') {
      data = {
        orgName: this.name,
        address: this.address,
        city: this.city,
        state: this.state,
        postalCode: this.zipcode,
        dispensaries: this.formatSelectedOrgs(),
      };

      this.createBank(data);
    } else {
      data = {
        name: this.name,
        streetAddress: this.address,
        city: this.city,
        state: this.state,
        postalCode: this.zipcode,
        establishedDate: new Date(this.establishedDate).toISOString(),
      };
      this.createDispensary(data);
    }
  }

  formatSelectedOrgs() {
    const data = [];
    this.selectedDispensaries.forEach(disp => {
      data.push(disp.id);
    });
    return data;
  }

  resetForm() {
    this.name = '';
    this.selectedType = '';
    this.selectedDispensaries = [];
  }

  selectionChanged({ value }) {
    const checkedSelectedOrgs = this.selectedDispensaries.filter(org => org.id === value.id);
    if (checkedSelectedOrgs.length === 0) {
      this.selectedDispensaries.push(value);
    }
    return;
  }

  removeOrg(org) {
    this.selectedDispensaries = this.selectedDispensaries.filter(selectedOrg => selectedOrg.id !== org.id);
  }

  createBank(data) {
    // FIXME used logging service instead of console.log(data);
    // const dialogRef = this.dialog.open(InProgressDialogComponent, {
    //   data: {
    //     message: 'Saving changes ...',
    //   },
    // });
    this.bankService
      .post(data)
      .then(newBank => {
        // dialogRef.close();
        this.dialogRef.close();
      })
      .catch(err => this.logger.error(err));
  }

  createDispensary(data) {
    // FIXME used logging service instead of console.log(data);
    // const dialogRef = this.dialog.open(InProgressDialogComponent, {
    //   data: {
    //     message: 'Saving changes ...',
    //   },
    // });
    this.dispensaryService
      .post(data)
      .then(newDisp => {
        // dialogRef.close();
        this.dialogRef.close();
      })
      .catch(err => this.logger.error(err));
  }

  formFilled() {
    return this.name.length > 0 && this.state.length > 0 && this.selectedType.length > 0;
  }
}
