import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { GcvAppFamilyService } from '../../../../services/gcvApp.service';
import { Bank } from '@gcv/shared';
import { UtilsService } from '../../../../services/utils.service';
import { BankDataService } from '../../../../services/actions/bank.action';
import { MatSnackBar } from '@angular/material';
import { Location } from '@angular/common';

@Component({
  selector: 'app-gcv-view-bank',
  styleUrls: ['./gcv-view-bank.component.scss'],
  templateUrl: './gcv-view-bank.component.html',
})
export class GcvViewBankComponent {
  step = 0;
  bankName = '';
  bank: Bank;
  bankData: FormGroup;
  dispensaries = [];
  constructor(
    private route: ActivatedRoute,
    private gcvApp: GcvAppFamilyService,
    private utils: UtilsService,
    private bankApp: BankDataService,
    private snackBar: MatSnackBar,
    private location: Location
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['data']) {
        const dispensaryMap = {};
        this.gcvApp.getDispensaries().forEach(disp => {
          dispensaryMap[disp.id] = disp;
          this.dispensaries.push({
            value: disp.id,
            viewValue: disp.name,
          });
          return;
        });
        this.bank = JSON.parse(params['data']);
        this.bankData = new FormGroup({
          dispensaries: new FormControl(this.bank.dispensaries),
        });
      }
    });
  }

  save() {
    const data = { ...this.bank, ...this.utils.filterOutEmptyProperties(this.bankData.getRawValue()) };
    this.bankApp.update({ id: this.bank.id }, data).then(() => {
      this.snackBar.open(`${this.bank.orgName} has been updated`, '', {
        duration: 3000,
      });
      this.location.back();
    });
  }

  cancel() {
    this.location.back();
  }
}
