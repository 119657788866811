import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dispensary-due-diligence',
  templateUrl: './dispensary-due-diligence.component.html',
  styleUrls: ['./dispensary-due-diligence.component.scss'],
})
export class DispensaryDueDiligenceComponent implements OnInit {
  @Input() title: string;
  @Input() break = false;
  @Input() subTitle: string;
  @Input() buttonText: string;
  @Input() percentage: string;
  @Input() icon: string;
  @Output() handleButtonPressed = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  buttonPressed() {
    this.handleButtonPressed.emit();
  }
}
