import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  height: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.height = window.outerHeight;
  }
  constructor() {
    this.height = window.outerHeight;
  }

  ngOnInit() {}
}
