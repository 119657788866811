import { ZeroStateComponent } from './zero-state/zero-state.component';
import { NgModule } from '@angular/core';
import { ButtonComponent } from './button/button.component';
import { FileButtonComponent } from './button/file-button.component';
import { CardComponent } from './card/card.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CollapsingShelfComponent } from './collapsing-shelf/collapsing-shelf.component';
import { DispensaryDueDiligenceComponent } from './dispensary-due-diligence/dispensary-due-diligence.component';
import { FooterComponent } from './footer/footer.component';
import { InitialDashboardComponent } from './initial-dashboard/initial-dashboard.component';
import { InputComponent } from './input/input.component';
import { SpinnerIconComponent } from './spinner-icon/spinner-icon.component';
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SupportCardComponent } from './support-card/support-card.component';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { UploadButtonComponent } from './upload-button/upload-button.component';
import { ViewUploadedFileComponent } from './view-uploaded-file/view-uploaded-file.component';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { FormatDynamicTitlePipe } from '../pipes/format-dynamic-title.pipe';
import { DueDiligenceWrapperComponent } from './due-diligence-wrapper/due-diligence-wrapper.component';
import { ModalComponent } from './modal/modal.component';
import { PaginationControlComponent } from './pagination-control/pagination-control.component';
import { NgxPaginationModule } from 'ngx-pagination';

import { MatInputComponent } from './material-input/mat-input.component';
import { DataTableComponent } from './data-table/data-table.component';
import { EditableCardComponent } from './editable-card/editable-card.component';
import { EditableCardHoursComponent } from './editable-card-hours/editable-card-hours.component';
import { NavigationShellComponent } from './navigation-shell/navigation-shell.component';
import { ForgotPasswordComponent } from './forgot-password-component/forgot-password.component.core';
import { RequireNewPasswordComponent } from './require-new-password-component/require-new-password.component.core';
import { DispensaryAdminVerificationComponent } from '../app/dispensary/registration/dispensary-admin-verification/dispensary-admin-verification';
import { CreateUserDialogComponent } from './create-user-dialog/create-user-dialog.component';
import { SalesSideDrawerComponent } from './sales-side-drawer/sales-side-drawer.component';
import { GcvVendorModule } from '../shared/vendor.module';
import { TimefilterComponent } from './timefilter/timefilter.component';
import { GcvMaterialCommonModule } from './material.module';
import { CognitoComponent } from '../app/cognito/cognito.component';
import { TimeoutComponent } from './timeout/timeout.component';
import { NgIdleModule } from '@ng-idle/core';
import { EditUserComponent } from './edit-user/edit-user-dialog.component';
import { ViewAccountComponent } from '../app/bank/fincen/view-report/view-account/view-account.component';
import { CalculateReportDaysRemaining } from '../pipes/calculateReportDaysRemaining.pipe';
import { TimeFormatterPipe } from '../pipes/time-formatter.pipe';
import { SignInComponent } from './sign-in-component/sign-in.component.core';
import { ReviewAccountDocumentComponent } from '../app/bank/accounts/review-account-document/review-account-document.component';

@NgModule({
  imports: [GcvVendorModule, NgxPaginationModule, GcvMaterialCommonModule, NgIdleModule.forRoot()],
  declarations: [
    PaginationControlComponent,
    ButtonComponent,
    TimeoutComponent,
    FileButtonComponent,
    CardComponent,
    ChangePasswordComponent,
    CheckboxComponent,
    CollapsingShelfComponent,
    DispensaryDueDiligenceComponent,
    DueDiligenceWrapperComponent,
    FooterComponent,
    InitialDashboardComponent,
    InputComponent,
    EditUserComponent,
    TimefilterComponent,
    ModalComponent,
    SpinnerIconComponent,
    NotificationCardComponent,
    SpinnerComponent,
    ReviewAccountDocumentComponent,
    SupportCardComponent,
    ProfileCardComponent,
    TabGroupComponent,
    UploadButtonComponent,
    ViewUploadedFileComponent,
    TruncatePipe,
    FormatDynamicTitlePipe,
    CalculateReportDaysRemaining,
    ModalComponent,
    ZeroStateComponent,
    TimeFormatterPipe,
    MatInputComponent,
    DataTableComponent,
    EditableCardComponent,
    EditableCardHoursComponent,
    NavigationShellComponent,
    CreateUserDialogComponent,
    DispensaryAdminVerificationComponent,
    RequireNewPasswordComponent,
    ViewAccountComponent,
    ForgotPasswordComponent,
    SignInComponent,
    CognitoComponent,
    SalesSideDrawerComponent,
    ReviewAccountDocumentComponent,
    ViewAccountComponent,
  ],
  exports: [
    PaginationControlComponent,
    ButtonComponent,
    FileButtonComponent,
    CardComponent,
    CognitoComponent,
    EditUserComponent,
    ChangePasswordComponent,
    TimeoutComponent,
    CheckboxComponent,
    CollapsingShelfComponent,
    DispensaryDueDiligenceComponent,
    DueDiligenceWrapperComponent,
    FooterComponent,
    InitialDashboardComponent,
    InputComponent,
    TimefilterComponent,
    ModalComponent,
    SpinnerIconComponent,
    NotificationCardComponent,
    SpinnerComponent,
    SupportCardComponent,
    ProfileCardComponent,
    TabGroupComponent,
    UploadButtonComponent,
    ViewUploadedFileComponent,
    TruncatePipe,
    FormatDynamicTitlePipe,
    CalculateReportDaysRemaining,
    ModalComponent,
    ZeroStateComponent,
    TimeFormatterPipe,
    MatInputComponent,
    DataTableComponent,
    EditableCardComponent,
    EditableCardHoursComponent,
    NavigationShellComponent,
    CreateUserDialogComponent,
    DispensaryAdminVerificationComponent,
    RequireNewPasswordComponent,
    ForgotPasswordComponent,
    SignInComponent,
    SalesSideDrawerComponent,
    GcvVendorModule,
    ViewAccountComponent,
    NgxPaginationModule,
    GcvMaterialCommonModule,
    ReviewAccountDocumentComponent,
  ],
  entryComponents: [
    CreateUserDialogComponent,
    DispensaryAdminVerificationComponent,
    TimeoutComponent,
    ReviewAccountDocumentComponent,
    ViewAccountComponent,
    EditUserComponent,
  ],
})
export class GcvInternalCommonModule {}
