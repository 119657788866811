import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import * as base64arraybuffer from 'base64-arraybuffer';
import { LoggerService } from '../../../services/logger/logger.service';

// TODO this dialog may not be constrained to just PDF. It is likely more general than that.

@Component({
  template: `
    <iframe #pdfView></iframe>
  `,
  styleUrls: ['./pdfViewDialog.component.scss'],
})
export class PdfViewDialogComponent implements OnInit {
  @ViewChild('pdfView')
  pdfView: ElementRef;

  objUrl: string;
  s3KeyUrl: string;
  type: string;

  constructor(
    public dialogRef: MatDialogRef<PdfViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private httpClient: HttpClient,
    private logger: LoggerService
  ) {
    if (data.objUrl) {
      this.objUrl = data.objUrl;
    } else if (data.s3KeyUrl) {
      this.type = 'application/pdf';
      if (data.hasOwnProperty('type')) {
        this.type = 'image/jpeg';
      }
      this.s3KeyUrl = data.s3KeyUrl;
    } else {
      throw Error('must pass in either an objUrl from window.URL.createObjectURL or an s3KeyUrl');
    }
  }

  ngOnInit() {
    if (this.objUrl) {
      this.pdfView.nativeElement.src = this.objUrl;
    } else if (this.s3KeyUrl) {
      this.fetchDataFromS3();
    } else {
      throw Error('must pass in either an objUrl from window.URL.createObjectURL or an s3KeyUrl');
    }
  }

  fetchDataFromS3() {
    const self = this;
    this.getPdfData(this.s3KeyUrl).subscribe(
      (res: Blob) => {
        const fileReader = new FileReader();
        fileReader.onload = e => {
          const filename = 'filename';
          const base64 = e.target['result'];
          const base64Array = base64arraybuffer.decode(base64);
          const file = new File([base64Array], filename, {
            type: this.type,
          });
          const objUrlFromS3 = window.URL.createObjectURL(file);
          self.pdfView.nativeElement.src = objUrlFromS3;
        };
        fileReader.readAsBinaryString(res);
      },
      err => {
        this.logger.error('err', err);
      }
    );
  }

  getPdfData(s3KeyUrl: string): Observable<Blob> {
    const cacheBusterUrl = `${s3KeyUrl}`;
    return this.httpClient.get(cacheBusterUrl, {
      responseType: 'blob',
    });
  }
}
